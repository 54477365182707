import { Button } from "solid-bootstrap"
import { FaRegularFile, FaSolidEraser, FaSolidMagnifyingGlass, FaSolidPaintbrush, FaSolidUsers } from "solid-icons/fa"
import { Component, useContext } from "solid-js"
import { Engine } from "../../lib/core/engine/Engine.type"
import { SelectedToolId } from "../../lib/core/map_editor/SelectedToolId.enum"
import preventCursorSelectText from "../../lib/dom/event/preventCursorSelectText"
import stopImmediatePropagation from "../../lib/dom/event/stopImmediatePropagation"
import EngineContext from "../EngineContext"
import EraseTileSelect from "../MapEditor/EraseTileSelect"
import FileWidget from "../MapEditor/FileWidget"
import InspectTileWidget from "../MapEditor/InspectTileWidget"
import PaintTileSelect from "../MapEditor/PaintTileSelect"
import PlayersWidget from "../MapEditor/PlayersWidget"

const MapEditorToolbar: Component = () => {
  const engine: Engine = useContext(EngineContext)
  // const [track, dirty] = engine.cache
  // createEffect(() => {
  //   track(EngineCache.SelectedToolId)
  //   console.log('track', engine.selectedTool===SelectedToolId.Inspect, track(EngineCache.SelectedToolId))
  // })

  function setSelectTool(newValue: SelectedToolId) {
    return (/*event: MouseEvent*/) => {
      // console.log('setSelectTool', newValue)
      engine.selectedTool = newValue
      // dirty(EngineCache.SelectedToolId)
    }
  }
  function is(id: SelectedToolId): boolean {
    // track(EngineCache.SelectedToolId)
    return engine.selectedTool === id
  }

  return <div
    ref={(elem) => {
      preventCursorSelectText(elem)
    }}
    id="hudmet"
    class="card widget top-right"
    onWheel={stopImmediatePropagation}
    onMouseUp={stopImmediatePropagation}
    onMouseDown={stopImmediatePropagation}
    onClick={stopImmediatePropagation}
    onContextMenu={stopImmediatePropagation}
    onMouseMove={stopImmediatePropagation}
  >
    <div class="card-body">
      <div class="btn-group text-nowrap">
        <Button name="file"
          class="btn btn-secondary w-100"
          disabled={is(SelectedToolId.File)}
          onClick={setSelectTool(SelectedToolId.File)}
          aria-label="File"
        ><FaRegularFile /> File</Button>
        <Button name="inspect"
          class="btn btn-secondary w-100"
          disabled={is(SelectedToolId.Inspect)}
          onClick={setSelectTool(SelectedToolId.Inspect)}
          aria-label="Inspect"
        ><FaSolidMagnifyingGlass /> Inspect</Button>
        <Button name="paint"
          class="btn btn-secondary w-100"
          disabled={is(SelectedToolId.Paint)}
          onClick={setSelectTool(SelectedToolId.Paint)}
          aria-label="Paint"
        ><FaSolidPaintbrush /> Paint</Button>
        <Button name="erase"
          class="btn btn-secondary w-100"
          disabled={is(SelectedToolId.Erase)}
          onClick={setSelectTool(SelectedToolId.Erase)}
          aria-label="Erase"
        ><FaSolidEraser /> Erase</Button>
        <Button name="players"
          class="btn btn-secondary w-100"
          disabled={is(SelectedToolId.Players)}
          onClick={setSelectTool(SelectedToolId.Players)}
          aria-label="Players"
        ><FaSolidUsers /> Players</Button>
      </div>
    </div>
    <FileWidget />
    <PaintTileSelect />
    <EraseTileSelect />
    <InspectTileWidget />
    <PlayersWidget />
  </div>
}

export default MapEditorToolbar
