import { captureException } from "@sentry/browser"
import createDefaultWait from "../../../createDefaultWait"
import BotInvalidMove from "../../../Exception/BotInvalidMove.class"
import createDraftMove from "../../draft_move/createDraftMove"
import type { DraftMove } from "../../draft_move/DraftMove.type"
import type { Engine } from "../../engine/Engine.type"
import { isEntityInfantry } from "../../entity/entityTypeMetaList.generated"
import getPlayerEntities from "../../entity/getPlayerEntities"
import type { Entity } from "../../entity/index"
import isBeingAnnexed from "../../entity/isBeingAnnexed"
import isEntityAnnexable from "../../entity/isEntityAnnexable"
import isEntityHasMovesLeft from "../../entity/isEntityHasMovesLeft"
import notInTaxi from "../../entity/notInTaxi"
import findById from "../../findById"
import { createMoveUnitAction } from "../../state/flux/action/Game/MoveUnitAction"
import dispatchClient from "../../state/flux/dispatchClient"
import type { Bot } from "../Bot.type"
import throwOnBotError from "../throwOnBotError"
import botShouldContinue from "./botShouldContinue"

export default async function botAnnexesOld(
  engine: Engine,
  bot: Bot
) {
  console.log('%c[botAnnexesOld]', 'color: grey; font-weight: lighter; font-size: 10px;')
  const { player, stepDelay } = bot
  const playerId = player.id

  const allPlayerEnts = getPlayerEntities(engine.state.ents, playerId)
  // console.log('allPlayerEnts', deepClone(allPlayerEnts))
  const playerInfantryUnits = allPlayerEnts.filter((ent) => {
    return (
      isEntityInfantry(ent) &&
      isEntityHasMovesLeft(ent) &&
      notInTaxi(ent)
    )
  })
  // console.log('playerInfantryUnits', deepClone(playerInfantryUnits))

  const allAnnexationsInProgressDraftMoves : Array<DraftMove> = engine.state.ents.reduce((carry : Array<DraftMove>, annexableEnt : Entity) => {
    if (
      annexableEnt.player_id !== playerId &&
      isEntityAnnexable(annexableEnt) &&
      isBeingAnnexed(annexableEnt)
    ) {
      const unit = findById(playerInfantryUnits, annexableEnt.ap_ent_id)
      if (unit)  {
        const draftMove = createDraftMove(unit)
        draftMove.target = annexableEnt
        draftMove.annex = true
        // draftMove.destPosition = toCoord(annexableEnt)
        carry.push(draftMove)
      }
    }
    return carry
  }, [] as Array<DraftMove>)

  for (const draftMove of allAnnexationsInProgressDraftMoves) {
    if (!botShouldContinue(engine, bot)) {
      return
    }
    try {
      await dispatchClient(engine, createMoveUnitAction(draftMove))
    } catch (err1) {
      const err2 = new BotInvalidMove('Annexing', err1)
      console.error(err2)
      captureException(err2)
      throwOnBotError(err2)
    }

    await createDefaultWait(stepDelay)
  }
}