import { deepClone } from "../../../deep_clone"
import BotInvalidMove from "../../../Exception/BotInvalidMove.class"
import createDraftMove from "../../draft_move/createDraftMove"
import createDraftMoveAttackEstimate from "../../draft_move/createDraftMoveAttackEstimate"
import type { DraftMove } from "../../draft_move/DraftMove.type"
import type { Engine } from "../../engine/Engine.type"
import type { AnnexableEntityUnion } from "../../entity/AnnexableEntityUnion.type"
import canUnitDirectAttackInGeneral from "../../entity/canUnitDirectAttackInGeneral"
import { isEntityInfantry } from "../../entity/entityTypeMetaList.generated"
import { byEntityResaleValueDesc } from "../../entity/getEntityResaleValue"
import { byEntityMapControlPriorityDesc } from "../../entity/getMapControlPriority"
import isEntityAnnexable from "../../entity/isEntityAnnexable"
import isEntityHasMovesLeft from "../../entity/isEntityHasMovesLeft"
import isEntityUnit from "../../entity/isEntityUnit"
import notInTaxi from "../../entity/notInTaxi"
import type { UnitEntityUnion } from "../../entity/UnitEntityUnion.type"
import { createMoveUnitAction } from "../../state/flux/action/Game/MoveUnitAction"
import dispatchClient from "../../state/flux/dispatchClient"
import entityListToGridXY from "../../tile_position_xy/entityListToGridXY"
import findMostEfficientPathToTileV2 from "../../tile_position_xy/findMostEfficientPathToTileV2/findMostEfficientPathToTileV2"
import getManhattanDistance from "../../tile_position_xy/getManhattanDistance"
import { samePosition } from "../../tile_position_xy/samePosition"
import toCoord from "../../tile_position_xy/toCoord"
import { byValueDesc } from "../../util/HasValue.interface"
import { byCbaDesc } from "../../util/sortByCba"
import type { Bot } from "../Bot.type"
import throwOnBotError from "../throwOnBotError"
import botShouldContinue from "./botShouldContinue"

type AnnexingEnemyTarget = {
  enemy: UnitEntityUnion
  annexable: AnnexableEntityUnion
  value: number
}

type ProposedAttack = {
  unit: UnitEntityUnion
  target: AnnexingEnemyTarget
  cba: number
  draftMove: DraftMove
}

export default async function botAttackAnnexers(
  engine: Engine,
  bot: Bot
) {
  console.log('%c[botAttackAnnexers]', 'color: grey; font-weight: lighter; font-size: 10px;')
  const { player } = bot
  const playerId = player.id
  // const allPlayerEnts = getPlayerEntities(engine.state.ents, playerId)
  // console.log('allPlayerEnts', deepClone(allPlayerEnts))

  // console.log('playerUnits', deepClone(playerUnits))

  // const allEnemyUnitsEnts = engine.state.ents.filter((ent): ent is UnitEntityUnion => {
  //   return (ent.player_id && ent.player_id !== playerId && isEntityUnit(ent)) as boolean
  // }).sort(byEntityResaleValueDesc)
  // console.log('allEnemyUnitsEnts', deepClone(allEnemyUnitsEnts))

  // const hqEnt = allPlayerEnts.find(isEntityHQ)
  const { width, height } = engine.state

  const allAnnexableEnts = engine.state.ents.filter(isEntityAnnexable).sort(byEntityMapControlPriorityDesc)

  const getProposedAttacksList = (): Array<ProposedAttack> => {

    const playerUnits = engine.state.ents.filter((ent): ent is UnitEntityUnion => {
      return (
        ent.player_id === playerId &&
        isEntityUnit(ent) &&
        notInTaxi(ent) &&
        isEntityHasMovesLeft(ent) &&
        canUnitDirectAttackInGeneral(ent)
      )
    }).sort(byEntityResaleValueDesc)
  
    const annexingEnemyTargetsList = allAnnexableEnts.reduce((carry: Array<AnnexingEnemyTarget>, annexableEnt) => {
      engine.state.ents.forEach((enemyEnt) => {
        if (isEntityInfantry(enemyEnt) && enemyEnt.player_id !== playerId) {
          if (enemyEnt.player_id !== annexableEnt.player_id) {
            if (enemyEnt.id === annexableEnt.ap_ent_id || samePosition(enemyEnt, annexableEnt)) {
              carry.push({
                enemy: enemyEnt,
                annexable: annexableEnt,
                // the bot should prioritize weakening enemies 
                // to delay annexing.
                get value(): number {
                  return -annexableEnt.ap + enemyEnt.hp
                },
              })
            }
          }
        }
      })
      return carry
    }, []).sort(byValueDesc)
    if (annexingEnemyTargetsList.length === 0) {
      return []
    }
    // console.log('annexingEnemyTargetsList', deepClone(annexingEnemyTargetsList))
    const entityGridXY = entityListToGridXY(engine.state.ents, width, height)

    const proposedAttackList = playerUnits.reduce((carry : Array<ProposedAttack>, unit: UnitEntityUnion): Array<ProposedAttack> => {
      // console.log('isEntityHasMovesLeft(unit)', isEntityHasMovesLeft(unit))
      if (isEntityHasMovesLeft(unit)) {
        annexingEnemyTargetsList.forEach((target : AnnexingEnemyTarget) => {
          const { enemy } = target
          // console.log('getManhattanDistance(unit, enemy) <= (unit.mobility ?? 0) + 1', getManhattanDistance(unit, enemy) <= (unit.mobility ?? 0) + 1)
          if (getManhattanDistance(unit, enemy) <= (unit.mobility ?? 0) + 1) {
            const eptt = findMostEfficientPathToTileV2(entityGridXY, unit, width, height, enemy.x, enemy.y)
            // console.log('eptt', eptt)
            if (eptt) {
              // console.log('hasLengthOne(eptt.turns)', hasLengthOne(eptt.turns))
              if (!(eptt.turns.length > 1)) {
                const { lastStep, targetStep } = eptt
                // console.log(deepClone({ lastStep, targetStep }))
                if (!lastStep?.impass && !lastStep?.occupant && targetStep?.enemies?.[0]) {
                  const destPosition = lastStep ? toCoord(lastStep) : null
                  const ae = createDraftMoveAttackEstimate(engine, unit, destPosition, enemy)
                  // const cba1 = ((eptt.targetStep?.cost ?? 0) + 1) / unit.mobility
                  const cba = (ae.unitCba ?? 0) - (ae.targetCba ?? 0)
                  // console.log('cba', cba)
                  if (cba >= 0) {
                    const draftMove = createDraftMove(unit)
                    draftMove.destPosition = destPosition
                    draftMove.attackPosition = toCoord(enemy)
                    draftMove.target = enemy

                    carry.push({
                      unit,
                      target,
                      cba,
                      draftMove,
                    })
                  }
                }
              }
            }
          }
        })
      }
      return carry
    }, [])
    
    proposedAttackList.sort(byCbaDesc)

    return proposedAttackList
  }

  do {
    const proposedAttackList = getProposedAttacksList()
    // console.log('proposedAttackList', deepClone(proposedAttackList))
    if (proposedAttackList.length === 0) {
      console.log('%c[botAttackAnnexers] ran out of annexers to attack', 'color: grey; font-weight: lighter; font-size: 10px;')
      return
    }

    for (const { draftMove } of proposedAttackList) {
      console.log('proposedAttackList.draftMove[index]', draftMove.unit.id, deepClone(draftMove))
      try {
        await dispatchClient(engine, createMoveUnitAction(draftMove))

        console.log('rebuild proposedAttackList to recalculate new best')
        break
      } catch (err1) {
        const err2 = new BotInvalidMove('Attack Annexers', err1)
        console.error(err2)
        throwOnBotError(err2)
      }
    }

  } while (await botShouldContinue(engine, bot))
}