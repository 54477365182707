/**
 * This file is automatically generated.
 **/
import { variant, InferOutput } from 'valibot'

import { CancelFactoryOrderActionSchema } from './action/Game/CancelFactoryOrderAction'
import { EndGameActionSchema } from './action/Game/EndGameAction'
import { EndRoundActionSchema } from './action/Game/EndRoundAction'
import { EndTurnActionSchema } from './action/Game/EndTurnAction'
import { MoveUnitActionSchema } from './action/Game/MoveUnitAction'
import { PlaceFactoryOrderActionSchema } from './action/Game/PlaceFactoryOrderAction'
import { StartRoundActionSchema } from './action/Game/StartRoundAction'
import { StartTurnActionSchema } from './action/Game/StartTurnAction'
import { ClaimPlayerSlotActionSchema } from './action/Lobby/ClaimPlayerSlotAction'
import { DeleteLobbyActionSchema } from './action/Lobby/DeleteLobbyAction'
import { KickPlayerSlotActionSchema } from './action/Lobby/KickPlayerSlotAction'
import { LeavePlayerSlotActionSchema } from './action/Lobby/LeavePlayerSlotAction'
import { PatchConfigActionSchema } from './action/Lobby/PatchConfigAction'
import { ReservePlayerSlotActionSchema } from './action/Lobby/ReservePlayerSlotAction'
import { RestoreLobbyActionSchema } from './action/Lobby/RestoreLobbyAction'
import { SetBotPlayerSlotActionSchema } from './action/Lobby/SetBotPlayerSlotAction'
import { StartNewGameActionSchema } from './action/Lobby/StartNewGameAction'
import { AddPlayerActionSchema } from './action/Map/AddPlayerAction'
import { DeleteMapActionSchema } from './action/Map/DeleteMapAction'
import { DeletePlayerActionSchema } from './action/Map/DeletePlayerAction'
import { EraseTileActionSchema } from './action/Map/EraseTileAction'
import { MapToLobbyActionSchema } from './action/Map/MapToLobbyAction'
import { PaintTileActionSchema } from './action/Map/PaintTileAction'
import { PatchMapActionSchema } from './action/Map/PatchMapAction'
import { PatchPlayerActionSchema } from './action/Map/PatchPlayerAction'
import { PublishMapActionSchema } from './action/Map/PublishMapAction'
import { RestoreMapActionSchema } from './action/Map/RestoreMapAction'
import { SetStateActionSchema } from './action/Map/SetStateAction'
import { UnpublishMapActionSchema } from './action/Map/UnpublishMapAction'

export type ActionSchemaUnion = typeof CancelFactoryOrderActionSchema | typeof EndGameActionSchema | typeof EndRoundActionSchema | typeof EndTurnActionSchema | typeof MoveUnitActionSchema | typeof PlaceFactoryOrderActionSchema | typeof StartRoundActionSchema | typeof StartTurnActionSchema | typeof ClaimPlayerSlotActionSchema | typeof DeleteLobbyActionSchema | typeof KickPlayerSlotActionSchema | typeof LeavePlayerSlotActionSchema | typeof PatchConfigActionSchema | typeof ReservePlayerSlotActionSchema | typeof RestoreLobbyActionSchema | typeof SetBotPlayerSlotActionSchema | typeof StartNewGameActionSchema | typeof AddPlayerActionSchema | typeof DeleteMapActionSchema | typeof DeletePlayerActionSchema | typeof EraseTileActionSchema | typeof MapToLobbyActionSchema | typeof PaintTileActionSchema | typeof PatchMapActionSchema | typeof PatchPlayerActionSchema | typeof PublishMapActionSchema | typeof RestoreMapActionSchema | typeof SetStateActionSchema | typeof UnpublishMapActionSchema


const actionSchemasList = [
  CancelFactoryOrderActionSchema,
  EndGameActionSchema,
  EndRoundActionSchema,
  EndTurnActionSchema,
  MoveUnitActionSchema,
  PlaceFactoryOrderActionSchema,
  StartRoundActionSchema,
  StartTurnActionSchema,
  ClaimPlayerSlotActionSchema,
  DeleteLobbyActionSchema,
  KickPlayerSlotActionSchema,
  LeavePlayerSlotActionSchema,
  PatchConfigActionSchema,
  ReservePlayerSlotActionSchema,
  RestoreLobbyActionSchema,
  SetBotPlayerSlotActionSchema,
  StartNewGameActionSchema,
  AddPlayerActionSchema,
  DeleteMapActionSchema,
  DeletePlayerActionSchema,
  EraseTileActionSchema,
  MapToLobbyActionSchema,
  PaintTileActionSchema,
  PatchMapActionSchema,
  PatchPlayerActionSchema,
  PublishMapActionSchema,
  RestoreMapActionSchema,
  SetStateActionSchema,
  UnpublishMapActionSchema,
] as const

export const ActionVariantsSchema = variant('type', actionSchemasList)
export type ActionVariants = InferOutput<typeof ActionVariantsSchema>
export type ActionSchemasListUnion = (typeof actionSchemasList)[number]


export default actionSchemasList