import type { HasHP } from '../has_hp'
import type { HasWeapons } from '../HasWeapons'
import type { HasPlayerId } from '../player/has_player_id'
import type { Entity } from './index'
import isWeaponUsable from './isWeaponUsable'

export default function canUnitRangedAttackInGeneral(
  unit: Entity
): unit is Entity & HasHP & HasPlayerId & HasWeapons {
  const { player_id: unitPlayerId, weapons } = unit
  // units inside of a taxi cannot be targeted or attacked
  if (unit.taxi_id) {
    return false
  }
  if (unitPlayerId) {
    if (weapons) {
      for (let index = 0; index < weapons.length; index++) {
        const weapon = weapons[index]
        if (isWeaponUsable(weapon)) {
          const maxRange = weapon.maxRange
          if (!maxRange) {
            continue
          }

          return true
        }
      }
    }
  }
  return false
}
