import type { HasHP } from '../has_hp'
import type { HasWeapons } from '../HasWeapons'
import type { HasPlayerId } from '../player/has_player_id'
import type { HasTaxiID } from './HasTaxiID'
import type { Entity } from './index'
import isWeaponUsable from './isWeaponUsable'

export default function canUnitDirectAttackInGeneral(
  unit: Entity
): unit is Entity & HasWeapons & HasPlayerId & HasHP {
  // units inside of a taxi cannot be targeted or attacked
  if ((unit as HasTaxiID).taxi_id) {
    return false
  }
  if ('hp' in unit) {
    if ('weapons' in unit && unit.weapons) {
      for (let index = 0; index < unit.weapons.length; index++) {
        const weapon = unit.weapons[index]
        if (isWeaponUsable(weapon)) {
          const maxRange = weapon.maxRange
          if (maxRange) {
            continue
          }
          return true
        }
      }
    }
  }
  return false
}
