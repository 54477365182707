import { literal, object, type InferOutput } from 'valibot'
import NotYourTurn from '../../../../../Exception/NotYourTurn.class'
import type { HasAttackedThisTurn } from '../../../../AttackedThisTurn'
import { Engine } from '../../../../engine/Engine.type'
import entityTypeMetaList from '../../../../entity/entityTypeMetaList.generated'
import getPlayerEntities from '../../../../entity/getPlayerEntities'
import findByIdOrThrow from '../../../../findByIdOrThrow'
import type { Player } from '../../../../player/Player.type'
import { PlayerIdSchema, type PlayerId } from '../../../../player/PlayerId'
import { PlayerTurnStatus } from '../../../../player/PlayerTurnStatus'
import type { HasWaitedThisTurn } from '../../../../WaitedThisTurn'
import type { HasWasBuiltThisTurn } from '../../../../WasBuiltThisTurn'
import type { ActionLog } from '../../ActionLog.type'
import { ActionType } from '../ActionType'

export const EndTurnActionSchema = object({
  type: literal(ActionType.Game.EndTurn),
  player_id: PlayerIdSchema,
})

export type EndTurnAction = InferOutput<typeof EndTurnActionSchema>

export function createEndTurnAction(player_id: PlayerId): EndTurnAction {
  return {
    type: ActionType.Game.EndTurn,
    player_id,
  }
}

export async function handleEndTurnAction(
  engine: Engine,
  action: EndTurnAction,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  actionLog: ActionLog
): Promise<void> {
  const { player_id } = action
  const { state } = engine
  const { turnPlayerId, players, ents } = state
  const turnPlayerIndex = players.findIndex((p) => p.id === player_id)

  const prevTurnPlayer = players[turnPlayerIndex]
  if (prevTurnPlayer.turn_status !== PlayerTurnStatus.Playing) {
    throw new NotYourTurn
  }

  const firstPlayer = players[0]

  const nextTurnPlayer: Player = players[turnPlayerIndex + 1] || firstPlayer

  const nexTurnPlayerId: PlayerId = nextTurnPlayer.id
  if (turnPlayerId === nexTurnPlayerId) {
    throw new Error('unexpected same playerId')
  }

  if (nextTurnPlayer === firstPlayer) {
    // start next round
    engine.state.round++

    // this player is done
    players.forEach((player: Player) => {
      player.turn_status = PlayerTurnStatus.Waiting
    })

    // console.log(`nextTurnPlayer(${nextTurnPlayer.id}) === firstPlayer(${firstPlayer.id}): nextTurnPlayer.turn_status = PlayerTurnStatus.Pending`)
    // the next player will be prompted to start turn
    nextTurnPlayer.turn_status = PlayerTurnStatus.Pending
  } else {
    // next player in round

    // console.log(`prevTurnPlayer(${prevTurnPlayer.id}): prevTurnPlayer.turn_status = PlayerTurnStatus.Done`)
    // this player is done
    prevTurnPlayer.turn_status = PlayerTurnStatus.Done

    // console.log(`nextTurnPlayer(${nextTurnPlayer.id}): nextTurnPlayer.turn_status = PlayerTurnStatus.Pending`)
    // the next player will be prompted to start turn
    nextTurnPlayer.turn_status = PlayerTurnStatus.Pending
  }

  state.turnPlayerId = nexTurnPlayerId

  const playerEnts = getPlayerEntities(ents, prevTurnPlayer.id)
  for (let index = playerEnts.length; index--; ) {
    const entity = playerEnts[index]
    const entityMeta = findByIdOrThrow(entityTypeMetaList, entity.etype_id)
    const { entDefaults } = entityMeta
    const { mobility: defaultMobility } = entDefaults as {
      mobility?: number
    }

    // turn is over, ent was no longer built this turn
    delete (entity as HasWasBuiltThisTurn).builtThisTurn
    delete (entity as HasWaitedThisTurn).waitedThisTurn
    delete (entity as HasAttackedThisTurn).attackedThisTurn

    // reset mobility points
    if ('mobility' in entity && defaultMobility) {
      entity.mobility = defaultMobility
    }
  }
}
