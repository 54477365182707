import { authAxios } from "../../../axios"
import { VITE_ALLOWS_GUEST_ACCESS_TOKEN } from "./auth_session_key"
import { updateSessionValue } from "./sessionValue"

// let maxFetches = 2

export default async function fetchGuestAccessToken() {
  // console.log('fetchGuestAccessToken')
  // if (maxFetches-- === 0) {
  //   throw new Error('Max Fetches Exceeded')
  // }
  // if (maxFetches < 0) {
  //   await new Promise(resolve => setTimeout(resolve, 10000))
  // }
  if (VITE_ALLOWS_GUEST_ACCESS_TOKEN) {
    // await new Promise(resolve => setTimeout(resolve, 1000))
    const response = await authAxios.post('/auth/guest')
    // await new Promise(resolve => setTimeout(resolve, 10000))
    // console.log('fetchGuestAccessToken', response.data)
    updateSessionValue(response.data)
    // TODO update JWT
    // throw new Error('Not Implemented')
  } else {
    throw new Error('Not Implemented (redirect to login)')
  }
}
