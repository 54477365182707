import { CanvasTexture, LinearFilter, Sprite, SpriteMaterial } from "three"
import { floor } from "../../core/util/math"

export default function createDebugTextSprite(text: string): Sprite {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  if (!context) throw new Error('Failed to get canvas context')

  const size = 256 // Size of the canvas
  canvas.width = size
  canvas.height = size

  // Set text properties
  context.font = '48px Arial'
  context.textAlign = 'center'
  context.textBaseline = 'middle'
  context.fillStyle = 'rgba(0,0,0,0.25)'
  context.fillRect(0, 0, size, size)
  context.fillStyle = 'white'
  const lines = text.split('\n')
  const lineHeight = floor(size / (lines.length + 1))
  lines.forEach((line, index) => {
    context.fillText(line, size / 2, (index + 1) * lineHeight)
  })

  // Create a texture
  const texture = new CanvasTexture(canvas)
  texture.minFilter = LinearFilter // Prevent mipmaps
  texture.needsUpdate = true

  // Create a sprite material and sprite
  const material = new SpriteMaterial({ map: texture, transparent: true })
  const sprite = new Sprite(material)
  // sprite.scale.set(5, 5, 1) // Adjust size to match box

  // sprite.userData.vampire = true

  return sprite
}
