import { literal, object, type InferOutput } from 'valibot'
import { Engine } from '../../../../engine/Engine.type'
import type { ActionLog } from '../../ActionLog.type'
import { ActionType } from '../ActionType'


export const RestoreMapActionSchema = object({
  type: literal(ActionType.Map.RestoreMap),
})

export type RestoreMapAction = InferOutput<typeof RestoreMapActionSchema>

export function createRestoreMapAction(): RestoreMapAction {
  return {
    type: ActionType.Map.RestoreMap,
  }
}

export async function handleRestoreMapAction(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  engine: Engine,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: RestoreMapAction,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  actionLog: ActionLog
): Promise<void> {
  // so basically the server does a special thing when it receives this action
}
