import type { EntityList } from "../state/EntityList.type"
import isBeingAnnexedBy from "./isBeingAnnexedBy"
import resetAnnexable from "./resetAnnexable"
import type { UnitEntityUnion } from "./UnitEntityUnion.type"

export default function resetAllAnnexingByUnit (ents: EntityList, unit : UnitEntityUnion): void {
  ents.forEach((annexableEnt) => {
    if (isBeingAnnexedBy(annexableEnt, unit)) {
      resetAnnexable(annexableEnt)
    }
  })
}