import { createEffect } from 'solid-js'
import { modifyMutable, produce } from 'solid-js/store'
import type { Engine } from '../../lib/core/engine/Engine.type'
import { PlayerKind } from '../../lib/core/player/PlayerKind.enum'
import { PlayerTurnStatus } from '../../lib/core/player/PlayerTurnStatus'

export default function createSetEngineAuthPlayerIdFromLocalEffect(
  engine: Engine
) {
  createEffect(() => {
    modifyMutable(
      engine,
      produce((engine) => {
        const player = engine.state.players.find((player) => player.turn_status === PlayerTurnStatus.Pending && player.kind === PlayerKind.Human)
        if (player) {
          engine.authPlayerId = player.id
        }
      })
    )
  })
}
