import { type Scene, PlaneGeometry, Vector3 } from "three"
import type { Engine } from "../../core/engine/Engine.type"
import { PI } from "../../core/util/math"
import isNotNil from "../../ldsh/isNotNil"
import { Water } from "../objects/mesh/Water"
import getWaterNormalsTexture1 from "../texture/WaterNormalsTexture1"

// this did not work lol
export default function createWaterPlaneMesh(
  engine: Engine,
  scene: Scene
) {
  const waterGeometry = new PlaneGeometry(10000, 10000)
  const waterMesh = new Water(
    waterGeometry,
    {
      textureWidth: 512,
      textureHeight: 512,
      waterNormals: getWaterNormalsTexture1(),
      sunDirection: new Vector3(),
      sunColor: 0xffffff,
      waterColor: 0x001e0f,
      distortionScale: 3.7,
      fog: isNotNil(scene.fog)
    }
  )
  waterMesh.rotation.x = - PI / 2
  scene.add(waterMesh)
  return waterMesh
}