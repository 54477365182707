import type { Nullable } from '../../../typescript'
import findPlayerByProfileId from '../player/findPlayerByProfileId'
import type { Player } from '../player/Player.type'
import type { ProfileId } from '../ProfileId.type'
import { Engine } from './Engine.type'

export default function setAuthPlayerIdFromProfileId(
  engine: Engine,
  profileId: Nullable<ProfileId>
): Nullable<Player> {
  if (profileId) {
    // console.log('setAuthPlayerIdFromProfileId', { profileId, players: engine.state.players.map((p) => ({
    //   player_id: p.id,
    //   profile_id: p.profile_id,
    // })) })
    const player = findPlayerByProfileId(engine.state, profileId)
    const newValue = player?.id || null
    // console.log('setAuthPlayerIdFromProfileId.newValue', newValue)
    engine.authPlayerId = newValue
    return player
  }
  return null
}
