import { Key } from '@solid-primitives/keyed'
import { FaSolidArrowRight, FaSolidRotate, FaSolidX } from 'solid-icons/fa'
import { Component, createMemo, createSignal, on, Show, useContext, type Accessor } from 'solid-js'
import { modifyMutable, produce } from 'solid-js/store'
import calculateDraftMoveAttackEstimates from '../../lib/core/draft_move/calculateDraftMoveAttackEstimates'
import createDraftMove from '../../lib/core/draft_move/createDraftMove'
import type { DraftMove } from '../../lib/core/draft_move/DraftMove.type'
import type { Engine } from '../../lib/core/engine/Engine.type'
import resetEngineToDefaultSelectedTool from '../../lib/core/engine/resetEngineToDefaultSelectedTool'
import type { AnnexableEntityUnion } from '../../lib/core/entity/AnnexableEntityUnion.type'
import canUnitAnnexEntity from '../../lib/core/entity/canUnitAnnexEntity'
import entityDisplayName from '../../lib/core/entity/entityDisplayName'
import { isEntityInfantry } from '../../lib/core/entity/entityTypeMetaList.generated'
import getEntitiesAtPosition from '../../lib/core/entity/getEntitiesAtPosition'
import type { Entity } from '../../lib/core/entity/index'
import isEntityAtFullMobility from '../../lib/core/entity/isEntityAtFullMobility'
import unitHasRangedAttackWeapon from '../../lib/core/entity/unitHasRangedAttackWeapon'
import unitHasUsableRangedAttackWeapon from '../../lib/core/entity/unitHasUsableRangedAttackWeapon'
import type { HasHP } from '../../lib/core/has_hp'
import type { HasWeapons } from '../../lib/core/HasWeapons'
import { SelectedToolId } from '../../lib/core/map_editor/SelectedToolId.enum'
import type { HasPlayerId } from '../../lib/core/player/has_player_id'
import { createMoveUnitAction } from '../../lib/core/state/flux/action/Game/MoveUnitAction'
import dispatchClient from '../../lib/core/state/flux/dispatchClient'
import type { PathTurnSteps_Step } from '../../lib/core/tile_position_xy/findMostEfficientPathToTileV2/PathTurnSteps_Step.type'
import findUnloadableCargoTileCollection, { type UnloadableCargoTileGroup } from '../../lib/core/tile_position_xy/findUnloadableCargoTileCollection'
import { samePosition } from '../../lib/core/tile_position_xy/samePosition'
import toCoord from '../../lib/core/tile_position_xy/toCoord'
import handlePreviewRangedAttackOptions from '../../lib/core/ui/action/PreviewRangedAttackOptions'
import handlePreviewUnloadPositionOptions from '../../lib/core/ui/action/PreviewUnloadPositionOptions'
import handleUnitLoadIntoEntityAction from '../../lib/core/ui/action/UnitLoadIntoEntityAction'
import Button from '../../lib/jsx/Button'
import nullIfEmptyArray from '../../lib/ldsh/nullIfEmptyArray'
import withBusy from '../../lib/withBusy'
import type { Nullable } from '../../typescript'
import EngineContext from '../EngineContext'
import LoadingIcon from '../LoadingIcon'
import SingleSpriteIcon from '../MapEditor/SingleSpriteIcon'

const DraftMoveMenuWidget: Component = () => {
  const engine: Engine = useContext(EngineContext)

  const yourTurn = (): boolean => (engine.authPlayerId && engine.authPlayerId === engine.state.turnPlayerId) as boolean

  return (
    <Show when={yourTurn() && !engine.state.ended && engine.draftMove} children={(draftMove) => {

      const lastStep = (): Nullable<PathTurnSteps_Step> => draftMove().destPositionPaths?.lastStep

      const [isBusy, setIsBusy] = createSignal<boolean>(false)

      const annexableEntityList = createMemo<Nullable<Array<AnnexableEntityUnion>>>(() => {
        const { destPosition, destPositionPaths, unit } = draftMove()
        if (destPosition) {
          return destPositionPaths?.lastStep?.annex
        }
        if (isEntityInfantry(unit)) {
          return getEntitiesAtPosition(engine.state.ents, unit).filter((ent) => canUnitAnnexEntity(unit, ent))
        }
      })

      const directAttackableEntityList = createMemo<Array<Entity & HasHP & HasPlayerId>>(() => {
        const list: Array<Entity & HasHP & HasPlayerId> = []
        lastStep()?.enemies?.forEach?.((row) => {
          if (row.canDirectAttack) {
            list.push(row.ent as Entity & HasHP & HasPlayerId)
          }
        })
        return list
      })
      // createEffect(() => {
      //   console.log('directAttackableEntityList', deepClone(directAttackableEntityList()))
      // })

      const unitWithRangedAttackWeapon = createMemo<Nullable<Entity & HasWeapons>>(() => {
        const { unit } = draftMove()
        if (unitHasRangedAttackWeapon(unit)) {
          return unit
        }
      })
      const unitWithUsableRangedAttackWeapon = createMemo<Nullable<Entity & HasWeapons>>(() => {
        const unit = unitWithRangedAttackWeapon()
        if (unit && unitHasUsableRangedAttackWeapon(unit)) {
          return unit
        }
      })

      // const rangedAttackableEntityList = createMemo<Array<Entity & HasHP & HasPlayerId>>(() => {
      //   const list : Array<Entity & HasHP & HasPlayerId> = []
      //   lastStep()?.enemies?.forEach?.((row) => {
      //     if (row.canRangedAttack) {
      //       list.push(row.ent as Entity & HasHP & HasPlayerId)
      //     }
      //   })
      //   return list
      // })
      // createEffect(() => {
      //   console.log('rangedAttackableEntityList', deepClone(rangedAttackableEntityList()))
      // })

      const unloadableCargoPathGroupList = createMemo<Nullable<Array<UnloadableCargoTileGroup>>>(on(() => engine.draftMove, (draftMove: Nullable<DraftMove>) => {
        return nullIfEmptyArray(findUnloadableCargoTileCollection(engine, draftMove))
      }))
      const showRangedAttackButton = createMemo(() => {
        const { attackPosition, destPosition, unit } = draftMove()

        if (attackPosition) {
          return
        }
        if (engine.selectedTool !== SelectedToolId.MoveUnit) {
          return
        }
        if (!isEntityAtFullMobility(unit)) {
          return
        }
        if (!unitWithRangedAttackWeapon()) {
          return
        }
        if (!samePosition(destPosition || unit, unit)) {
          return
        }
        return true
      })

      // const cssStyle = createMemo<Partial<JSX.CSSProperties>>(() => {
      //   return {
      //     // position: 'fixed',
      //     // left: '10px',
      //     // top: '10px',
      //   }
      // })
      return (
        <div class="cw cw-dm cw-sm-bl float-end">
          <Key each={directAttackableEntityList()} by="id"
            children={(directAttackableEntity) => {
              return <Button
                disabled={isBusy()}
                name="direct_attack"
                class="btn btn-primary d-block"
                ref={(elem: HTMLButtonElement) => {
                  // onmouseenter/onmouseleave does not include children
                  // onmouseover/onmouseout includes children
                  elem.onmouseover = () => {
                    const targetEnt = directAttackableEntity()
                    const position = toCoord(targetEnt)
                    modifyMutable(engine, produce((engine) => {
                      const { draftMove } = engine
                      if (draftMove && !samePosition(draftMove.attackPosition, position)) {
                        draftMove.attackPosition = position
                        calculateDraftMoveAttackEstimates(engine)
                      }
                    }))
                  }
                  elem.onmouseout = () => {
                    const targetEnt = directAttackableEntity()
                    const position = toCoord(targetEnt)
                    modifyMutable(engine, produce((engine) => {
                      const { draftMove } = engine
                      if (draftMove && samePosition(draftMove.attackPosition, position)) {
                        draftMove.attackPosition = null
                        calculateDraftMoveAttackEstimates(engine)
                      }
                    }))
                  }
                }}
                onClick={withBusy(isBusy, setIsBusy, async () => {
                  const targetEnt = directAttackableEntity()
                  const newDraftMove: DraftMove = {
                    ...draftMove(),
                    attackPosition: toCoord(targetEnt),
                    target: targetEnt,
                  }
                  engine.selectedTool = SelectedToolId.MoveUnit
                  engine.draftMove = newDraftMove
                  calculateDraftMoveAttackEstimates(engine)
                  await dispatchClient(engine, createMoveUnitAction(newDraftMove))
                  modifyMutable(engine, produce((engine) => {
                    resetEngineToDefaultSelectedTool(engine)
                  }))
                })}
              >
                <Show when={isBusy()} children={<LoadingIcon />} fallback={<FaSolidArrowRight />} />
                {` Attack ${entityDisplayName(directAttackableEntity())} `}
                <Show when={directAttackableEntityList().length === 1}
                  children={<span class="text-muted small">[d]</span>}
                />
              </Button>
            }}
          />
          <Show when={showRangedAttackButton()}
            children={(
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              rangedUnit) => {
              return <Button
                disabled={isBusy() || !unitWithUsableRangedAttackWeapon()}
                name="ranged_attack"
                class="btn btn-primary d-block"
                onClick={withBusy(isBusy, setIsBusy, async () => {
                  handlePreviewRangedAttackOptions(engine)
                })}
              >
                <Show when={isBusy()} children={<LoadingIcon />} fallback={<FaSolidArrowRight />} />
                {` Bombard `}
                <span class="text-muted small">[b]</span>
              </Button>
            }}
          />
          <Key each={annexableEntityList()} by="id" children={(annexableEntity) => {
            // const annexableEntityTypeMeta = () => 
            return <Button
              disabled={isBusy()}
              name="annex"
              class="btn btn-primary d-block"
              onClick={withBusy(isBusy, setIsBusy, async () => {
                const newDraftMove: DraftMove = {
                  ...draftMove(),
                  annex: true,
                }
                engine.selectedTool = SelectedToolId.MoveUnit
                // console.log('engine.draftMove = newDraftMove')
                engine.draftMove = newDraftMove
                calculateDraftMoveAttackEstimates(engine)
                await dispatchClient(engine, createMoveUnitAction(newDraftMove))
                modifyMutable(engine, produce((engine) => {
                  resetEngineToDefaultSelectedTool(engine)
                }))
              })}
            >
              <Show when={isBusy()} children={<LoadingIcon />} fallback={<FaSolidArrowRight />} />
              {' Annex '}
              <SingleSpriteIcon entity={annexableEntity()} noBg />
              {` ${entityDisplayName(annexableEntity())}`}
              <span class="text-muted small">[a]</span>
            </Button>
          }}
          />
          <Show when={lastStep()?.merge} children={(mergableEntity) => {
            // const mergableEntityTypeMeta = () => 
            return <Button
              disabled={isBusy()}
              name="merge"
              class="btn btn-primary d-block"
              onClick={withBusy(isBusy, setIsBusy, async () => {
                await dispatchClient(engine, createMoveUnitAction({
                  ...draftMove(),
                  merge: mergableEntity(),
                }))
                modifyMutable(engine, produce((engine) => {
                  resetEngineToDefaultSelectedTool(engine)
                }))
              })}
            >
              <Show when={isBusy()} children={<LoadingIcon />} fallback={<FaSolidArrowRight />} />
              {` Merge ${entityDisplayName(mergableEntity())} `}
              <span class="text-muted small">[m]</span>
            </Button>
          }}
          />
          <Key each={lastStep()?.pickup} by="id" children={(cargoEnt: Accessor<Entity>) => {
            return (
              <Button
                disabled={isBusy()}
                name="pickup"
                class="btn btn-primary d-block"
                onClick={withBusy(isBusy, setIsBusy, async () => {
                  await dispatchClient(engine, createMoveUnitAction({
                    ...draftMove(),
                    pickup: cargoEnt(),
                  }))
                  modifyMutable(engine, produce((engine) => {
                    resetEngineToDefaultSelectedTool(engine)
                  }))
                })}
              >
                <Show when={isBusy()} children={<LoadingIcon />} fallback={<FaSolidArrowRight />} />
                {' Pickup '}
                <span class="text-muted small">[p]</span>
              </Button>
            )
          }} />
          <Key each={lastStep()?.taxis} by="id" children={(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            taxiEnt: Accessor<Entity>
          ) => {
            return (
              <Button
                disabled={isBusy()}
                name="pickup"
                class="btn btn-primary d-block"
                onClick={withBusy(isBusy, setIsBusy, async () => {
                  await handleUnitLoadIntoEntityAction(engine)
                })}
              >
                <Show when={isBusy()} children={<LoadingIcon />} fallback={<FaSolidArrowRight />} />
                {' Load '}
                <span class="text-muted small">[L]</span>
              </Button>
            )
          }} />
          <Show when={unloadableCargoPathGroupList()} children={(unloadableCargoPathGroupList: Accessor<Array<UnloadableCargoTileGroup>>) => {
            return <Key each={unloadableCargoPathGroupList()} by="cargo_id" children={(ucPathGroup: Accessor<UnloadableCargoTileGroup>) => {
              return <Button
                disabled={isBusy()}
                name="unload"
                class="btn btn-primary d-block"
                classList={{
                  'btn-secondary': draftMove()?.unload?.cargo_id === ucPathGroup().cargo_id
                }}
                onClick={() => {
                  handlePreviewUnloadPositionOptions(engine)
                }}
              >
                <Show when={isBusy()} children={<LoadingIcon />} fallback={<FaSolidArrowRight />} />
                {' Unload '}
                <span class="text-muted small">[u]</span>
              </Button>
            }} />
          }} />
          <Show when={lastStep()?.occupant} children={(occupant: Accessor<Entity>) => {
            return <Button
              name="occupant"
              class="btn btn-secondary d-block"
              onClick={withBusy(isBusy, setIsBusy, async () => {
                const newSelectedEnt = occupant()
                modifyMutable(engine, produce((engine) => {
                  resetEngineToDefaultSelectedTool(engine)
                }))
                engine.draftMove = newSelectedEnt ? createDraftMove(newSelectedEnt) : null
              })}
            >
              <FaSolidRotate />
              {' Select '}
              <SingleSpriteIcon entity={occupant()} noBg tileSize={24} />
            </Button>
          }} />
          <Show when={draftMove().destPosition && !(lastStep()?.occupant)}>
            <Button
              disabled={isBusy()}
              name="commit"
              class="btn btn-primary d-block"
              onClick={withBusy(isBusy, setIsBusy, async () => {
                await dispatchClient(engine, createMoveUnitAction(draftMove()))
                modifyMutable(engine, produce((engine) => {
                  resetEngineToDefaultSelectedTool(engine)
                }))
              })}
            >
              <Show when={isBusy()} children={<LoadingIcon />} fallback={<FaSolidArrowRight />} />
              {' Commit '}
              <span class="text-muted small">[c]</span>
            </Button>
          </Show>
          <Show when={draftMove() && !draftMove().destPosition}>
            <Button
              disabled={isBusy()}
              name="wait"
              class="btn btn-primary d-block"
              onClick={withBusy(isBusy, setIsBusy, async () => {
                await dispatchClient(engine, createMoveUnitAction({
                  ...draftMove(),
                  wait: true,
                }))
                modifyMutable(engine, produce((engine) => {
                  resetEngineToDefaultSelectedTool(engine)
                }))
              })}
            >
              <Show when={isBusy()} children={<LoadingIcon />} fallback={<FaSolidX />} />
              {' Wait '}
              <span class="text-muted small">[w]</span>
            </Button>
          </Show>
          <Show when={draftMove()}>
            <Button
              disabled={isBusy()}
              name="cancel"
              class="btn btn-primary d-block"
              onClick={withBusy(isBusy, setIsBusy, async () => {
                modifyMutable(engine, produce((engine) => {
                  resetEngineToDefaultSelectedTool(engine)
                }))
              })}
            >
              <Show when={isBusy()} children={<LoadingIcon />} fallback={<FaSolidX />} />
              {' Cancel '}
              <span class="text-muted small">[esc]</span>
            </Button>
          </Show>
        </div>
      )
    }} />
  )
}

export default DraftMoveMenuWidget
