import { hasLocalStorage } from '../Storage/LocalStorage/HasLocalStorage'
import { StateMap } from './StateMap.type'
import { StateMapDisk } from './state_map_disk.enum'

export default function createDefaultStateMap(): StateMap {
  return {
    id: null,
    dname: null,
    bio: null,
    disk: hasLocalStorage ? StateMapDisk.LocalStorage : StateMapDisk.Cloud,
  }
}
