import { createEffect, createMemo, on, onCleanup } from "solid-js"
import type { Scene } from "three"
import * as THREE from "three"
import type { Engine } from "../../core/engine/Engine.type"
import type { EntityId } from "../../core/entity/EntityId.type"
import type { ForestEntity } from "../../core/entity/EntityType/Forest"
import type { MountainEntity } from "../../core/entity/EntityType/Mountain"
import { isEntityForest, isEntityMountain } from "../../core/entity/entityTypeMetaList.generated"
import { getTreeModelSignal } from './createGetTreeModelGLTFSignal'
import createMountainPlane from './createMountainPlane'

export default function createMapTrees(engine : Engine, scene : Scene): void {
  const [getTreeModel] = getTreeModelSignal()

  const getTreeEnts = createMemo((): Array<ForestEntity | MountainEntity> => {
    if (getTreeModel()) {
      return engine.state.ents.filter((ent) => isEntityForest(ent) || isEntityMountain(ent))
    }
    return []
  })

  const entityMeshMap : Map<EntityId, THREE.Mesh | THREE.Group> = new Map()

  let generation = 1
  createEffect(on(getTreeEnts, (newList : Array<ForestEntity | MountainEntity>) => {
    const treeModel = getTreeModel()
    // console.log('setupModels', treeModel, newList.length)
    if (!getTreeModel()) {
      return
    }
    const halfStateWidth = engine.state.width / 2
    const halfStateHeight = engine.state.height / 2
    generation++
    // const treeEnts = getTreeEnts()
    newList.forEach(ent => {
      const entityId = ent.id
      const existingMesh = entityMeshMap.get(entityId)
      if (existingMesh) {
        existingMesh.userData.g = generation
      } else {
        const baseMesh = isEntityForest(ent)
          ? treeModel!.scene.clone()
          : createMountainPlane(ent)
        const newMesh = baseMesh
        newMesh.userData.id = entityId
        newMesh.userData.g = generation

        const x = (((ent.x - (halfStateWidth)) * 10) + 5)
        const y = -(((ent.y - (halfStateHeight)) * 10) + 5)
        newMesh.position.set(x, 0, y)

        entityMeshMap.set(entityId, newMesh)
        scene.add(newMesh)
      }
    })

    entityMeshMap.forEach((mesh, entityId) => {
      if (mesh.userData.g !== generation) {
        scene.remove(mesh)
        entityMeshMap.delete(entityId)
      }
    })
  }))


  onCleanup(() => {
    entityMeshMap.forEach(mesh => {
      scene.remove(mesh)
    })
  })


}
