import { createRoot, createSignal, type Signal } from "solid-js"
import { GLTFLoader, type GLTF } from 'three/addons/loaders/GLTFLoader.js'

const loader = new GLTFLoader();

export default function GLTFSignalFactory(modelFileAsStringOrUrl: string): () => Signal<GLTF | null> {
  return () => createRoot(() => {
    // eslint-disable-next-line solid/reactivity
    const signal = createSignal<GLTF | null>(null)

    loader.load(modelFileAsStringOrUrl, function (ModelObj) {
      // console.log('setModelObj', ModelObj)
      signal[1](ModelObj)
      // console.log(ModelObj.scene)
    }, undefined, function (error) {
      console.error(error)
    });

    return signal
  })
}
