import { modifyMutable, produce } from "solid-js/store"
import type { Nullable } from "../../../../typescript"
import { deepClone } from "../../../deep_clone"
import calculateDraftMoveRangedAttackEstimates from "../../draft_move/calculateDraftMoveRangedAttackEstimates"
import createDraftMove from "../../draft_move/createDraftMove"
import type { Engine } from "../../engine/Engine.type"
import type { Entity } from "../../entity/index"
import type { HasMobility } from "../../has_mobility"
import { SelectedToolId } from "../../map_editor/SelectedToolId.enum"
import type { HasPlayerId } from "../../player/has_player_id"
import { centerPanOnPosition } from "../../view_ctx/centerPanOnPosition"
import type { HasWaitedThisTurn } from "../../WaitedThisTurn"
import type { HasWasBuiltThisTurn } from "../../WasBuiltThisTurn"

export default function handleRotateNextUnit(engine: Engine, forward: boolean) {
  // console.log('handleRotateNextUnit')
  modifyMutable(engine, produce((engine : Engine) => {
    engine.selectedTool = SelectedToolId.MoveUnit
    const nextUnit = getNextUnit(engine, forward)
    console.log('nextUnit', deepClone(nextUnit))
    engine.draftMove = nextUnit ? createDraftMove(nextUnit) : null
    calculateDraftMoveRangedAttackEstimates(engine, engine.draftMove)
    if (nextUnit) {
      centerPanOnPosition(engine, nextUnit)
    }
  }))
}

function getNextUnit(engine: Engine, forward: boolean): Nullable<Entity> {
  const playerId = engine.selectedPlayerId || engine.authPlayerId
  const { draftMove, state } = engine
  const unit = draftMove?.unit
  console.log('playerId', playerId)
  const activePlayerEnts = state.ents.filter((ent) => {
    if ((ent as HasPlayerId).player_id !== playerId) {
      return false
    }
    console.log((ent as HasPlayerId).player_id, (ent as HasWaitedThisTurn).waitedThisTurn, (ent as HasWasBuiltThisTurn).builtThisTurn, !((ent as HasMobility).mobility > 0))
    if ((ent as HasWaitedThisTurn).waitedThisTurn) {
      return false
    }
    if ((ent as HasWasBuiltThisTurn).builtThisTurn) {
      return false
    }
    if (!((ent as HasMobility).mobility > 0)) {
      return false
    }
    return true
  })
  console.log('activePlayerEnts', deepClone(activePlayerEnts))

  const unitIndex = unit ? activePlayerEnts.findIndex((ent) => ent.id === unit.id) : -1
  console.log('unitIndex', deepClone(unitIndex))

  if (forward) {
    return activePlayerEnts[unitIndex + 1] || activePlayerEnts[0]
  }
  return activePlayerEnts[unitIndex - 1] || activePlayerEnts.at(-1)
}