export interface HasCBA {
  cba: number
}

export function byCbaDesc (a : HasCBA, b : HasCBA) {
  return b.cba - a.cba
}

export function byCbaAsc (a : HasCBA, b : HasCBA) {
  return a.cba - b.cba
}