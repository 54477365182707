import { modifyMutable, produce } from "solid-js/store"
import type { Engine } from "../../engine/Engine.type"
import setOrRotateDraftMoveUnloadableCargoPath from "../../draft_move/setOrRotateDraftMoveUnloadableCargoPath"

export default function handlePreviewUnloadPositionOptions(engine: Engine) {
  // console.log('handlePreviewUnloadPositionOptions')
  const { draftMove } = engine
  if (!draftMove) {
    return null
  }
  modifyMutable(engine, produce((engine: Engine) => {
    // temporarily set hoveredPosition to null
    // so it's not used against us in findUnloadableCargoPaths
    // if you wanted to unload at hoveredPosition,
    // you should have clicked first to set destPosition
    const { hoveredPosition: originalHoveredPosition } = engine
    engine.hoveredPosition = null
    setOrRotateDraftMoveUnloadableCargoPath(engine)
    engine.hoveredPosition = originalHoveredPosition
  }))
}
