export interface HasTotalCost {
  totalCost: number
}

export function byTotalCostDesc (a : HasTotalCost, b : HasTotalCost) {
  return b.totalCost - a.totalCost
}

export function byTotalCostAsc (a : HasTotalCost, b : HasTotalCost) {
  return a.totalCost - b.totalCost
}