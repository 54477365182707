import { isoNow } from '@sg/shared/src/lib/Date'
import { FaSolidArrowRotateLeft, FaSolidTrashCan } from 'solid-icons/fa'
import { createMemo } from 'solid-js'
import { backendAxios } from '../../axios'
import { createDeleteLobbyAction } from '../../lib/core/state/flux/action/Lobby/DeleteLobbyAction'
import { createRestoreLobbyAction } from '../../lib/core/state/flux/action/Lobby/RestoreLobbyAction'
import { createDeleteMapAction } from '../../lib/core/state/flux/action/Map/DeleteMapAction'
import { createRestoreMapAction } from '../../lib/core/state/flux/action/Map/RestoreMapAction'
import { StateType } from '../../lib/core/state/state_type.enum'
import type { StateOnlineId } from '../../lib/core/state/StateOnlineId.type'
import type { StateTypeMeta } from '../../lib/core/state/StateTypeMeta.type'
import createDefaultWait from '../../lib/createDefaultWait'
import NotImplemented from '../../lib/Exception/NotImplemented.class'
import { createSessionSignal } from '../../rx/shared/session/createSessionSignal'
import { LoadingSignal, createLoadingSignal } from '../../rx/signal/create_loading_signal'
import { AlertBagStore, createAlertBagStore, setAlertBagError, setAlertBagSuccess } from '../../rx/store/create_alert_bag_store'
import { Nullable } from '../../typescript'
import LoadingIcon from '../LoadingIcon'
import { GetAlertBagSuccessMessageFunction, GetItemValueFunction, OnChangeFunction } from './onClickMutateTimestampFunctionFactory'

interface Deletable {
  id: StateOnlineId
  deleted_at: string | null,
}

interface Props {
  disabled: Nullable<boolean>,
  item: Deletable,
  st: StateTypeMeta,
  // ariaLabel: string,
  onChange: OnChangeFunction,
  ab: AlertBagStore,
}

const getItemValue: GetItemValueFunction = function (item: Deletable) {
  return item?.deleted_at
} as GetItemValueFunction

function getAction(item: Deletable, stateType: StateTypeMeta) {
  const isDelete = !getItemValue(item)
  if (stateType.id === StateType.Lobby) {
    return isDelete ? createDeleteLobbyAction() : createRestoreLobbyAction()
  }
  if (stateType.id === StateType.Map) {
    return isDelete ? createDeleteMapAction() : createRestoreMapAction()
  }
  // if (stateType.id === StateType.Game) {
  //   return isDelete ? createDeleteGameAction() : createRestoreGameAction()
  // }
  // if (stateType.id === StateType.Replay) {
  //   return isDelete ? createDeleteReplayAction() : createRestoreReplayAction()
  // }
  throw new NotImplemented()
}

const getAlertBagSuccessMessage: GetAlertBagSuccessMessageFunction = function (newValue: string | null, noun: string): Nullable<string> {
  return newValue ? `${noun} deleted.` : `${noun} restored.`
} as GetAlertBagSuccessMessageFunction

const DeleteButton = (props: Props) => {
  const loading: LoadingSignal = createLoadingSignal()
  // eslint-disable-next-line solid/reactivity
  const ab = props.ab || createAlertBagStore()
  const session = createSessionSignal()[0]

  const noun = () => props.st.dname

  const ariaLabel = createMemo(() => {
    return props.item && (getItemValue(props.item) ? `Undelete ${noun()}` : `Delete ${noun()}`)
  })

  return (<button
    class="btn btn-danger btn-sm"
    disabled={!props.item?.id || props.disabled || loading()}
    aria-label={ariaLabel()}
    title={ariaLabel()}
    // onClick={onClickMutateTimestampFunctionFactory(
    //   props, timestampKey, ab, session, loading,
    //   getAlertBagSuccessMessage, getItemValue)}
    onClick={async () => {
      console.log('DeleteButton.onClick')
      const wait = createDefaultWait()
      const itemId = props.item.id
      if (!itemId) {
        // throw new Error('!itemId')
        return
      }
      try {
        setAlertBagError(ab, null)
        const user_id = session()?.sub
        if (!user_id) {
          return
        }
        loading.start()

        const newTimeStampValue = getItemValue(props.item) ? null : isoNow()
        const action = getAction(props.item, props.st)

        await backendAxios.post(`/${props.st.api}/${itemId}`, {
          action,
          cref: 1,
        })

        const absm = getAlertBagSuccessMessage(newTimeStampValue, noun())
        setAlertBagSuccess(ab, absm)

        props.onChange(newTimeStampValue)
      } catch (error) {
        // console.error(error)
        setAlertBagError(ab, error as Error)
      } finally {
        await wait
        loading.end()
      }
    }}
  >
    {(loading() || !props.item)
      ? <LoadingIcon />
      : (getItemValue(props.item)
        ? <FaSolidArrowRotateLeft />
        : <FaSolidTrashCan />)}
  </button>)
}

export default DeleteButton