import type { Nullable } from "../../../typescript"
import type { DraftMove_RangedEstimate } from "../draft_move/DraftMove_RangedEstimate.type"
import findByIdOrThrow from "../findByIdOrThrow"
import type { HasPrice } from "../HasPrice"
import entityTypeMetaList from "./entityTypeMetaList.generated"
import type { Entity } from "./index"

export default function getEntityResaleValue(ent: Nullable<Entity>): number {
  if (ent) {
    const etm = findByIdOrThrow(entityTypeMetaList, ent.etype_id)
    return ((etm as HasPrice).price ?? 0) * (ent.hp ?? 0)
  }
  return 0
}

export function byEntityResaleValueAsc(a: Entity, b: Entity) {
  return getEntityResaleValue(a) - getEntityResaleValue(b)
}
export function byEntityResaleValueDesc(a: Entity, b: Entity) {
  return getEntityResaleValue(b) - getEntityResaleValue(a)
}

export function byEstimateTargetEntityResaleValueAsc(a: DraftMove_RangedEstimate, b: DraftMove_RangedEstimate) {
  return getEntityResaleValue(a?.estimate?.target) - getEntityResaleValue(b?.estimate?.target)
}
export function byEstimateTargetEntityResaleValueDesc(a: DraftMove_RangedEstimate, b: DraftMove_RangedEstimate) {
  return getEntityResaleValue(b?.estimate?.target) - getEntityResaleValue(a?.estimate?.target)
}
