import type { Vector3 } from "three"
import { cos, sin } from "../../core/util/math"

export default function setFromSphericalCoords(
  obj : Vector3,
  radius : number,
  phi: number,
  theta: number): void {
  const sinPhiRadius = sin(phi) * radius
  obj.x = sinPhiRadius * sin(theta)
  obj.y = cos(phi) * radius
  obj.z = sinPhiRadius * cos(theta)
}