import { createEffect, createMemo, on, onCleanup } from "solid-js"
import type { Scene } from "three"
import * as THREE from "three"
import type { Engine } from "../../core/engine/Engine.type"
import entityDisplayName from "../../core/entity/entityDisplayName"
import type { EntityId } from "../../core/entity/EntityId.type"
import isMapControlTarget, { type MapControlTargetEntity } from '../../core/entity/isMapControlTarget'
import createDebugTextSprite from "./createDebugTextSprite"

export default function createMapControlEntities(engine : Engine, scene : Scene): void {
  const boxGeometry = new THREE.BoxGeometry( 1, 1, 1 ); 
  const greyBoxMaterial = new THREE.MeshBasicMaterial( {color: 0x888888} ); 
  const GreyPlaceholderBox = new THREE.Mesh( boxGeometry, greyBoxMaterial ); 
  GreyPlaceholderBox.scale.set(2, 2, 2)

  const getMapControlEntityList = createMemo((): Array<MapControlTargetEntity> => {
    return engine.state.ents.filter(isMapControlTarget)
  })

  const entityMeshMap : Map<EntityId, THREE.Mesh> = new Map()

  let generation = 1
  createEffect(on(getMapControlEntityList, (newList : Array<MapControlTargetEntity>) => {
    const halfStateWidth = engine.state.width / 2
    const halfStateHeight = engine.state.height / 2
    generation++
    newList.forEach(ent => {
      const entityId = ent.id
      const existingMesh = entityMeshMap.get(entityId)
      if (existingMesh) {
        existingMesh.userData.g = generation
      } else {
        const newMesh = GreyPlaceholderBox.clone()
        newMesh.userData.id = entityId
        newMesh.userData.g = generation

        const x = (((ent.x - (halfStateWidth)) * 10) + 5)
        const y = -(((ent.y - (halfStateHeight)) * 10) + 5)
        newMesh.position.set(x, 0, y)
        newMesh.scale.set(9, 1, 9)
        newMesh.position.y = 1

        // Create and add the debug text
        const debugText = createDebugTextSprite(`${entityDisplayName(ent)}
ID: ${entityId}
(${ent.x}, ${ent.y})
(${x}, ${y})
`)
        // debugText.scale.set(2, 2, 2) // Scale the text
        debugText.position.set(0, 5, 0) // Position the text above the box
        debugText.scale.set(1, 8, 0.1) // Adjust size to match box
        newMesh.add(debugText)

        entityMeshMap.set(entityId, newMesh)
        scene.add(newMesh)
      }
    })

    entityMeshMap.forEach((mesh, entityId) => {
      if (mesh.userData.g !== generation) {
        scene.remove(mesh)
        entityMeshMap.delete(entityId)
      }
    })
  }))

  onCleanup(() => {
    entityMeshMap.forEach(mesh => {
      scene.remove(mesh)
    })
  })
}
