import type { HasPlayerId } from '../player/has_player_id'
import type { HasWasBuiltThisTurn } from '../WasBuiltThisTurn'
import { isEntityFactory } from './entityTypeMetaList.generated'
import type { Entity } from './index'

export const enum FactoryBuildUnitsStatus {
  NotFactory = 2,
  Blocked = 3,
  WaitingForOrder = 4,
  OrderPlaced = 5,
}

/**
 * Will return true if a factory is not blocked by any units
 * for the purpose of constructing new units
 *
 * @param entity
 * @param state
 * @returns boolean
 */
export default function getFactoryBuildUnitsStatus(
  factoryEntity: Entity,
  entsAtPosition: Array<Entity>
): FactoryBuildUnitsStatus {
  if (isEntityFactory(factoryEntity)) {
    const player_id = factoryEntity.player_id
    if (player_id) {
      const builtThisTurnEnt = entsAtPosition.find(
        (ent) => (ent as HasWasBuiltThisTurn).builtThisTurn
      )
      if (builtThisTurnEnt) {
        return FactoryBuildUnitsStatus.OrderPlaced
      }
  
      const anyPlayerOwnedEnt = entsAtPosition.find(
        (ent) => (ent as HasPlayerId).player_id && ent.id !== factoryEntity.id
      )
      if (anyPlayerOwnedEnt) {
        return FactoryBuildUnitsStatus.Blocked
      }
    }
    return FactoryBuildUnitsStatus.WaitingForOrder
  }
  return FactoryBuildUnitsStatus.NotFactory
}
