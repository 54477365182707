import { modifyMutable, produce } from "solid-js/store"
import { BoxGeometry, Mesh, MeshBasicMaterial, type Intersection, type Scene } from "three"
import type { Nullable } from "../../../typescript"
import calculateDestPositionHoverPositionPaths from "../../core/draft_move/calculateDestPositionHoverPositionPaths"
import calculateDraftMoveAttackEstimates from "../../core/draft_move/calculateDraftMoveAttackEstimates"
import type { Engine } from "../../core/engine/Engine.type"
import type { TilePositionXY } from "../../core/tile_position_xy/TilePositionXY.type"
import coord from "../../core/tile_position_xy/coord"
import { samePosition } from "../../core/tile_position_xy/samePosition"
import { floor } from "../../core/util/math"
import toFixed1 from "../../core/util/toFixed1"
import { VERTICES_PER_TILE } from "../consts"
import createDebugTextSprite from "./createDebugTextSprite"

const hoverTileGeometry = new BoxGeometry(1, 1, 1)
const hoverTileMaterial = new MeshBasicMaterial({
  color: 0xcccccc,
  transparent: true,
  opacity: 0.25,
})

type UpdateHoverTileFunction = (intersection : Nullable<Intersection>) => void

export default function createHoverTileMesh(engine : Engine, scene : Scene): UpdateHoverTileFunction {
  const hoverTileBox = new Mesh(hoverTileGeometry, hoverTileMaterial)
  hoverTileBox.scale.set(8, 8, 8)
  // hoverTileBox.scale.set(9, 9, 9)
  // hoverTileBox.scale.set(10, 10, 10)
  hoverTileBox.position.set(0, 5, 0) // Position the text above the box
  hoverTileBox.visible = false

  scene.add(hoverTileBox)

  let prevHoverPosition : TilePositionXY | null = null
  return function updateHoverTile(intersection : Nullable<Intersection>): void {
    if (intersection) {
      const {
        width: stateWidth,
        height: stateHeight,
      } = engine.state
      const halfGeometryWidth = stateWidth * VERTICES_PER_TILE
      const halfGeometryHeight = stateHeight * VERTICES_PER_TILE
      // console.log('plainGeometry.parameters.height', plainGeometry.parameters.height, 'sizePerTile', plainGeometry.parameters.height / stateHeight)
      // const halfPlainGeometryParametersHeight = plainGeometry.parameters.height / 2
      // const halfPlainGeometryParametersWidth = plainGeometry.parameters.width / 2
      const halfPlainGeometryParametersHeight = halfGeometryWidth / 2
      const halfPlainGeometryParametersWidth = halfGeometryHeight / 2

      const { point } = intersection
      hoverTileBox.position.set(point.x, point.y, point.z)
      hoverTileBox.userData.vampire = true

      const stateX = floor((point.x + halfPlainGeometryParametersWidth) / VERTICES_PER_TILE)
      const stateY = floor((halfPlainGeometryParametersHeight - point.z) / VERTICES_PER_TILE)

      const nextHoverPosition = coord(stateX, stateY)
      const hoverPositionChanged = !samePosition(prevHoverPosition, nextHoverPosition)
      if (hoverPositionChanged) {
        hoverTileBox.clear()

        modifyMutable(engine, produce((engine: Engine) => {
          engine.hoveredPosition = prevHoverPosition = nextHoverPosition
          calculateDestPositionHoverPositionPaths(engine)
          calculateDraftMoveAttackEstimates(engine)
        }))
      }

      // const debugText = createDebugTextSprite(`(${stateX}, ${stateY})`)
      const debugText = createDebugTextSprite(`(${stateX}, ${stateY})
(${toFixed1(point.x)}, ${toFixed1(point.z)})`)
      // debugText.scale.set(2, 2, 2) // Scale the text
      debugText.position.set(0, 1, 0) // Position the text above the box
      debugText.scale.set(1, 1, 0.1) // Adjust size to match box
      hoverTileBox.clear()
      hoverTileBox.add(debugText)

      const x = (((stateX - ((stateWidth / 2))) * 10) + 5)
      const y = -(((stateY - ((stateHeight / 2))) * 10) + 5)
      hoverTileBox.position.set(x, 1, y)

      hoverTileBox.visible = true
    } else {
      hoverTileBox.visible = false
    }
  }
}
