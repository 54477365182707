import findByIdOrThrow from "../findByIdOrThrow"
import type { HasAnnexPoints } from "../HasAnnexPoints.type"
import type { AnnexableEntityUnion } from "./AnnexableEntityUnion.type"
import entityTypeMetaList from "./entityTypeMetaList.generated"

export default function resetAnnexable (annexableEnt : AnnexableEntityUnion): void {
  annexableEnt.ap_ent_id = null
  const annexableEntityTypeMeta = findByIdOrThrow(entityTypeMetaList, annexableEnt.etype_id)
  const defaultAnnexPoints = (annexableEntityTypeMeta.entDefaults as HasAnnexPoints).ap
  annexableEnt.ap = defaultAnnexPoints
}