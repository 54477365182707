import { DoubleSide, Mesh, MeshStandardMaterial, PlaneGeometry, Scene } from "three"
import type { Engine } from "../../core/engine/Engine.type"
import { isEntityPlain } from "../../core/entity/entityTypeMetaList.generated"
import getEntitiesAtPosition from "../../core/entity/getEntitiesAtPosition"
import coord from "../../core/tile_position_xy/coord"
import { floor, PI } from "../../core/util/math"
import { VERTICES_PER_TILE } from "../consts"
import getGrassTerrainNormalsTexture1 from "../texture/GrassTerrainNormalsTexture1"

// this did not work lol
export default function createGrassPlaneMesh(
  engine: Engine,
  scene: Scene
) {
  const { width: stateWidth, height: stateHeight } = engine.state
  const plainGeometry = new PlaneGeometry(stateWidth * VERTICES_PER_TILE, stateHeight * VERTICES_PER_TILE, 100, 100) // Higher segments for detail
  // const forestGeometry = new PlaneGeometry(stateWidth * VERTICES_PER_TILE, stateHeight * VERTICES_PER_TILE, 100, 100) // Higher segments for detail
  const plainMaterial = new MeshStandardMaterial({
    map: getGrassTerrainNormalsTexture1(),
    // map: getForestTerrainNormalsTexture1(),
    side: DoubleSide,
    // wireframe: true,
    // roughness: 0.4,
  })

  // Create the terrain plane
  const grassPlaneMesh = new Mesh(plainGeometry, plainMaterial)
  grassPlaneMesh.rotateX(-PI / 2) // Rotate the plane to lay flat
  scene.add(grassPlaneMesh)

  {
    // Modify plane's vertices
    const positionAttribute = plainGeometry.attributes.position
    const vertexCount = positionAttribute.count

    const geometry2Width = plainGeometry.parameters.width
    const geometry2Height = plainGeometry.parameters.height
    const halfWidth = geometry2Width / 2
    const halfHeight = geometry2Height / 2

    for (let i = 0; i < vertexCount; i++) {
      const x = positionAttribute.getX(i)
      const y = positionAttribute.getY(i)
      // const z = positionAttribute.getZ(i)
      const stateX = floor(((x + halfWidth) / VERTICES_PER_TILE))
      const stateY = floor(((y + halfHeight) / VERTICES_PER_TILE))
      const position = coord(stateX, stateY)
      const entStack = getEntitiesAtPosition(engine.state.ents, position)

      if (entStack.find(isEntityPlain)) {
        positionAttribute.setZ(i, 1)
      } else {
        positionAttribute.setZ(i, -1)
      }

      // const z = Math.sin(x * y * 0.005) * 15 // Custom manipulation (e.g., a wave)
      // positionAttribute.setZ(i, z)
    }
    positionAttribute.needsUpdate = true // Notify Three.js of the change
  }

  return grassPlaneMesh
}