import { captureException } from '@sentry/browser'
import { createEffect, on, onCleanup } from 'solid-js'
import { parse } from 'valibot'
import type { Engine } from '../../lib/core/engine/Engine.type'
import type Action from '../../lib/core/state/flux/action/Action.type'
import { ActionLogSchema, type ActionLog } from '../../lib/core/state/flux/ActionLog.type'
import alreadyHasActionLog from '../../lib/core/state/flux/alreadyHasActionLog'
import dispatchFromRemote from '../../lib/core/state/flux/dispatchFromRemote'
import { StateType } from '../../lib/core/state/state_type.enum'
import type { StateOnlineId } from '../../lib/core/state/StateOnlineId.type'
import type { Nullable } from '../../typescript'
import getAccessToken from '../shared/session/getAccessToken'

export default function registerServerSentEvents(engine: Engine) {
  // if (import.meta.env.VITE_MERCURE_ENDPOINT) {
  // eventSource = createMemo()
  let eventSource: EventSource | null = null

  function closeEventSourceIfExists() {
    // console.log('closeEventSourceIfExists', { exists: !!eventSource })
    if (eventSource) {
      eventSource.close()
    }
  }

  onCleanup(closeEventSourceIfExists)

  const stateId = () => engine.state.online?.id

  // eventSource.onmessage = (message) => {
  //   const data: object = JSON.parse(message.data)
  //   console.log('es.onmessage', data)
  //   messages.push(data)
  // }

  function onmessage(event: MessageEvent) {
    console.log('New message from server:', event.data)
  }

  async function onAction(event: MessageEvent): Promise<void> {
    console.log('New Action from server:', event.data)
    const data: { a: unknown, l: unknown } = JSON.parse(event.data)
    // dispatchFromRemote will validate action
    // const validatedAction = parse(ActionUnionSchema, event.data)
    const nonValidatedAction : Action = data.a as Action
    const log : ActionLog = parse(ActionLogSchema, data.l)

    if (alreadyHasActionLog(engine, log)) {
      return
    }

    await dispatchFromRemote(engine, nonValidatedAction, log)

    engine.actionLogs.push(log)
  }

  function onerror(error: Event) {
    console.error('SSE error:', error)
    captureException(error)
    if (eventSource?.readyState === EventSource.CLOSED) {
      // console.log('will reconnect soon...')
      setTimeout(() => {
        // console.log('reconnecting...')
        if (eventSource?.readyState === EventSource.CLOSED) {
          createOrReplaceEventSource(stateId())
        } else {
          console.warn('already reconnected?')
        }
      }, 5000)
    }
  }

  function onopen() {
    // console.log('SSE connection opened')
  }

  async function createOrReplaceEventSource(stateId: Nullable<StateOnlineId>) {
    // console.log('createOrReplaceEventSource', { stateId })
    closeEventSourceIfExists()
    if (stateId && engine.state.type !== StateType.Replay) {
      const access_token = await getAccessToken()
      if (access_token) {
        const hubURL = new URL('/api/sse', window.location.href)
        hubURL.searchParams.append('access_token', access_token)
        hubURL.searchParams.append('state', stateId as unknown as string)

        eventSource = new EventSource(hubURL)
        eventSource.onmessage = onmessage
        eventSource.onerror = onerror
        eventSource.onopen = onopen
        eventSource.addEventListener('Action', onAction)
      }
    }
  }

  createEffect(on(stateId, createOrReplaceEventSource))
}
