import createDefaultWait from "../../../createDefaultWait"
import type { Engine } from "../../engine/Engine.type"
import { PlayerTurnStatus } from "../../player/PlayerTurnStatus"
import { createEndTurnAction } from "../../state/flux/action/Game/EndTurnAction"
import dispatchClient from "../../state/flux/dispatchClient"
import type { Bot } from "../Bot.type"

export default async function botEndTurn(
  engine: Engine,
  bot: Bot
) {
  console.log('%c[botEndTurn]', 'color: grey; font-weight: lighter; font-size: 10px;')
  if (bot.player.turn_status === PlayerTurnStatus.Playing) {
    await dispatchClient(engine, createEndTurnAction(bot.player.id))
    await createDefaultWait(bot.stepDelay)
  }
}