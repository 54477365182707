import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
// import axiosRetry from 'axios-retry'
import addAuthorizationHeader from './rx/shared/session/addAuthorizationHeader'
import fetchRefreshedAccessToken from './rx/shared/session/fetchRefreshedAccessToken'
import getAccessToken from './rx/shared/session/getAccessToken'
import { Nullable } from './typescript'

// const BASE_URL = import.meta.env.VITE_HONO_URL;

export const authAxios = axios.create({
  // baseURL: import.meta.env.VITE_HONO_URL,
  baseURL: '/api',
  maxRedirects: 0,
  timeout: 30 * 1000, // 30s
  headers: {
    Accept: 'application/json',
  },
})

export const backendAxios = axios.create({
  // baseURL: import.meta.env.VITE_HONO_URL,
  baseURL: '/api',
  maxRedirects: 0,
  timeout: 30 * 1000, // 30s
  headers: {
    Accept: 'application/json',
  },
})

;(window as unknown as { axios: unknown }).axios = backendAxios

// const supabaseUrl = import.meta.env.VITE_SUPABASE_URL
// const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY
// export const supabaseAxios = axios.create({
//   baseURL: import.meta.env.VITE_SUPABASE_URL,
//   maxRedirects: 0,
//   timeout: 30 * 1000, // 30s
//   headers: {
//     accept: POSTGREST_ACCEPT_HEADER,
//     apikey: import.meta.env.VITE_SUPABASE_ANON_KEY,
//   },
// })

// ;(window as unknown as { sbAxios: unknown }).sbAxios = supabaseAxios

// if (import.meta.env.VITE_AXIOS_RETRY) {
//   axiosRetry(backendAxios, {
//     retries: 5,
//     retryDelay: axiosRetry.exponentialDelay,
//   })

//   axiosRetry(supabaseAxios, {
//     retries: 5,
//     retryDelay: axiosRetry.exponentialDelay,
//   })
// }

backendAxios.interceptors.request.use(addAuthorizationHeader)
// supabaseAxios.interceptors.request.use(addAuthorizationHeader)

applyRetryRequestIf401AndExpiredJwt(backendAxios)
// applyRetryRequestIf401AndExpiredJwt(supabaseAxios)

/*
 * example body of JWT expired response
 *
 * note that this response code was 400, but the JSON says 403
 * we shall not trust the status code.
 *
 * Server Status Code: 400
 * {
 *   error: "Unauthorized",
 *   message: "jwt expired",
 *   statusCode: "403",
 * }
 */
function isJwtExpiredResponse(response: AxiosResponse) {
  if (response?.status >= 400 && response?.data?.message === 'jwt expired') {
    return true
  }
  return false
}

// async function onAxiosApiRequest(config: AxiosRequestConfig): Promise<AxiosRequestConfig<any>> {
//   await waitIfRefreshingAccessToken()
//   return config
// }

function applyRetryRequestIf401AndExpiredJwt(axiosInstance: AxiosInstance) {
  // backendAxios.interceptors.request.use(function (
  //   request: InternalAxiosRequestConfig
  // ): InternalAxiosRequestConfig {
  //   return request
  // })
  axiosInstance.interceptors.response.use(function (response: AxiosResponse) {
    // if (response.status >= 400) {
    //   console.error('AxiosInterceptor', response.status, response.statusText, response)
    // } else {
    //   console.info('AxiosInterceptor', response.status, response.statusText, response)
    // }
    return response
  }, retryRequestIf401AndExpiredJwt)
  async function retryRequestIf401AndExpiredJwt(this: AxiosInstance, error: AxiosError) {
    // console.log('retryRequestIf401AndExpiredJwt.error', error)
    // console.log('retryRequestIf401AndExpiredJwt.this', this)
    const originalRequest = error.config
    const errorResponse: Nullable<AxiosResponse> = error?.response

    // TODO only do this if error message explcitly says JWT expired
    if (originalRequest && errorResponse && isJwtExpiredResponse(errorResponse)) {
      try {
        // ask for a new refresh token
        // console.log('retryRequestIf401AndExpiredJwt.fetchRefreshedAccessToken')
        await fetchRefreshedAccessToken()
        // acquire new access token
        const newAccessToken = await getAccessToken() // get new access token
        const oldAuthHeader = originalRequest.headers.Authorization
        const newAuthHeader = `Bearer ${newAccessToken}` // update the access token in header
        if (oldAuthHeader !== newAuthHeader) {
          return axiosInstance(originalRequest) // retry the original request with new token
        }
      } catch (refreshError) {
        return Promise.reject(refreshError) // if token refresh fails, reject the promise
      }
    } else {
      // console.log('applyRetryRequestIf401AndExpiredJwt.else', 'originalRequest', originalRequest)
    }
    return Promise.reject(error) // if not a 401 or already retried, just reject
  }
}
