import { useSearchParams } from '@solidjs/router'
import { createMemo, Show, Suspense } from 'solid-js'
import AlertBag from '../../components/AlertBag'
import GamePlayCanvas from '../../components/GamePlayCanvas'
import Loader from '../../components/Loader'
import { StateMapDisk } from '../../lib/core/state/state_map_disk.enum'
import { StateType } from '../../lib/core/state/state_type.enum'
import { parseIntOrNull } from '../../lib/core/util/math'
import refetchOnHotModuleReload from '../../lib/hmr/refetchOnHotModuleReload'
import createRedirectIfWrongStateTypeEffect from '../../rx/effect/createRedirectIfWrongStateTypeEffect'
import createGameRowResource from '../../rx/resource/createGameRowResource'
import { createLoadingSignal, registerLoadingResource } from '../../rx/signal/create_loading_signal'
import { createAlertBagStore, registerAlertBagResource } from '../../rx/store/create_alert_bag_store'
import type { Nullable } from '../../typescript'

type GamePlayPageSearchParams = {
  disk: string
  game_id: string
}

const componentStateType = StateType.Game

const GamePlayPage = () => {
  const [searchParams] = useSearchParams<GamePlayPageSearchParams>()

  const loading = createLoadingSignal()
  const ab = createAlertBagStore()

  const stateId = createMemo<Nullable<number>>((): Nullable<number> => parseIntOrNull(searchParams.game_id))

  if (searchParams.disk === StateMapDisk.LocalStorage) {
    // eslint-disable-next-line solid/components-return-once
    return <div id="GamePlayPage">
      <Loader signal={loading} />
      <AlertBag store={ab} />
      <GamePlayCanvas local />
    </div>
  }

  // eslint-disable-next-line solid/reactivity
  const stateItemResource = createGameRowResource(stateId)
  refetchOnHotModuleReload(stateItemResource)
  const [stateRow] = stateItemResource
  createRedirectIfWrongStateTypeEffect(componentStateType, () => stateRow()?.data?.type, () => stateRow()?.data?.online?.id)

  registerLoadingResource(loading, stateItemResource)
  registerAlertBagResource(ab, stateItemResource)

  // createEffect(() => {
  //   const deleted_at = stateRow()?.deleted_at
  //   if (deleted_at) {
  //     setAlertBagWarning(ab, 'This map has been marked for deletion and will soon be removed by an automated garbage collection process.')
  //   } else {
  //     setAlertBagWarning(ab, null)
  //   }
  // })

  // TODO only GamePlayCanvas can be seen
  return (
    <div id="GamePlayPage">
      <Loader signal={loading} />
      <AlertBag store={ab} />
      <Suspense fallback={<div class="text-muted">Loading...</div>}>
        <Show when={stateRow()} children={(sr) => <GamePlayCanvas row={sr()} />} />
      </Suspense>
    </div>
  )
}

export default GamePlayPage