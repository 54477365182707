import type { Nullable } from "../../../typescript"
import { isEntityBuilding, isEntityFactory, isEntityHQ } from "./entityTypeMetaList.generated"
import type { Entity } from "./index"

export default function getMapControlPriority(ent: Nullable<Entity>): number {
  if (ent) {
    if (isEntityHQ(ent)) {
      return 3
    }
    if (isEntityFactory(ent)) {
      return 2
    }
    if (isEntityBuilding(ent)) {
      return 1
    }
  }
  return 0
}

export function byEntityMapControlPriorityAsc(a: Entity, b: Entity) {
  return getMapControlPriority(a) - getMapControlPriority(b)
}
export function byEntityMapControlPriorityDesc(a: Entity, b: Entity) {
  return getMapControlPriority(b) - getMapControlPriority(a)
}
