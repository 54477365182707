import { createEffect, on } from "solid-js"
import type { Engine } from "../../../core/engine/Engine.type"
import type { LayerMeta } from "../../../core/layer_meta"
import { createOffscreenCanvas } from "../../create_canvas"
import { getCanvas2dContext } from "../../get_canvas_2d_context"

export default function createRenderHasErrorIconCanvasBuffer (engine : Engine, lm: LayerMeta, buffer: OffscreenCanvas) {

  const patternCanvas = createOffscreenCanvas(1, 1)
  const patternCtx = getCanvas2dContext(patternCanvas)

  if (patternCtx) {
    // Create a diagonal pattern
    patternCanvas.width = 10 // Set the size of the pattern
    patternCanvas.height = 10

    // Draw the diagonal line on the pattern canvas
    // patternCtx.strokeStyle = '#F00'
    patternCtx.strokeStyle = 'rgba(255, 0, 0, 0.15)'
    patternCtx.lineWidth = 2
    patternCtx.beginPath()
    patternCtx.moveTo(0, 10)
    patternCtx.lineTo(10, 0)
    patternCtx.stroke()
  }

  createEffect(on(() => engine.viewCtx.tile_size, (tile_size: number) => {
    buffer.width = tile_size
    buffer.height = tile_size
    lm.until = 0

    const ctx = getCanvas2dContext(buffer)
    if (ctx) {

      // Create the repeating pattern
      const pattern = ctx.createPattern(patternCanvas, 'repeat')

      ctx.clearRect(0, 0, tile_size, tile_size)

      ctx.filter = `drop-shadow(1px 1px #FAA)`

      if (pattern) {
        ctx.fillStyle = pattern
        ctx.fillRect(0, 0, tile_size, tile_size)
      }
      // ctx.fillStyle = 'rgba(0, 0, 0, 0.25)'
      ctx.strokeStyle = '#F00'
      ctx.lineWidth = 4
      // ctx.fillRect(1, 1, tile_size - 1, tile_size - 1)
      ctx.strokeRect(1, 1, tile_size - 1, tile_size - 1)
    }
  }))
}