import { now } from '@sg/shared/src/lib/Date'
import { createEffect, createMemo, on } from 'solid-js'
import type { PathTurnStepCollection } from 'src/lib/core/tile_position_xy/findMostEfficientPathToTileV2/PathTurnStepCollection.type.ts'
import type { Nullable } from 'vite-node'
import createLayerMeta from '../../../core/create_layer_meta'
import { Engine } from '../../../core/engine/Engine.type.ts'
import type { BaseEntity } from '../../../core/entity/BaseEntity'
import type { Entity } from '../../../core/entity/index'
import isBeingAnnexed from '../../../core/entity/isBeingAnnexed'
import whereHasError from '../../../core/entity/whereHasError'
import { EXPIRE_NEVER } from '../../../core/layer_meta'
import { SelectedToolId } from '../../../core/map_editor/SelectedToolId.enum.ts'
import createLazyEfficientPathGrid from '../../../core/tile_position_xy/createLazyEfficientPathGrid'
import entityListToGridXY from '../../../core/tile_position_xy/entityListToGridXY'
import type { TilePositionXY } from '../../../core/tile_position_xy/TilePositionXY.type'
import { deepClone } from '../../../deep_clone'
import bindCanvasToEngine from '../../bind_canvas_to_engine.ts'
import { createCanvasElement, createOffscreenCanvas } from '../../create_canvas.ts'
import { disableCanvasSmoothing } from '../../disable_canvas_smoothing.ts'
import drawPaintTileSelectHoverGhostEntityOnPosition from '../../draw/cursor/draw_paint_tile_select_hover_ghost_entity_on_position.ts'
import createRenderAnnexIconCanvasBuffer from '../../draw/entity_icons/createRenderAnnexIconCanvasBuffer'
import createRenderClassicPlusIconCanvasBuffer from '../../draw/entity_icons/createRenderClassicPlusIconCanvasBuffer'
import createRenderHasErrorIconCanvasBuffer from '../../draw/entity_icons/createRenderHasErrorIconCanvasBuffer'
import createRenderRightToBracketIconCanvasBuffer from '../../draw/entity_icons/createRenderRightToBracketIconCanvasBuffer'
import createRenderTransportLoadedIconCanvasBuffer from '../../draw/entity_icons/createRenderTransportLoadedIconCanvasBuffer.ts'
import { getCanvas2dContext } from '../../get_canvas_2d_context'

const name = 'TerrainCursor'
const relZIndex = 0

// @link https://lospec.com/palette-list/eulbink
// const MovementArrowBlue1 = '#0ce6f2'
const MovementArrowBlue2 = '#201533'

export default function addTerrainCursorCanvasOld(engine: Engine) {
  // console.log(`${name}.add`)
  const lm = createLayerMeta()
  const elem = createCanvasElement()
  const buffer1 = createCanvasElement()
  const annexIconBuffer = createOffscreenCanvas(1,1)
  const hasErrorIconBuffer = createOffscreenCanvas(1,1)
  const transportLoadedBuffer = createOffscreenCanvas(1,1)
  const rightToBracketIconBuffer = createOffscreenCanvas(1,1)
  const classicPlusIconBuffer = createOffscreenCanvas(1,1)
  elem.setAttribute('name', `${name}`)
  // const { viewCtx } = engine
  // const state = engine.cache[0]
  // const track = engine.cache[0]
  elem.style.zIndex = (engine.viewCtx.zIndex + relZIndex).toString()

  // createEffect(() => {
  //   // track(EngineCache.Cursor)
  //   lm.until = 0
  // })
  let width = 1
  let height = 1

  let selectedTool = engine.selectedTool
  let hoveredPosition = engine.hoveredPosition
  let mouseDownPosition = engine.mouseDownPosition
  let selectedPosition = engine.selectedPosition
  let lastMouseMoveEvent = engine.lastMouseMoveEvent

  let renderWidthPx = engine.viewCtx.renderWidthPx
  let renderHeightPx = engine.viewCtx.renderHeightPx
  let tile_size = engine.viewCtx.tile_size
  let tile_gap_px = engine.viewCtx.tile_gap_px
  let canvas_edge_buffer = engine.viewCtx.canvas_edge_buffer
  let lastPanAt = engine.viewCtx.lastPanAt
  let pan_x = engine.viewCtx.pan_x
  let pan_y = engine.viewCtx.pan_y

  let tile_size_plus_gap: number = tile_size + tile_gap_px

  createEffect(() => {
    const { state, viewCtx } = engine
    width = state.width
    height = state.height

    selectedTool = engine.selectedTool
    hoveredPosition = engine.hoveredPosition
    mouseDownPosition = engine.mouseDownPosition
    selectedPosition = engine.selectedPosition
    lastMouseMoveEvent = engine.lastMouseMoveEvent

    renderWidthPx = viewCtx.renderWidthPx
    renderHeightPx = viewCtx.renderHeightPx
    tile_size = viewCtx.tile_size
    tile_gap_px = viewCtx.tile_gap_px
    canvas_edge_buffer = viewCtx.canvas_edge_buffer
    lastPanAt = viewCtx.lastPanAt
    pan_x = viewCtx.pan_x
    pan_y = viewCtx.pan_y

    tile_size_plus_gap = tile_size + tile_gap_px

    lm.until = 0
  })

  const getAnnexEnts = createMemo((): Array<Entity> => {
    lm.until = 0
    const annexEnts = engine.state.ents.filter(isBeingAnnexed)
    console.log('addTerrainCursorCanvasOld.effect', deepClone(annexEnts))
    return annexEnts
  })
  createRenderAnnexIconCanvasBuffer(engine, lm, annexIconBuffer)
  const getHasErrorEnts = createMemo((): Array<Entity> => {
    lm.until = 0
    const hasErrorEnts = engine.state.ents.filter(whereHasError)
    return hasErrorEnts
  })
  createRenderHasErrorIconCanvasBuffer(engine, lm, hasErrorIconBuffer)
  let loadedTranportEnts: Array<Entity> = []
  createEffect(() => {
    loadedTranportEnts = engine.state.ents.filter((e) => (e as BaseEntity).cargo?.length as number > 0)
    lm.until = 0
  })
  createRenderTransportLoadedIconCanvasBuffer(engine, lm, transportLoadedBuffer)

  let upts : PathTurnStepCollection | null = null
  let getEfficientPathToTile: Nullable<(x: number, y: number) => PathTurnStepCollection | null> = null
  let entityGridXY: ReturnType<typeof entityListToGridXY> | null = null

  createEffect(on(() => engine.hoveredPosition, (hoveredPosition : Nullable<TilePositionXY>) => {
    // console.log('createEffect(on(() => engine.hoveredPosition')
    const { state, draftMove } = engine
    upts = null
    if (hoveredPosition && draftMove) {
      const { unit } = draftMove
      if (unit) {
        const { width, height, ents } = state
        entityGridXY = entityListToGridXY(ents, width, height)
        getEfficientPathToTile = createLazyEfficientPathGrid(width, height, entityGridXY, unit)
        upts = getEfficientPathToTile(hoveredPosition.x, hoveredPosition.y)
      }
    } else {
      // getEfficientPathToTile = null
      // upts = null
    }
    lm.until = 0
  }))
  createRenderRightToBracketIconCanvasBuffer(engine, lm, rightToBracketIconBuffer)

  createRenderClassicPlusIconCanvasBuffer(engine, lm, classicPlusIconBuffer)

  function render(timeStamp: number) {
    // console.log(`${name}.render`)
    lm.ts = timeStamp

    // frames have indicated they
    // plan on rendering the same image
    if (lm.until > timeStamp) {
      return
    }
    lm.until = timeStamp + EXPIRE_NEVER
    // console.log(`${name}.render`)

    // buffer1 will be the full map, unpanned
    const bufferWidthPx = tile_size * width + tile_gap_px * (width - 1) + 2 * canvas_edge_buffer
    const bufferHeightPx = tile_size * height + tile_gap_px * (height - 1) + 2 * canvas_edge_buffer

    const ctx1 = getCanvas2dContext(buffer1)
    if (!ctx1) return
    const ctx0 = getCanvas2dContext(elem)
    if (!ctx0) return

    // reset buffer1
    buffer1.width = bufferWidthPx
    buffer1.height = bufferHeightPx
    ctx1.clearRect(0, 0, bufferWidthPx, bufferHeightPx)
    disableCanvasSmoothing(ctx1)


    const annexEnts = getAnnexEnts()
    for (let index = 0; index < annexEnts.length; index++) {
      const ent = annexEnts[index]
      const dx: number = canvas_edge_buffer + ent.x * tile_size_plus_gap
      const dy: number = canvas_edge_buffer + ent.y * tile_size_plus_gap

      ctx1.drawImage(annexIconBuffer, dx, dy, tile_size, tile_size)
    }

    const hasErrorEntsList = getHasErrorEnts()
    for (let index = 0; index < hasErrorEntsList.length; index++) {
      const ent = hasErrorEntsList[index]
      const dx: number = canvas_edge_buffer + ent.x * tile_size_plus_gap
      const dy: number = canvas_edge_buffer + ent.y * tile_size_plus_gap

      ctx1.drawImage(hasErrorIconBuffer, dx, dy, tile_size, tile_size)
      ctx1.fillStyle = 'black'
      ctx1.fillText(`[${ent.x},${ent.y}]`, dx, dy + (0.1 * tile_size))
    }

    for (let index = 0; index < loadedTranportEnts.length; index++) {
      const ent = loadedTranportEnts[index]
      const dx: number = canvas_edge_buffer + ent.x * tile_size_plus_gap
      const dy: number = canvas_edge_buffer + ent.y * tile_size_plus_gap

      ctx1.drawImage(transportLoadedBuffer, dx, dy, tile_size, tile_size)
    }

    if (upts) {
      // console.log('upts', deepClone(upts))
      const { lastStep } = upts
      if (lastStep) {
        if (lastStep.taxis?.length) {
          const dx: number = canvas_edge_buffer + (lastStep.x * tile_size_plus_gap)
          const dy: number = canvas_edge_buffer + (lastStep.y * tile_size_plus_gap)
    
          ctx1.filter = `drop-shadow(5px 5px 10px ${MovementArrowBlue2})`
          ctx1.drawImage(
            rightToBracketIconBuffer,
            dx,
            dy,
            rightToBracketIconBuffer.width,
            rightToBracketIconBuffer.height
          )
        }
        if (lastStep.merge) {
          const dx: number = canvas_edge_buffer + (lastStep.x * tile_size_plus_gap)
          const dy: number = canvas_edge_buffer + (lastStep.y * tile_size_plus_gap)
    
          ctx1.filter = `drop-shadow(5px 5px 10px ${MovementArrowBlue2})`
          ctx1.drawImage(
            classicPlusIconBuffer,
            dx,
            dy,
            classicPlusIconBuffer.width,
            classicPlusIconBuffer.height
          )
        }
      }
    }

    if (lastPanAt) {
      if (lastPanAt + 500 >= now()) {
        if (lastMouseMoveEvent !== null && mouseDownPosition !== null) {
          // drawPanCursorOnPosition(ctx1, lastMouseMoveEvent, viewCtx)
        }
      } else {
        engine.viewCtx.lastPanAt = 0
      }
    } else if (selectedTool === SelectedToolId.Inspect) {
      if (hoveredPosition !== null && mouseDownPosition === null) {
        // drawHoverInspectCursorOnPosition(ctx1, hoveredPosition, tile_size, canvas_edge_buffer, tile_size_plus_gap)
      }

      if (mouseDownPosition !== null || selectedPosition !== null) {
        const position = mouseDownPosition || selectedPosition
        if (position) {
          // inspectSelectedCursorRenderFn(ctx1, lm, position, tile_size)
          // drawSelectedInspectCursorOnPosition(ctx1, position, tile_size, canvas_edge_buffer, tile_size_plus_gap)
        }
      }
    } else if (selectedTool === SelectedToolId.Paint) {
      if (hoveredPosition !== null && mouseDownPosition === null) {
        const entity = engine.paintToolOption.entity
        if (entity) {
          drawPaintTileSelectHoverGhostEntityOnPosition(
            engine,
            ctx1,
            entity,
            lm,
            hoveredPosition,
            tile_size,
            canvas_edge_buffer,
            tile_size_plus_gap,
            timeStamp
          )
        }
        // drawHoverPaintCursorOnPosition(ctx1, hoveredPosition, tile_size, canvas_edge_buffer, tile_size_plus_gap)
      }
      // if (mouseDownPosition !== null || selectedPosition !== null) {
      //   const position = mouseDownPosition || selectedPosition
      //   if (position) {
      //     drawSelectedPaintCursorOnPosition(ctx1, position, tile_size, canvas_edge_buffer, tile_size_plus_gap)
      //   }
      // }
    } else if (selectedTool === SelectedToolId.Erase) {
      // if (hoveredPosition !== null/* && mouseDownPosition === null*/) {
      //   drawHoverEraseCursorOnPosition(ctx1, hoveredPosition, tile_size, canvas_edge_buffer, tile_size_plus_gap)
      // }
      // if (mouseDownPosition !== null || selectedPosition !== null) {
      //   const position = mouseDownPosition || selectedPosition
      //   if (position) {
      //     drawSelectedEraseCursorOnPosition(ctx1, position, tile_size, canvas_edge_buffer, tile_size_plus_gap)
      //   }
      // }
    }

    // reset screen
    elem.width = renderWidthPx
    elem.height = renderHeightPx
    ctx0.clearRect(0, 0, renderWidthPx, renderHeightPx)
    disableCanvasSmoothing(ctx0)

    // write buffer1 to screen
    ctx0.drawImage(buffer1, pan_x - canvas_edge_buffer, pan_y - canvas_edge_buffer)
  }

  // eslint-disable-next-line solid/reactivity
  bindCanvasToEngine(engine, elem, render)
}
