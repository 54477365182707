import { ONE_DAY_SECONDS } from '@sg/shared/src/util/numbers'
import { DESCRIPTION_MAXIMUM, DESCRIPTION_MINIMUM, DNAME_MAXIMUM, DNAME_MINIMUM, GAME_PASSPHRASE_MAXIMUM, GAME_PASSPHRASE_MINIMUM } from '@sg/shared/src/valibot/schema/DisplayName.type'
import { A, NavigateOptions, useNavigate, useSearchParams } from '@solidjs/router'
import { FormGroup, FormLabel, FormText } from 'solid-bootstrap'
import { FaSolidBook, FaSolidMap, FaSolidUsers } from 'solid-icons/fa'
import { Component, createEffect, createMemo, For, JSX, onMount, Show, Suspense } from 'solid-js'
import { createMutable, modifyMutable, produce, unwrap } from 'solid-js/store'
import toast from 'solid-toast'
import { enum_, fallback, safeParse } from 'valibot'
import { backendAxios } from '../../axios'
import AlertBag from '../../components/AlertBag'
import AlertDanger from '../../components/AlertDanger'
import AlertWarning from '../../components/AlertWarning'
import Breadcrumbs from '../../components/Breadcrumb/Breadcrumbs'
import EngineContext from '../../components/EngineContext'
import FormSection from '../../components/Form/FormSection'
import MapShowCanvas from '../../components/MapShowCanvas'
import SignInButton from '../../components/SignInButton'
import CreateGamePlayersEditor from '../../components/StateEditor/CreateGamePlayersEditor'
import RestrictedUnitRuleEditor from '../../components/StateEditor/RestrictedUnitEditor'
import toastError from '../../lib/AlertBag/toastError'
import createEngineForUI from '../../lib/core/engine/createEngineForUI'
import { Engine } from '../../lib/core/engine/Engine.type'
import syncEngineWithPropsRow from '../../lib/core/engine/syncEngineWithPropsRow'
import { hasCloud } from '../../lib/core/has_cloud'
import isPlayerSlotTaken from '../../lib/core/player/isPlayerSlotTaken'
import { PlayerKind } from '../../lib/core/player/PlayerKind.enum'
import { MaxUnitsPerPlayerMax, MaxUnitsPerPlayerMin, WinMapControlPercentMax, WinMapControlPercentMin, WinMaxDaysMax, WinMaxDaysMin } from '../../lib/core/state/create_default_state_config'
import createDefaultStateOnline from '../../lib/core/state/create_default_state_online'
import { createPatchConfigAction } from '../../lib/core/state/flux/action/Lobby/PatchConfigAction'
import { createStartNewGameAction, StartNewGameStateSchema } from '../../lib/core/state/flux/action/Lobby/StartNewGameAction'
import dispatchClient from '../../lib/core/state/flux/dispatchClient'
import mapDisplayName from '../../lib/core/state/mapDisplayName'
import { StateMapDisk, StateMapDiskSchema } from '../../lib/core/state/state_map_disk.enum'
import { StateOnline } from '../../lib/core/state/state_online.type'
import { StateType } from '../../lib/core/state/state_type.enum'
import { StateOnlineIdSchema } from '../../lib/core/state/StateOnlineId.type'
import { hasLocalStorage } from '../../lib/core/Storage/LocalStorage/HasLocalStorage'
import { saveGameToLocalStorage } from '../../lib/core/Storage/LocalStorage/localStorageEngineLoader'
import { parseIntOrNull, range } from '../../lib/core/util/math'
import { values } from '../../lib/core/util/Object'
import Unexpected from '../../lib/Exception/Unexpected.class'
import getBackPath from '../../lib/getBackPath'
import Button from '../../lib/jsx/Button'
import createRedirectIfWrongStateTypeEffect from '../../rx/effect/createRedirectIfWrongStateTypeEffect'
import createSetEngineAuthPlayerIdFromAuthProfileSignalEffect from '../../rx/effect/createSetEngineAuthPlayerIdFromAuthProfileSignalEffect'
import createValibotSafeParseMemo from '../../rx/memo/createValibotSafeParseMemo'
import createLobbyRowResource from '../../rx/resource/createLobbyRowResource'
import createMapRowResource from '../../rx/resource/createMapRowResource'
import createAuthProfileSignal from '../../rx/shared/profile/createAuthProfileSignal'
import canSessionCreateGame from '../../rx/shared/session/canSessionCreateGame'
import { createIsGuestMemo } from '../../rx/shared/session/createIsGuestMemo'
import { createSessionSignal } from '../../rx/shared/session/createSessionSignal'
import { createLoadingSignal } from '../../rx/signal/create_loading_signal'
import registerServerSentEvents from '../../rx/sse/registerServerSentEvents'
import { setAlertBagError } from '../../rx/store/create_alert_bag_store'
import type { Nullable } from '../../typescript'

const navigateOptions: Partial<NavigateOptions> = {
  replace: true,
}

interface MenuTabMeta {
  key: string
  dname: string
  icon: JSX.Element | null
}

enum MenuTabMetaKey {
  Basic = 'basic',
  Players = 'players',
  Map = 'map',
  Rules = 'rules',
}

const MenuTabMetaKeySchema = enum_(MenuTabMetaKey, 'Invalid Menu Tab')

// type PlayCreateGamePageStep2SearchParams = {
//   game_id: string
//   tab: MenuTabMetaKey
// }

const componentStateType = StateType.Lobby

const PlayCreateGamePage: Component = () => {
  console.log('PlayCreateGamePage')
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const urlGameId = createValibotSafeParseMemo(StateOnlineIdSchema, () => parseIntOrNull(searchParams.game_id))
  const urlMapId = createValibotSafeParseMemo(StateOnlineIdSchema, () => parseIntOrNull(searchParams.map_id))
  const urlDisk = createValibotSafeParseMemo(StateMapDiskSchema, () => searchParams.disk)
  const urlTab = createValibotSafeParseMemo(fallback(MenuTabMetaKeySchema, () => MenuTabMetaKey.Players), () => searchParams.tab)

  onMount(() => {
    const disk = urlDisk().value
    if (disk === StateMapDisk.LocalStorage) {
      if (!urlMapId()) {
        // direct the user to pick a map first
        navigate(`/maps`)
      }
    } else if (disk === StateMapDisk.Cloud) {
      if (!urlGameId()) {
        // direct the user to pick a map first
        navigate(`/maps`)
      }
    } else {
      navigate(`/maps`)
    }
  })

  function toDaysOption(value: number): JSX.Element {
    // return ONE_DAY_SECONDS * value
    return <option value={`${ONE_DAY_SECONDS * value}`}>{value} Day{value === 1 || 's'}</option>
  }

  const tabsMap: Readonly<Record<string, MenuTabMeta>> = [
    // { key: 'basic', dname: 'Basic', icon: null },
    { key: 'players', dname: 'Players', icon: <FaSolidUsers /> },
    { key: 'map', dname: 'Map', icon: <FaSolidMap /> },
    { key: 'rules', dname: 'Rules', icon: <FaSolidBook /> },
  ].reduce((carry: Record<string, MenuTabMeta>, tab) => (carry[tab.key] = tab, carry), {})

  const loading = createLoadingSignal()
  // const [state, setState] = createStore<State>(createDefaultNewGameState(1, 1))
  // const [online, setOnline] = createStore<StateOnline>(createDefaultStateOnline())
  const engine: Engine = createMutable(createEngineForUI())
  // eslint-disable-next-line solid/reactivity
  engine.state.type = StateType.Lobby
  // eslint-disable-next-line solid/reactivity
  engine.toast = toast

  const [authProfile] = createAuthProfileSignal()
  createSetEngineAuthPlayerIdFromAuthProfileSignalEffect(engine, authProfile)

  const online = createMutable<StateOnline>(createDefaultStateOnline())
  const session = createSessionSignal()[0]
  const isGuest = createIsGuestMemo()
  createEffect(() => {
    if (!tabsMap[searchParams.tab as string]) {
      setSearchParams({ tab: 'players' }, navigateOptions)
    }
  })

  // const gameItemResource = createGameItemResource(session, searchParams)
  // const [game] = gameItemResource

  const lobbyRowResource = createLobbyRowResource((): Nullable<number> => urlGameId().value)
  const [lobbyRow] = lobbyRowResource
  const isLobbyOwner = createMemo(() => lobbyRow()?.owner?.id === session()?.sub && session()?.sub)
  createEffect(() => {
    syncEngineWithPropsRow(engine, lobbyRow())
  })

  const mapRowResource = createMapRowResource((): Nullable<number> => urlMapId().value)
  const [mapRow] = mapRowResource
  // const isMapOwner = createMemo(() => mapRow()?.owner?.id === session()?.sub && session()?.sub)
  createEffect(() => {
    const map = mapRow()
    if (map) {
      const { data } = map
      syncEngineWithPropsRow(engine, {
        ...map,
        data: {
          ...data,
          type: StateType.Lobby,
          online: null,
          players: data.players.map((p, index) => ({
            ...p,
            kind: index === 0 ? PlayerKind.Human : PlayerKind.Bot,
            dname: index === 0 ? (authProfile()?.dname || 'Player') : p.dname,
            is_local: true,
          }))
        },
      })
    }
  })

  registerServerSentEvents(engine)
  createRedirectIfWrongStateTypeEffect(componentStateType, () => engine.state.type, () => engine.state.online?.id)

  const startNewGameValidation = createMemo(() => safeParse(StartNewGameStateSchema, engine.state))

  const totalPlayerSlotsCount = createMemo<number>((): number => {
    return engine.state.players.length || 0
  })
  const occupiedPlayerSlotsCount = createMemo<number>((): number => {
    // console.log('occupiedPlayerSlotsCount',
    //   engine.state.players.filter(isPlayerSlotTaken).length || 0,
    //   engine.state.players.map(p => ({isTaken: isPlayerSlotTaken(p), ...p})))
    return engine.state.players.filter(isPlayerSlotTaken).length || 0
  })

  const needsToSignInBeforeCanCreateGame = createMemo(() => {
    return engine.state.online && !canSessionCreateGame(session())
  })

  function handleBackButtonClick() {
    // console.log('handleBackButtonClick')
    navigate(getBackPath() as string)
  }

  // uncomment if createRedirectIfWrongStateTypeEffect does not work
  // createGameStartedEffect(engine, () => {
  //   navigate(`/game/play?game_id=${engine.state.online?.id}`)
  // })

  const selectedTab = createMemo(() => tabsMap[urlTab().value as string] || tabsMap.basic)

  return (
    <EngineContext.Provider value={engine}>
      <div>
        <Breadcrumbs>
          <A href="/lobby/create">Create Game</A>
        </Breadcrumbs>
        <nav class="container w-32-rem">
          <div class="nav nav-tabs" role="tablist">
            <For each={values(tabsMap)}>
              {(tab) => (
                <button class={(tab === selectedTab()) ? 'nav-link active' : 'nav-link'}
                  type="button"
                  role="tab"
                  aria-controls={tab.dname}
                  onClick={() => {
                    setSearchParams({
                      tab: tab.key,
                    }, navigateOptions)
                  }}>
                  {tab.icon && tab.icon}
                  {tab.icon && ' '}
                  {tab.dname}
                </button>
              )}
            </For>
          </div>
        </nav>
        <Show when={isGuest()}>
          <AlertWarning>
            To join this game's lobby, <SignInButton />
          </AlertWarning>
        </Show>
        <div class="container overflow-y-auto overflow-x-hidden w-32-rem h-75vh">
          <FormSection show={true}>
            <h2>Simple Tactics - Play - New Game</h2>
            {/* <BreakPointText /> */}
          </FormSection>
          <AlertBag store={engine.ab} />
          <FormSection show={'map' === searchParams.tab}>
            <h4>Map</h4>
            <FormGroup>
              <FormLabel class="w-25">{mapDisplayName(engine.state)}</FormLabel>
            </FormGroup>
            {/* <MapPickerModal state={engine.state} onChange={(newState) => engine.state = newState}} /> */}
            <div class="text-center">
              <Suspense fallback={<div>Loading...</div>}>
                <Show when={lobbyRow() || mapRow()}>
                  <MapShowCanvas state={engine.state} width={480} height={480} />
                </Show>
              </Suspense>
            </div>
          </FormSection>
          <FormSection show={'players' === searchParams.tab}>
            <h4>
              Players{' '}
              <span class="text-muted text-monospace">[{occupiedPlayerSlotsCount()}/{totalPlayerSlotsCount()}]</span>
            </h4>
            <CreateGamePlayersEditor />
          </FormSection>
          <FormSection show={'basic' === searchParams.tab}>
            <h4>Basic</h4>
            <FormGroup>
              <FormLabel class="w-25">Type</FormLabel>
              <select class="form-control w-75 d-inline" name="is_online"
                value={engine.state.online ? '1' : '0'}
                onInput={(event: InputEvent) => {
                  modifyMutable(engine, produce((engine) => {
                    if ((event.target as HTMLSelectElement)?.value === '1') {
                      engine.state.online = unwrap(online)
                    } else {
                      delete engine.state.online
                    }
                  }))
                }}
              >
                <option value="0">Offline</option>
                <option value="1">Cloud</option>
              </select>
              <Show when={needsToSignInBeforeCanCreateGame()}>
                <AlertWarning>
                  <A href="/auth/sign-in">Sign In</A> to play online
                </AlertWarning>
              </Show>
            </FormGroup>
            <Show when={engine.state.online} children={(getter) => {
              const online = getter()
              return <>
                <h3>Cloud options</h3>
                <FormGroup>
                  <FormLabel class="w-25">Name</FormLabel>
                  <input class="form-control w-75 d-inline" name="dname" type="text"
                    value={online.dname || ''}
                    onInput={(event: InputEvent) => {
                      modifyMutable(online, produce((online) => {
                        online.dname = (event.target as HTMLInputElement)?.value
                      }))
                    }}
                    minLength={DNAME_MINIMUM} maxLength={DNAME_MAXIMUM}
                  />
                  <FormText>optional short description</FormText>
                </FormGroup>
                <FormGroup>
                  <FormLabel class="w-25">Passphrase</FormLabel>
                  <input class="form-control w-75 d-inline" name="passphrase" type="text"
                    value={online.passphrase || ''}
                    onInput={(event: InputEvent) => {
                      modifyMutable(online, produce((online) => {
                        online.passphrase = (event.target as HTMLInputElement)?.value
                      }))
                    }}
                    minLength={GAME_PASSPHRASE_MINIMUM} maxLength={GAME_PASSPHRASE_MAXIMUM}
                  />
                  <FormText>If left non-blank, only users who know the game's secret passphrase can join.</FormText>
                </FormGroup>
                <FormGroup>
                  <FormLabel>Description &nbsp;<FormText class="d-inline">optional long description</FormText></FormLabel>
                  <textarea class="form-control" name="bio"
                    value={online.bio || ''}
                    onInput={(event: InputEvent) => {
                      modifyMutable(online, produce((online) => {
                        online.bio = (event.target as HTMLTextAreaElement)?.value
                      }))
                    }}
                    minLength={DESCRIPTION_MINIMUM} maxLength={DESCRIPTION_MAXIMUM}
                  />
                </FormGroup>
                <div>
                  <h2>Timers</h2>

                  <FormGroup>
                    <FormLabel class="w-50">Initial Time</FormLabel>
                    <select class="form-control w-50 d-inline" name="initialTime"
                      value={online.initialTime}
                      onInput={(event: InputEvent) => {
                        modifyMutable(online, produce((online) => {
                          online.initialTime = parseFloat((event.target as HTMLSelectElement)?.value)
                        }))
                      }}
                    >{range(1, 28).map(toDaysOption)}</select>
                    <FormText>At the start of the game, a player is given this much spare time to delay starting their next turn. The player should save this time for emergencies. If this time runs out, the player loses automatically.</FormText>
                  </FormGroup>
                  <FormGroup>
                    <FormLabel class="w-50">Extra Time Per Turn</FormLabel>
                    <select class="form-control w-50 d-inline" name="extraTimePerTurn"
                      value={online.extraTimePerTurn}
                      onInput={(event: InputEvent) => {
                        modifyMutable(online, produce((online) => {
                          online.extraTimePerTurn = parseFloat((event.target as HTMLSelectElement)?.value)
                        }))
                      }}
                    >{range(1, 14).map(toDaysOption)}</select>
                    <FormText>At the start of a player's turn, additional time may be given.</FormText>
                  </FormGroup>
                  <FormGroup>
                    <FormLabel class="w-50">Max Time Per Turn</FormLabel>
                    <select class="form-control w-50 d-inline" name="maxTimePerTurn"
                      value={online.maxTimePerTurn}
                      onInput={(event: InputEvent) => {
                        modifyMutable(online, produce((online) => {
                          online.maxTimePerTurn = parseFloat((event.target as HTMLSelectElement)?.value)
                        }))
                      }}
                    >{range(1, 14).map(toDaysOption)}</select>
                    <FormText>After the previous player has completed their turn, the next player must complete their turn in this amount of time, otherwise their turn will be ended early.</FormText>
                  </FormGroup>
                </div>
              </>
            }}
            />
          </FormSection>
          <FormSection show={'rules' === searchParams.tab}>
            <h4>Rules</h4>
            <FormGroup>
              <FormLabel class="w-50">Fog of War</FormLabel>
              <Button name="fow" class="btn btn-secondary w-50 d-inline text-center"
                classList={{
                  active: engine.state.config.fow as unknown as boolean
                }}
                onClick={() => {
                  dispatchClient(engine, createPatchConfigAction({
                    fow: engine.state.config.fow ? 0 : 1
                  }))
                }}>{engine.state.config.fow ? 'On' : 'Off'}</Button>
              <FormText>Fog of War is a game mode where players can only see their units and nearby units.</FormText>
            </FormGroup>
            <FormGroup>
              <FormLabel class="w-50">Starting Income</FormLabel>
              <select class="form-control w-50 d-inline text-end" name="startIncome"
                value={engine.state.config.startIncome}
                onInput={(event: InputEvent) => {
                  dispatchClient(engine, createPatchConfigAction({
                    startIncome: parseInt((event.target as HTMLSelectElement)?.value, 10)
                  }))
                }}
              >
                <For each={[0, 10, 20, 40, 100, 200, 400]}>{amount => <option value={amount}>${amount}</option>}</For>
              </select>
              <FormText>Higher starting income allows players to making opening moves with higher tier units.</FormText>
            </FormGroup>
            <FormGroup>
              <FormLabel class="w-50">Income Multiplier</FormLabel>
              <select class="form-control w-50 d-inline text-end" name="incomeMult"
                value={engine.state.config.incomeMult}
                onInput={(event: InputEvent) => {
                  dispatchClient(engine, createPatchConfigAction({
                    incomeMult: parseFloat((event.target as HTMLSelectElement)?.value)
                  }))
                }}
              >
                <For each={[0, 0.5, 1, 2, 3, 4, 5]}>{amount => <option value={amount}>{amount}x</option>}</For>
              </select>
              <FormText>Increase/Decrease the rate players acquire income.</FormText>
            </FormGroup>
            <FormGroup>
              <FormLabel class="w-50">Max Total Units Per Player</FormLabel>
              <input class="form-control w-50 d-inline text-end" name="maxUnitsPerPlayer" type="number"
                value={engine.state.config.maxUnitsPerPlayer || ''}
                onInput={(event: InputEvent) => {
                  const newValue = parseInt((event.target as HTMLInputElement)?.value, 10) || null
                  if (newValue === null || (newValue >= MaxUnitsPerPlayerMin && MaxUnitsPerPlayerMax >= newValue)) {
                    dispatchClient(engine, createPatchConfigAction({
                      maxUnitsPerPlayer: newValue
                    }))
                  }
                }}
                placeholder="50" min={MaxUnitsPerPlayerMin} max={MaxUnitsPerPlayerMax}
              />
              <FormText>Add rules against spamming weak units</FormText>
            </FormGroup>
            <FormGroup>
              <RestrictedUnitRuleEditor state={engine.state} />
            </FormGroup>
            <h4>Victory Conditions</h4>
            <FormGroup>
              <FormLabel class="w-50">Map Control</FormLabel>
              <input class="form-control w-50 d-inline text-end" name="winMapControlPercent" type="number" step={5}
                value={engine.state.config.winMapControlPercent || ''}
                onInput={(event: InputEvent) => {
                  const newValue = parseInt((event.target as HTMLInputElement)?.value, 10) || null
                  if (newValue === null || (newValue >= WinMapControlPercentMin && WinMapControlPercentMax >= newValue)) {
                    dispatchClient(engine, createPatchConfigAction({
                      winMapControlPercent: newValue
                    }))
                  }
                }}
                placeholder="Off" min={WinMapControlPercentMin} max={WinMapControlPercentMax}
              />
              <FormText>The first player to acquire {engine.state.config.winMapControlPercent || 'X'}% of the map wins.</FormText>
            </FormGroup>
            <FormGroup>
              <FormLabel>Annex Any HQ</FormLabel>
              <label class="form-label d-block">
                <input type="radio" name="winAnnexAnyHq" value="0"
                  checked={engine.state.config.winAnnexAnyHq === 0}
                  onChange={() => dispatchClient(engine, createPatchConfigAction({
                    winAnnexAnyHq: 0
                  }))}
                /> Off - <div class="form-text d-inline">All of a player's HQs must be captured to eleminate them.</div>
              </label>
              <label class="form-label d-block">
                <input type="radio" name="winAnnexAnyHq" value="1"
                  checked={engine.state.config.winAnnexAnyHq === 1}
                  onChange={() => dispatchClient(engine, createPatchConfigAction({
                    winAnnexAnyHq: 1
                  }))}
                /> On - <div class="form-text d-inline">Any HQ can be captured to eliminate them.</div>
              </label>
            </FormGroup>
            <FormGroup>
              <FormLabel>Require Complete Kills</FormLabel>
              <label class="form-label d-block">
                <input type="radio" name="requireCompleteKills" value="0"
                  checked={engine.state.config.requireCompleteKills === 0}
                  onChange={() => dispatchClient(engine, createPatchConfigAction({
                    requireCompleteKills: 0
                  }))}
                /> Off - <div class="form-text d-inline">If a player loses their HQ, they are still in the game as long as any ally still has their HQ.</div>
              </label>
              <label class="form-label d-block">
                <input type="radio" name="requireCompleteKills" value="1"
                  checked={engine.state.config.requireCompleteKills === 1}
                  onChange={() => dispatchClient(engine, createPatchConfigAction({
                    requireCompleteKills: 1
                  }))}
                /> On - <div class="form-text d-inline">If a player loses their HQ, all of their units are removed from the game.</div>
              </label>
            </FormGroup>
            <FormGroup>
              <FormLabel class="w-50">Max Days</FormLabel>
              <input class="form-control w-50 d-inline text-end" name="winMaxDays" type="number"
                step={5}
                value={engine.state.config.winMaxDays || ''}
                onInput={(event: InputEvent) => {
                  const newValue = parseInt((event.target as HTMLInputElement)?.value, 10) || null
                  if (newValue === null || (newValue >= WinMaxDaysMin && WinMaxDaysMax >= newValue)) {
                    dispatchClient(engine, createPatchConfigAction({
                      winMaxDays: newValue
                    }))
                  }
                }}
                placeholder="Off" min={WinMaxDaysMin} max={WinMaxDaysMax}
              />
              <FormText>At the end of {engine.state.config.winMaxDays || 'X'} days, the player with the highest map control is declared the winner.</FormText>
            </FormGroup>
          </FormSection>
          <Show when={lobbyRow()?.data && !startNewGameValidation().success}>
            <AlertDanger>
              <strong>Unable to Start New Game:</strong>
              <ul>
                <For each={startNewGameValidation().issues}>
                  {(issue) => <li>{issue.message}</li>}
                </For>
              </ul>
            </AlertDanger>
          </Show>
        </div>
        <div class="container w-32-rem">
          <button class="btn btn-secondary btn-sm float-start" onClick={handleBackButtonClick}>Back</button>
          <Show when={isLobbyOwner() && urlGameId().value}>
            <button class="btn btn-danger btn-sm float-start mx-1" onClick={async function handleDeleteGameButtonClick() {
              // console.log('handleStartGameButtonClick')
              try {
                const disk = urlDisk().value
                if (hasLocalStorage && disk === StateMapDisk.LocalStorage) {
                  // game not actually saved yet.
                  // nothing to delete
                } else if (hasCloud && disk === StateMapDisk.Cloud) {
                  loading.start()
                  const gameId = urlGameId().value
                  if (!gameId) {
                    toast.error('No Game ID')
                    return
                  }
                  // await dispatchClient(engine, createDeleteNewGameAction())
                  await backendAxios.delete(`/st1/lobby/${gameId}`)
                  toast.success('Game Deleted')
                  navigate('/')
                } else {
                  throw new Unexpected('?disk')
                }
              } catch (error) {
                console.error(error)
                setAlertBagError(engine.ab, error as Error)
                toastError(error)
              } finally {
                loading.end()
              }
            }}>Delete Game</button>
          </Show>
          <Show when={urlDisk().value === StateMapDisk.LocalStorage || (isLobbyOwner() && urlGameId().value)}>
            <button class="btn btn-primary float-end" onClick={async function handleStartGameButtonClick() {
              // console.log('handleStartGameButtonClick')
              try {
                const disk = urlDisk().value
                if (hasLocalStorage && disk === StateMapDisk.LocalStorage) {
                  loading.start()
                  await dispatchClient(engine, createStartNewGameAction())
                  await saveGameToLocalStorage({
                    ...engine,
                    // we only want the start new game action log
                    actionLogs: engine.actionLogs.slice(-1),
                  })
                  navigate(`/game/play?disk=${StateMapDisk.LocalStorage}`)
                } else if (hasCloud && disk === StateMapDisk.Cloud) {
                  loading.start()
                  await dispatchClient(engine, createStartNewGameAction())
                } else {
                  throw new Unexpected('?disk')
                }
              } catch (error) {
                console.error(error)
                setAlertBagError(engine.ab, error as Error)
                toastError(error)
              } finally {
                loading.end()
              }
            }}>Start Game</button>
          </Show>
          <Show when={urlDisk().value !== StateMapDisk.LocalStorage && !isLobbyOwner()}>
            <button class="btn btn-primary float-end"
              onClick={function handlePlayerReadyButtonClick() {
                console.log('TODO toggle ready/not ready')
              }}>Ready</button>
          </Show>
        </div>
      </div>
    </EngineContext.Provider>
  )
}

export default PlayCreateGamePage
