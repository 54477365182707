import type { Nullable } from '../../../typescript'
import type { Engine } from '../engine/Engine.type'
import { byEstimateTargetEntityResaleValueDesc } from '../entity/getEntityResaleValue'
import type { Weapon } from '../entity/Weapon/Weapon.type'
import findAttackableEntity from '../findAttackableEntity'
import type { HasWeapons } from '../HasWeapons'
import type { HasPlayerId } from '../player/has_player_id'
import coord from '../tile_position_xy/coord'
import entityListToGridXY from '../tile_position_xy/entityListToGridXY'
import getEntityGridXYStack from '../tile_position_xy/getEntityGridXYStack'
import getManhattanDistance from '../tile_position_xy/getManhattanDistance'
import toCoord from '../tile_position_xy/toCoord'
import { max, min } from '../util/math'
import createDraftMoveAttackEstimate from './createDraftMoveAttackEstimate'
import type { DraftMove } from './DraftMove.type'
import type { DraftMove_RangedEstimate } from './DraftMove_RangedEstimate.type'

export default function calculateDraftMoveRangedAttackEstimates(
  engine: Engine,
  draftMove : Nullable<DraftMove>): void {
  // console.log('calculateDraftMoveRangedAttackEstimates')
  const { state } = engine
  if (draftMove) {
    const rangedEstimates: Array<DraftMove_RangedEstimate> = []
    const { unit } = draftMove
    const destPosition = draftMove.destPosition || toCoord(unit)
    const { player_id: unitPlayerId } = unit as HasPlayerId
    if (unitPlayerId) {
      const rangedWeapons : Nullable<Array<Weapon>> = (unit as HasWeapons)?.weapons?.filter((weapon : Weapon) => {
        return (weapon.maxRange as number) > 0
      })
      if (!rangedWeapons || !(rangedWeapons.length > 0)) {
        draftMove.rangedEstimates = []
        return
      }

      const { ents, width, height } = state

      const entityGridXY = entityListToGridXY(ents, width, height)

      const minRange: number = min(...rangedWeapons.map(weapon => weapon.minRange || 0))
      const maxRange: number = max(...rangedWeapons.map(weapon => weapon.maxRange as number))

      const minX = max(0, destPosition.x - maxRange)
      const minY = max(0, destPosition.y - maxRange)
      const maxX = min(destPosition.x + maxRange, height)
      const maxY = min(destPosition.y + maxRange, width)
      for (let y = minY; y <= maxY; y++) {
        for (let x = minX; x <= maxX; x++) {
          const targetPosition = coord(x, y)
          const distance = getManhattanDistance(targetPosition, unit)
          if (distance >= minRange && distance <= maxRange) {

            // at the minimum, we need this object so we can highlight the tile red
            const rangedEstimate : DraftMove_RangedEstimate = {
              x,
              y,
              distance,
              estimate: null,
            }

            rangedEstimates.push(rangedEstimate)
            const entsAtPosition = getEntityGridXYStack(entityGridXY, x, y)

            const target = findAttackableEntity(entsAtPosition, unitPlayerId)

            if (target) {
              // rangedWeapons.forEach((weapon : Weapon) => {
                const estimate = createDraftMoveAttackEstimate(engine, unit, destPosition, target)
                rangedEstimate.estimate = estimate
              // })
            }
          }
        }
      }
    }
    // have most valuable estimates first
    rangedEstimates.sort(byEstimateTargetEntityResaleValueDesc)
    // console.log('rangedEstimates', deepClone(rangedEstimates), deepClone(rangedEstimates.filter(re => {
    //   return re.estimate !== null
    // })))
    draftMove.rangedEstimates = rangedEstimates
  }
}
