import { modifyMutable, produce } from "solid-js/store"
import calculateDraftMoveRangedAttackEstimates from "../../draft_move/calculateDraftMoveRangedAttackEstimates"
import type { DraftMove } from "../../draft_move/DraftMove.type"
import type { Engine } from "../../engine/Engine.type"
import { SelectedToolId } from "../../map_editor/SelectedToolId.enum"

export default function handlePreviewRangedAttackOptions(engine: Engine) {
  // console.log('handlePreviewRangedAttackOptions')
  const { draftMove } = engine
  if (!draftMove) {
    return null
  }
  modifyMutable(engine, produce((engine : Engine) => {
    if (engine.selectedTool === SelectedToolId.RangedAttackRadius) {
      engine.selectedTool = SelectedToolId.MoveUnit
    } else {
      const newDraftMove : DraftMove = {
        ...draftMove,
        destPosition: null,
        attackPosition: null,
        target: null,
      }
      engine.selectedTool = SelectedToolId.RangedAttackRadius
      engine.draftMove = newDraftMove
      calculateDraftMoveRangedAttackEstimates(engine, engine.draftMove)
    }
  }))
}
