import { ReplayRowValue } from '@sg/backend/src/lib/db/getReplayOr404.ts'
import { FaSolidAlignCenter, FaSolidBackward, FaSolidBackwardStep, FaSolidForward, FaSolidForwardStep, FaSolidPause, FaSolidPlay } from 'solid-icons/fa'
import { Component, createEffect, createMemo, createSignal, on, onMount, Show } from 'solid-js'
import { createMutable, modifyMutable, produce } from 'solid-js/store'
import toast from 'solid-toast'
import bindViewCtxToWindow from '../lib/bindViewCtxToWindow'
import bindEngineToComponent from '../lib/canvas/bind_engine_to_component'
import addDrawMovementGridCanvas from '../lib/canvas/layer/addDrawMovementGridCanvas'
import addBgGradientCanvas from '../lib/canvas/layer/bg_gradient'
import addDrawEntsByKindCanvas from '../lib/canvas/layer/ents_by_type'
import addTerrainCursorCanvasOld from '../lib/canvas/layer/terrain_cursor/add_canvas'
import addCursorCanvas from '../lib/canvas/layer/terrain_cursor/addCursorCanvas'
import calculateMapWidthHeightPxTileSize from '../lib/core/calculateMapWidthHeightPx'
import calculateTileSize from '../lib/core/calculateTileSize'
import createCenterPanOnMount from '../lib/core/createCenterPanOnMount'
import createEngineForReplay from '../lib/core/engine/createEngineForReplay'
import { Engine } from '../lib/core/engine/Engine.type'
import { EntityLayerId } from '../lib/core/entity/entity_layer_id.enum'
import { SelectedToolId } from '../lib/core/map_editor/SelectedToolId.enum'
import { ActionType } from '../lib/core/state/flux/action/ActionType'
import dispatchReplay from '../lib/core/state/flux/dispatchReplay'
import type { State } from '../lib/core/state/State.type'
import { StateType } from '../lib/core/state/state_type.enum'
import { max } from '../lib/core/util/math'
import recenterPanZoomViewport from '../lib/core/view_ctx/recenterPanZoomViewport'
import { deepClone } from '../lib/deep_clone'
import registerContextMenuEvent from '../lib/dom/event/registerContextMenuEvent'
import registerGamePlayKeyboardEvents from '../lib/dom/event/registerGamePlayKeyboardEvents'
import registerKeyDownEvent from '../lib/dom/event/registerKeyDownEvent'
import registerKeyUpEvent from '../lib/dom/event/registerKeyUpEvent'
import registerMouseClickEvent from '../lib/dom/event/registerMouseClickEvent'
import registerMouseDownEvent from '../lib/dom/event/registerMouseDownEvent'
import registerMouseMoveEvent from '../lib/dom/event/registerMouseMoveEvent'
import registerMouseUpEvent from '../lib/dom/event/registerMouseUpEvent'
import registerTouchEventsV2 from '../lib/dom/event/registerTouchEventsV2'
import registerWheelEvent from '../lib/dom/event/wheel'
import Unexpected from '../lib/Exception/Unexpected.class'
import Button from '../lib/jsx/Button'
import createRedirectIfWrongStateTypeEffect from '../rx/effect/createRedirectIfWrongStateTypeEffect'
import useCornerWidgetEngine from '../rx/shared/CornerWidget/useCornerWidgetEngine'
import EngineContext from './EngineContext'
import GameEndedModal from './GameEndedModal'

interface Props {
  // session: BackendSessionPayload,
  row: ReplayRowValue,
}

const componentStateType = StateType.Replay

const ReplayPlayCanvas: Component<Props> = (props) => {
  const engine: Engine = createMutable<Engine>(createEngineForReplay())

  const [isPlaying, setIsPlaying] = createSignal(false)

  const [actionLogIndexCursor, setActionLogIndexCursor] = createSignal<number>(0)
  const [timeBetweenActionDispatches, setTimeBetweenActionDispatches] = createSignal<number>(500)

  setTimeBetweenActionDispatches(500)

  const getRawActionLogs = () => (props.row.action_logs as Engine['actionLogs'])

  const getStartNewGameLogIndex = createMemo(() => getRawActionLogs().findIndex((actionLog) => actionLog.action.type === ActionType.Lobby.StartNewGame))

  // game logs without lobby logs
  const actionLogs = createMemo(() => getRawActionLogs().slice(getStartNewGameLogIndex()))
  // createEffect(() => {
  //   console.log('actionLogs', actionLogs())
  // })
  const isAtBegining = createMemo(() => actionLogIndexCursor() === 0)
  const isAtEnd = createMemo(() => actionLogIndexCursor() > 0 && actionLogs().length === (actionLogIndexCursor() + 1))

  // createEffect(() => {
  //   console.table({
  //     isAtEnd: isAtEnd(),
  //     isAtBegining: isAtBegining(),
  //     getStartNewGameLogIndex: getStartNewGameLogIndex(),
  //     actionLogIndexCursor: actionLogIndexCursor(),
  //     'getAllActionLogs().length': actionLogs().length,
  //   })
  // })

  let lastAction: Promise<void> | null = null

  const stateCache = new Map<number, State>()

  createEffect(on(isPlaying, (isPlayingIsTrue: boolean) => {
    if (isPlayingIsTrue && !lastAction) {
      const logs = actionLogs()
      lastAction = (async () => {
        try {
          do {
            const index = actionLogIndexCursor() + 1
            const nextActionLog = logs[index]

            if (nextActionLog) {
              setActionLogIndexCursor(index)

              await dispatchReplay(engine, nextActionLog.action, nextActionLog.cref)

              stateCache.set(index, deepClone(engine.state))

              // console.log(`waiting ${timeBetweenActionDispatches()}ms`, { actionLogIndexCursor })
              const delay = timeBetweenActionDispatches()
              if (delay > 1) {
                await new Promise((resolve) => setTimeout(() => requestAnimationFrame(resolve), delay))
              } else {
                throw new Unexpected('!>1')
              }
            } else {
              // console.log('ran out of action logs, stop playing')
              setIsPlaying(false)
            }

          } while (isPlaying())
        } finally {
          lastAction = null
        }
      })()
    } else {
      // ran out of action logs, stop playing
      setIsPlaying(false)
    }
  }))

  async function rewindToPreviousActionLog() {
    setIsPlaying(false)

    const prevIndex = actionLogIndexCursor() - 1
    const logs = actionLogs()
    console.log('rewindToPreviousActionLog', prevIndex, 'log', logs[prevIndex])
    const prevActionLog = logs[prevIndex]
    if (prevActionLog) {
      const prevState = stateCache.get(prevIndex)
      if (prevState) {
        // eslint-disable-next-line solid/reactivity
        engine.state = prevState
        // eslint-disable-next-line solid/reactivity
        engine.prevState = prevState
        // eslint-disable-next-line solid/reactivity
        engine.actionLogs = engine.actionLogs.slice(0, prevIndex + 1)
        setActionLogIndexCursor(prevIndex)
      } else {
        console.log('stateCache', stateCache, 'prevIndex', prevIndex)
        // rewind back to the most recent state in cache
        let cursorIndex = prevIndex - 1
        let prevState = null
        do {
          prevState = stateCache.get(cursorIndex)
          console.log('cursorIndex', cursorIndex, 'prevState', prevState)
          cursorIndex -= 1
        } while (!prevState && cursorIndex >= 0)

        if (!prevState) {
          // attempt to start from the beginning
          prevState = logs[0].state0
        }
        if (!prevState) {
          throw new Unexpected('!prevState')
        }

        // eslint-disable-next-line solid/reactivity
        engine.prevState = engine.state = deepClone(prevState)
        // eslint-disable-next-line solid/reactivity
        engine.actionLogs = logs.slice(0, 1)

        do {
          cursorIndex++
          const nextActionLog = logs[cursorIndex]

          if (nextActionLog) {
            setActionLogIndexCursor(cursorIndex)

            await dispatchReplay(engine, nextActionLog.action, nextActionLog.cref)

            console.log('setting cache', cursorIndex)
            stateCache.set(cursorIndex, deepClone(engine.state))
          } else {
            console.log('ran out of action logs, stop playing', { cursorIndex, prevActionLog })
            setIsPlaying(false)
          }
        } while (prevIndex >= cursorIndex)
        console.log('done recreating state', { cursorIndex, prevActionLog }, logs)
      }
    } else {
      throw new Unexpected('!prevActionLog')
    }
  }

  async function fastForwardToNextActionLog() {
    setIsPlaying(false)

    const nextIndex = actionLogIndexCursor() + 1
    const nextActionLog = actionLogs()[nextIndex]
    if (nextActionLog) {
      await dispatchReplay(engine, nextActionLog.action, nextActionLog.cref)
      setActionLogIndexCursor(nextIndex)
    } else {
      throw new Unexpected('!nextActionLog')
    }
  }

  function rewindToTheBeginning() {
    setIsPlaying(false)

    const logs = actionLogs()

    const index = 0
    const startNewGameLog = logs[index]
    const initialState = startNewGameLog?.state0
    setActionLogIndexCursor(index)

    if (!initialState) {
      const error = new Error('No initial state found in action logs')
      console.error(error)
      // toast.error(error.message)
      return
    }

    //   syncEngineWithPropsRow(engine, props.row)
    modifyMutable(engine, produce((engine: Engine) => {
      engine.state = engine.prevState = deepClone(initialState)
      engine.actionLogs = [startNewGameLog]
    }))

    recenterPanZoomViewport(engine)
  }

  function fastfowardToTheEnd() {
    const logs = actionLogs()
    setActionLogIndexCursor(logs.length - 1)
    setIsPlaying(false)
    //   syncEngineWithPropsRow(engine, props.row)
    modifyMutable(engine, produce((engine: Engine) => {
      engine.state = engine.prevState = deepClone(props.row.data)
      engine.actionLogs = logs
    }))

    recenterPanZoomViewport(engine)
  }

  onMount(rewindToTheBeginning)

  // const [authProfile] = createAuthProfileSignal()
  // createSetEngineAuthPlayerIdFromAuthProfileSignalEffect(engine, authProfile)

  bindViewCtxToWindow(engine)
  createCenterPanOnMount(engine)

  addBgGradientCanvas(engine)
  // addTerrainGridCanvas(engine)
  addDrawEntsByKindCanvas(engine, [EntityLayerId.TerrainL1])
  addDrawEntsByKindCanvas(engine, [EntityLayerId.TerrainL2])
  addDrawMovementGridCanvas(engine)
  addDrawEntsByKindCanvas(engine, [EntityLayerId.Unit2])

  // addDrawOffMapCloudsCanvas(engine)
  addTerrainCursorCanvasOld(engine)
  addCursorCanvas(engine)

  registerMouseDownEvent(engine)
  registerMouseUpEvent(engine)
  registerMouseClickEvent(engine)
  registerContextMenuEvent(engine)
  registerWheelEvent(engine)
  registerKeyUpEvent(engine)
  registerKeyDownEvent(engine)
  registerGamePlayKeyboardEvents(engine, true)
  // registerTouchEventsV2(engine)

  // const isStateDirty = createIsStateDirtyMemo(engine)
  // useConfirmLeaveWithUnsavedChanges(isStateDirty)

  bindEngineToComponent(engine)

  useCornerWidgetEngine(engine)

  // registerServerSentEvents(engine)
  createRedirectIfWrongStateTypeEffect(componentStateType, () => engine.state.type, () => engine.state.online?.id)

  // setUp()
  onMount(() => {
    modifyMutable(engine, produce((engine) => {
      engine.toast = toast
      engine.selectedTool = SelectedToolId.Inspect
      engine.viewCtx.zoom = 200
      engine.viewCtx.tile_size = calculateTileSize(engine.viewCtx)
      calculateMapWidthHeightPxTileSize(engine)
    }))

    recenterPanZoomViewport(engine)

    // setTimeout(fastfowardToTheEnd, 1000)
  })

  return <EngineContext.Provider value={engine}>
    <div id="gpc" class="replay">
      <div id="gvp" ref={(ref: HTMLElement) => {
        // console.log('ref', ref)
        if (ref) {
          registerTouchEventsV2(engine, ref)
          registerMouseMoveEvent(engine, ref)
        }
      }}>
        {engine.canvasList}
      </div>
      <div id="cmdp">
        <div class="d-flex justify-content-between">
          <span class="btn btn-outline-secondary disabled btn-sm m-1">Round: {engine.state.round} / {props.row.data.round}</span>
          <span class="btn btn-outline-secondary disabled btn-sm m-1">
            Action: {max(0, actionLogIndexCursor() + 1)} / {actionLogs().length}
          </span>
          <Button class="btn btn-outline-secondary btn-sm m-1" onClick={() => recenterPanZoomViewport(engine)}>
            <FaSolidAlignCenter />{' Recenter'}
          </Button>
        </div>
        <div class="d-flex justify-content-between">
          <Button class="btn btn-outline-secondary btn-sm m-1" disabled={isAtBegining()} onClick={rewindToTheBeginning}>
            <FaSolidBackward />{' Begining'}
          </Button>
          <Button class="btn btn-outline-secondary btn-sm m-1" disabled={isAtBegining()} onClick={rewindToPreviousActionLog}>
            <FaSolidBackwardStep />{' Prev'}
          </Button>
          <Show when={isPlaying()}
            children={
              <Button class="btn btn-outline-secondary btn-sm m-1" onClick={() => setIsPlaying(false)}>
                <FaSolidPause />{' Pause'}
              </Button>
            }
            fallback={
              <Button class="btn btn-outline-secondary btn-sm m-1" onClick={() => setIsPlaying(true)}>
                <FaSolidPlay />{' Play'}
              </Button>
            }
          />
          <Button class="btn btn-outline-secondary btn-sm m-1" disabled={isAtEnd()} onClick={fastForwardToNextActionLog}>
            {'Next '}
            <FaSolidForward />
          </Button>
          <Button class="btn btn-outline-secondary btn-sm m-1" disabled={isAtEnd()} onClick={fastfowardToTheEnd}>
            {'End '}<FaSolidForwardStep />
          </Button>
        </div>
      </div>
    </div>
    <GameEndedModal />
  </EngineContext.Provider>
}

export default ReplayPlayCanvas
