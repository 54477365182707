import { InternalAxiosRequestConfig } from "axios"
import getAccessToken from "./getAccessToken"

export default async function addAuthorizationHeader<D>(config: InternalAxiosRequestConfig<D>) {
  // console.log('addAuthorizationHeader')
  if (!config.headers?.Authorization) {
    // console.log('<const accessToken = await getAccessToken()>')
    const accessToken = await getAccessToken()
    // console.log('</const accessToken = await getAccessToken()>')
    if (accessToken) {
      // console.log('addAuthorizationHeader.accessToken')
      // config.headers AxiosHeaders is apparently nullable
      // config.headers = config.headers || {}
      config.headers.Authorization = `Bearer ${accessToken}`
    } else {
      // console.log('addAuthorizationHeader.!accessToken')
      // get guest access token?
      // throw new Error('NoAccessToken')
    }
  }

  return config
}