import { modifyMutable, produce } from 'solid-js/store'
import useIsActionLogListModalOpenSignal from '../../../rx/shared/ModalMenu/useIsActionLogListModalOpenSignal'
import useIsPlayersListModalOpenSignal from '../../../rx/shared/ModalMenu/useIsPlayersListModalOpenSignal'
import { Engine } from '../../core/engine/Engine.type'
import resetEngineToDefaultSelectedTool from '../../core/engine/resetEngineToDefaultSelectedTool'
import calculateSelectedPositionEnts from '../../core/entity/calculateSelectedPositionEnts'
import canUnitAnnexEntity from '../../core/entity/canUnitAnnexEntity'
import { isEntityInfantry } from '../../core/entity/entityTypeMetaList.generated'
import getEntitiesAtPosition from '../../core/entity/getEntitiesAtPosition'
import { SelectedToolId } from '../../core/map_editor/SelectedToolId.enum'
import { createMoveUnitAction } from '../../core/state/flux/action/Game/MoveUnitAction'
import dispatchClient from '../../core/state/flux/dispatchClient'
import handlePreviewRangedAttackOptions from '../../core/ui/action/PreviewRangedAttackOptions'
import handlePreviewUnloadPositionOptions from '../../core/ui/action/PreviewUnloadPositionOptions'
import handleRotateNextUnit from '../../core/ui/action/RotateNextUnit'
import handleUnitLoadIntoEntityAction from '../../core/ui/action/UnitLoadIntoEntityAction'
import handleUnitPickupEntityAction from '../../core/ui/action/UnitPickupEntityAction'
import { centerPanOnPosition } from '../../core/view_ctx/centerPanOnPosition'
import { deepClone } from '../../deep_clone'
import firstIfOnlyOne from '../../ldsh/firstIfOnlyOne'
import onWindowEvent from '../../onWindowEvent'
import preventDefault from './preventDefault'

// TODO optional "grid" hotkeys preset.
// grid hotkeys put the hotkeys closer together?

export default function registerGamePlayKeyboardEvents(engine: Engine, isGamePlay: boolean) {

  const [isPlayersListModalOpen, setIsPlayersListModalOpen] = useIsPlayersListModalOpenSignal()
  const [isActionLogListModalOpen, setIsActionLogListModalOpen] = useIsActionLogListModalOpenSignal()
  // const dirty = engine.cache[1]
  // const ab = createAlertBagStore()
  function onGamePlayKeyDown(event: KeyboardEvent) {
    // console.log('onGamePlayKeyDown', event)
    if (shouldIgnoreHotKeys(event)) {
      return
    }
    // if (key === 's') {
    //   if (event.metaKey || event.ctrlKey) {
    //     // console.log('Save Hotkey!')
    //     preventDefault(event)
    //     saveStateChanges(engine)
    //     dispatchClient(engine, createSetStateAction(engine.state))
    //     return
    //   }
    // }
    if (isGamePlay) {
      const { key } = event
      const { draftMove } = engine
      // if (key === 'z') {
      //   if (event.metaKey || event.ctrlKey) {
      //     preventDefault(event)
      //     if (event.shiftKey) {
      //       // console.log('Redo Event!')
      //     } else {
      //       // console.log('Undo Event!')
      //     }
      //     return
      //   }
      // }
      // if (key === 'p') {
      //   preventDefault(event)
      //   engine.selectedTool = SelectedToolId.Paint
      //   return
      // }
      // if (key === 'e') {
      //   preventDefault(event)
      //   engine.selectedTool = SelectedToolId.Erase
      //   return
      // }
      // if (key === 'f') {
      //   preventDefault(event)
      //   engine.selectedTool = SelectedToolId.File
      //   return
      // }
      // DoW:
      // j: jump or teleport
      // n: unload
      // y: set rally point
      // b: build menu (if builder selected)
      // shift: queue orders
      // .>: next builder
      // ctrl + R: next unit
      // u: upgrade
      // backspace: default camera angle
      // ': snap to unit (I'll say centerpan?)
      // CivV:
      // F1: civilopedia
      // F2: Economic
      // F3: Military
      // F4: Diplomacy
      // F5: Social Policies
      // F6: Research
      // F7: Notifications
      // F8: Victory Progress
      // F9: Demographics
      // F10: Strategic View (this could mean zoom out or see overworld map)
      // F11: Quick Save
      // F12: Quick Load
      // Shift+Enter: Next Turn
      // g : turn on/off grid
      // ctrl+R resource icons
      // ctrl+Y yield icons
      // +-: Zoom
      // PageUp/PageDown: Zoom
      // Insert/Home/End: Capital City View
      // Esc: Menu
      // Ctrl+O: Game Options
      // Space: skip unit
      // >: next unit
      // <: prev unit
      // m: move mode
      // e: explore
      // a: alert
      // f: sleep/fortify
      // Delete: disband
      // ctrl+a: attack
      // b: ranged attack
      // s: set up artillary
      // h: heal+fortify
      // BackSpace: cancel last mission
      // I: intercept air units
      // 

      if (key === 'i') {
        preventDefault(event)
        engine.draftMove = null
        engine.selectedTool = SelectedToolId.Inspect
        calculateSelectedPositionEnts(engine)
        return
      }
      if (key === 'Escape') {
        console.log('[esc] pressed')
        preventDefault(event)
        modifyMutable(
          engine,
          produce((engine) => {
            resetEngineToDefaultSelectedTool(engine)
          })
        )
        return
      }
      if (key === 'g' && event.ctrlKey) {
        console.log('[ctrl+g] pressed')
        preventDefault(event)
        // TODO recenter camera with respect to lost/gained pixels
        engine.viewCtx.tile_gap_px = engine.viewCtx.tile_gap_px === 0 ? 1 : 0
        return
      }
      // center camera on selected unit
      if (key === "'") {
        console.log('[SingleQuote] pressed')
        preventDefault(event)
        const centerToPosition = draftMove?.unit || engine.selectedPosition
        if (centerToPosition) {
          centerPanOnPosition(engine, centerToPosition)
        }
        return
      }
      // <> for prev, next unit
      if (key === '.' || key === ',') {
        console.log(`[${key}] pressed`)
        preventDefault(event)
        handleRotateNextUnit(engine, key === '.')
        return
      }
      if (key === 'w') {
        console.log('[w] pressed')
        preventDefault(event)
        if (draftMove) {
          dispatchClient(engine, createMoveUnitAction({...draftMove, wait: true }))
        }
        return
      }
      if (key === 'c') {
        console.log('[c] pressed')
        preventDefault(event)
        if (draftMove && !draftMove.destPositionPaths?.lastStep?.occupant) {
          dispatchClient(engine, createMoveUnitAction({...draftMove }))
        }
        return
      }
      if (key === 'p') {
        console.log('[p] pressed')
        preventDefault(event)
        handleUnitPickupEntityAction(engine)
        return
      }
      if (key === 'N') {
        console.log('[shift+n] pressed')
        preventDefault(event)
        setIsActionLogListModalOpen(!isActionLogListModalOpen())
        // engine.selectedTool = SelectedToolId.Players
        return
      }
      if (key === 'L') {
        console.log('[shift+L] pressed')
        preventDefault(event)
        setIsPlayersListModalOpen(!isPlayersListModalOpen())
        // engine.selectedTool = SelectedToolId.Players
        return
      }
      if (key === 'l') {
        console.log('[l] pressed')
        preventDefault(event)
        handleUnitLoadIntoEntityAction(engine)
        return
      }
      // u for unload
      if (key === 'u') {
        console.log('[u] pressed')
        preventDefault(event)
        handlePreviewUnloadPositionOptions(engine)
        return
      }
      // a for annex
      if (key === 'a') {
        console.log('[a] pressed')
        preventDefault(event)
        if (draftMove) {
          const { unit } = draftMove
          if (draftMove.destPosition) {
            if (firstIfOnlyOne(draftMove.destPositionPaths?.lastStep?.annex)) {
              console.log('annex.draftMove', deepClone(draftMove))
              dispatchClient(engine, createMoveUnitAction({ ...draftMove!, annex: true }))
            }
          } else if (isEntityInfantry(unit)) {
            const annexableEntityList = getEntitiesAtPosition(engine.state.ents, unit).filter((ent) => canUnitAnnexEntity(unit, ent))
            if (annexableEntityList.length === 1) {
              dispatchClient(engine, createMoveUnitAction({ ...draftMove, annex: true }))
            }
          }
        }
        return
      }
      // b for bombard
      if (key === 'b') {
        console.log('[b] pressed')
        preventDefault(event)
        handlePreviewRangedAttackOptions(engine)
        return
      }
      if (key === 'm') {
        console.log('[m] pressed')
        preventDefault(event)
        if (draftMove) {
          const merge = draftMove.destPositionPaths?.lastStep?.merge
          if (merge) {
            dispatchClient(engine, createMoveUnitAction({ ...draftMove, merge }))
          }
        }
        return
      }

      console.log(`[${key}] pressed`, 'No key handler returned early', event)

      // if (key === 'Enter') {
      //   console.log('[enter] pressed')
      //   preventDefault(event)
      //   if (engine.draftMove) {
      //     dispatchClient(engine, createMoveUnitAction(engine.draftMove))
      //   }
      //   return
      // }
    }

    // when it's possible for Ctrl - Z to undo this
    // if (key === 'r') {
    //   if (event.shiftKey && (event.metaKey || event.ctrlKey)) {
    //     console.log('Revert to PrevState!')
    //     preventDefault(event)
    //     engine.state = structuredClone(unwrap(engine.prevState))
    //     return
    //   }
    // }
  }

  // function onGamePlayKeyUp() {
  //   // console.log('onGamePlayKeyUp', event)
  //   // if (key === 's') {
  //   //   if (event.metaKey || event.ctrlKey) {
  //   //     console.log('Save Hotkey!')
  //   //     preventDefault(event)
  //   //     saveStateChanges(engine)
  //   //     return
  //   //   }
  //   // }
  // }

  // onWindowEvent('keyup', onGamePlayKeyUp)
  onWindowEvent('keydown', onGamePlayKeyDown)
}

function shouldIgnoreHotKeys(event: KeyboardEvent): boolean {
  if (event.metaKey || event.ctrlKey) {
    return false
  }

  const target = event.target as HTMLElement | null
  const targetNodeName = target?.nodeName
  // console.log('targetNodeName', targetNodeName)
  return (targetNodeName === 'INPUT' ||
    targetNodeName === 'TEXTAREA' ||
    target?.isContentEditable) as boolean
}
