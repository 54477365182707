import type { HasHP } from '../has_hp'
import type { HasPlayerId } from '../player/has_player_id'
import getManhattanDistance from '../tile_position_xy/getManhattanDistance'
import type { Entity } from './index'
import isTargetEntityAirUnit from './isTargetEntityAirUnit'
import isWeaponUsable from './isWeaponUsable'

export default function canUnitRangedAttackEntity(
  unit: Entity,
  target: Entity
): target is Entity & HasHP & HasPlayerId {
  const { player_id: unitPlayerId, weapons } = unit
  // units inside of a taxi cannot be targeted or attacked
  if (target.taxi_id || unit.taxi_id) {
    return false
  }
  if (unitPlayerId && unitPlayerId !== target.player_id) {
    if (target.hp) {
      if (weapons) {
        for (let index = 0; index < weapons.length; index++) {
          const weapon = weapons[index]
          if (isWeaponUsable(weapon)) {
            const maxRange = weapon.maxRange
            if (!maxRange) {
              continue
            }
            const minRange: number = weapon.minRange || 0
            const distance = getManhattanDistance(target, unit)
            const exceedsMaxRange = distance >= maxRange
            if (exceedsMaxRange) {
              continue
            }
            const lessThanMinRange = minRange - 1  > distance
            if (lessThanMinRange) {
              continue
            }
            if (weapon.airOnly && !isTargetEntityAirUnit(target)) {
              continue
            }

            return true
          }
        }
      }
    }
  }
  return false
}
