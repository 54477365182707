import { MapRowValue } from '@sg/backend/src/lib/db/getMapOr404'
import { Component, onMount } from 'solid-js'
import { createMutable, modifyMutable, produce } from 'solid-js/store'
import toast from 'solid-toast'
import bindViewCtxToWindow from '../lib/bindViewCtxToWindow'
import bindEngineToComponent from '../lib/canvas/bind_engine_to_component'
import addBgGradientCanvas from '../lib/canvas/layer/bg_gradient'
import addDrawEntsByKindCanvas from '../lib/canvas/layer/ents_by_type'
import addTerrainCursorCanvasOld from '../lib/canvas/layer/terrain_cursor/add_canvas'
import addCursorCanvas from '../lib/canvas/layer/terrain_cursor/addCursorCanvas'
import calculateMapWidthHeightPxTileSize from '../lib/core/calculateMapWidthHeightPx'
import calculateTileSize from '../lib/core/calculateTileSize'
import createCenterPanOnMount from '../lib/core/createCenterPanOnMount'
import createEngineForMapEditor from '../lib/core/engine/createEngineForMapEditor'
import { Engine } from '../lib/core/engine/Engine.type'
import syncEngineWithPropsRow from '../lib/core/engine/syncEngineWithPropsRow'
import { EntityLayerId } from '../lib/core/entity/entity_layer_id.enum'
import { SelectedToolId } from '../lib/core/map_editor/SelectedToolId.enum'
import { createIsStateDirtyMemo } from '../lib/core/state/create_is_state_dirty_memo'
import { centerPan } from '../lib/core/view_ctx/centerPan'
import registerContextMenuEvent from '../lib/dom/event/registerContextMenuEvent'
import registerKeyDownEvent from '../lib/dom/event/registerKeyDownEvent'
import registerKeyUpEvent from '../lib/dom/event/registerKeyUpEvent'
import registerMapEditKeyboardEvents from '../lib/dom/event/registerMapEditKeyboardEvents'
import registerMouseClickEvent from '../lib/dom/event/registerMouseClickEvent'
import registerMouseDownEvent from '../lib/dom/event/registerMouseDownEvent'
import registerMouseMoveEvent from '../lib/dom/event/registerMouseMoveEvent'
import registerMouseUpEvent from '../lib/dom/event/registerMouseUpEvent'
import registerTouchEventsV2 from '../lib/dom/event/registerTouchEventsV2'
import registerWheelEvent from '../lib/dom/event/wheel'
import createSetEngineAuthPlayerIdFromAuthProfileSignalEffect from '../rx/effect/createSetEngineAuthPlayerIdFromAuthProfileSignalEffect'
import createAuthProfileSignal from '../rx/shared/profile/createAuthProfileSignal'
import registerServerSentEvents from '../rx/sse/registerServerSentEvents'
import useConfirmLeaveWithUnsavedChanges from '../rx/use/useConfirmLeaveWithUnsavedChanges'
import EngineContext from './EngineContext'
import MapEditorToolbar from './Hud/MapEditorToolbar'

interface Props {
  // session: BackendSessionPayload,
  row: MapRowValue,
}

// const componentStateType = StateType.Map

const MapEditCanvas: Component<Props> = (props) => {
  const engine: Engine = createMutable<Engine>(createEngineForMapEditor())

  onMount(() => {
    syncEngineWithPropsRow(engine, props.row)
  })

  const [authProfile] = createAuthProfileSignal()
  createSetEngineAuthPlayerIdFromAuthProfileSignalEffect(engine, authProfile)

  bindViewCtxToWindow(engine)
  createCenterPanOnMount(engine)

  addBgGradientCanvas(engine)
  // addTerrainGridCanvas(engine)
  addDrawEntsByKindCanvas(engine, [EntityLayerId.TerrainL1])
  addDrawEntsByKindCanvas(engine, [EntityLayerId.TerrainL2, EntityLayerId.Unit2])

  // addDrawOffMapCloudsCanvas(engine)
  addTerrainCursorCanvasOld(engine)
  addCursorCanvas(engine)

  registerMouseDownEvent(engine)
  registerMouseUpEvent(engine)
  registerMouseClickEvent(engine)
  registerContextMenuEvent(engine)
  registerWheelEvent(engine)
  registerKeyUpEvent(engine)
  registerKeyDownEvent(engine)
  registerMapEditKeyboardEvents(engine, true)
  // registerTouchEventsV2(engine)

  // modifyMutable(engine, produce((engine) => {
  //   engine.canvasList.forEach(makeCanvasFullViewport)
  // }))
  // modifyMutable(engine, produce((engine) => {
  //   engine.canvasList.forEach(function (elem: HTMLCanvasElement) {
  //     // elem.style.position = 'relative'
  //     // elem.style.top = '0px'
  //     // elem.style.left = '0px'
  //     // elem.width = '100%'
  //     // elem.height = '100%'
  //   })
  // }))

  const isStateDirty = createIsStateDirtyMemo(engine)
  useConfirmLeaveWithUnsavedChanges(isStateDirty)

  bindEngineToComponent(engine)
  registerServerSentEvents(engine)

  onMount(() => {
    modifyMutable(engine, produce((engine) => {
      engine.toast = toast
      // engine.selectedPosition = { x: 13, y: 9 }
      // calculateSelectedPositionEnts(engine)
      // engine.selectedTool = SelectedToolId.File
      engine.selectedTool = SelectedToolId.Inspect
      // engine.selectedTool = SelectedToolId.Paint
      // engine.selectedTool = SelectedToolId.Erase
      engine.viewCtx.zoom = 200
      engine.viewCtx.tile_size = calculateTileSize(engine.viewCtx)
      calculateMapWidthHeightPxTileSize(engine)
      centerPan(engine)
    }))
  })

  return <EngineContext.Provider value={engine}>
    <div id="gpc">
      <div id="gvp" ref={(ref: HTMLElement) => {
        // console.log('ref', ref)
        if (ref) {
          registerTouchEventsV2(engine, ref)
          registerMouseMoveEvent(engine, ref)
        }
      }}>
        {engine.canvasList}
      </div>
    </div>
    <MapEditorToolbar />
  </EngineContext.Provider>
}

export default MapEditCanvas
