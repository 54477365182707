import { array, boolean, object, type InferOutput } from 'valibot'
import canUnitDirectAttackEntity from '../entity/canUnitDirectAttackEntity'
import canUnitRangedAttackEntity from '../entity/canUnitRangedAttackEntity'
import { EntitySchema, type Entity } from '../entity/index'
import isEntityUnit from '../entity/isEntityUnit'
import type { HasHP } from '../has_hp'
import type { HasPlayerId } from '../player/has_player_id'
import type { EntityGridXY } from './EntityGridXY.type'
import createLazyReadOnlyGridXY from './createLazyReadOnlyGridXY'
import getEntityGridXYStack from './getEntityGridXYStack'

type EnemyEntity = Entity & HasPlayerId & HasHP

export const EnemyListRowSchema = object({
  canDirectAttack: boolean(),
  canRangedAttack: boolean(),
  ent: EntitySchema, // Entity & HasPlayerId & HasHP
})

export type EnemyListRow = InferOutput<typeof EnemyListRowSchema>

export const EnemyListSchema = array(EnemyListRowSchema)

export type EnemyList = InferOutput<typeof EnemyListSchema>

export default function createLazyEnemyListGrid(
  // full state width
  gridWidth: number,
  // full state height
  gridHeight: number,
  // full state ents as GridXY
  grid: EntityGridXY,
  // unit attempting to approach enemies
  unit: Entity
) {
  const lazyGrid = createLazyReadOnlyGridXY<EnemyList>(
    gridWidth,
    gridHeight,
    function enemyListGridGetter(x: number, y: number): EnemyList {
      const positionStack = getEntityGridXYStack(grid, x, y)
      const list : EnemyList = []
      const unitPlayerId = unit.player_id
      for (let index = 0; index < positionStack.length; index++) {
        const enemyEnt = positionStack[index];
        if (enemyEnt.player_id !== unitPlayerId) {
          if (isEntityUnit(enemyEnt)) {
            if (enemyEnt.taxi_id) {
              // ignore cargo, taxi will be used
              continue
            }
            list.push({
              canDirectAttack: canUnitDirectAttackEntity(unit, enemyEnt),
              canRangedAttack: canUnitRangedAttackEntity(unit, enemyEnt),
              ent: enemyEnt as EnemyEntity,
            })
          }
        }
      }
      return list
    }
  )
  return lazyGrid
}
