import { MapRowValue } from '@sg/backend/src/lib/db/getMapOr404'
import { Component, onCleanup, onMount } from 'solid-js'
import { createMutable } from 'solid-js/store'
import * as THREE from "three"
import createEngineForMapEditor from '../lib/core/engine/createEngineForMapEditor'
import { Engine } from '../lib/core/engine/Engine.type'
import syncEngineWithPropsRow from '../lib/core/engine/syncEngineWithPropsRow'
import clampCameraToState from '../lib/core/view_ctx/clampCameraToState'
import registerPointerMoveEvent from '../lib/dom/event/registerPointerMoveEvent'
import firstIfOnlyOne from '../lib/ldsh/firstIfOnlyOne'
import createGrassPlaneMesh from '../lib/three/fn/createGrassPlaneMesh'
import createHoverTileMesh from '../lib/three/fn/createHoverTileMesh'
import createMapControlEntities from '../lib/three/fn/createMapControlEntities'
import createMapTrees from '../lib/three/fn/createMapTrees'
import createSkyPlaneMesh from '../lib/three/fn/createSkyPlaneMesh'
import createUnitEntities from '../lib/three/fn/createUnitEntities'
import createWaterPlaneMesh from '../lib/three/fn/createWaterPlaneMesh'
import createSetEngineAuthPlayerIdFromAuthProfileSignalEffect from '../rx/effect/createSetEngineAuthPlayerIdFromAuthProfileSignalEffect'
import createAuthProfileSignal from '../rx/shared/profile/createAuthProfileSignal'
import EngineContext from './EngineContext'


interface Props {
  // session: BackendSessionPayload,
  row: MapRowValue,
}

// const componentStateType = StateType.Map

const MapEditThreeJsCanvas: Component<Props> = (props) => {
  const engine: Engine = createMutable<Engine>(createEngineForMapEditor())

  onMount(() => {
    syncEngineWithPropsRow(engine, props.row)
  })

  const [authProfile] = createAuthProfileSignal()
  createSetEngineAuthPlayerIdFromAuthProfileSignalEffect(engine, authProfile)

  // bindViewCtxToWindow(engine)
  // createCenterPanOnMount(engine)

  // addBgGradientCanvas(engine)
  // // addTerrainGridCanvas(engine)
  // addDrawEntsByKindCanvas(engine, [EntityLayerId.TerrainL1])
  // addDrawEntsByKindCanvas(engine, [EntityLayerId.TerrainL2, EntityLayerId.Unit2])

  // // addDrawOffMapCloudsCanvas(engine)
  // addTerrainCursorCanvasOld(engine)
  // addCursorCanvas(engine)

  // registerMouseDownEvent(engine)
  // registerMouseUpEvent(engine)
  // registerMouseClickEvent(engine)
  // registerContextMenuEvent(engine)
  // registerWheelEvent(engine)
  // registerKeyUpEvent(engine)
  // registerKeyDownEvent(engine)
  // registerMapEditKeyboardEvents(engine, true)
  // registerTouchEventsV2(engine)

  // modifyMutable(engine, produce((engine) => {
  //   engine.canvasList.forEach(makeCanvasFullViewport)
  // }))
  // modifyMutable(engine, produce((engine) => {
  //   engine.canvasList.forEach(function (elem: HTMLCanvasElement) {
  //     // elem.style.position = 'relative'
  //     // elem.style.top = '0px'
  //     // elem.style.left = '0px'
  //     // elem.width = '100%'
  //     // elem.height = '100%'
  //   })
  // }))

  // const isStateDirty = createIsStateDirtyMemo(engine)
  // useConfirmLeaveWithUnsavedChanges(isStateDirty)

  // bindEngineToComponent(engine)
  // registerServerSentEvents(engine)

  // onMount(() => {
  //   modifyMutable(engine, produce((engine) => {
  //     engine.toast = toast
  //     // engine.selectedPosition = { x: 13, y: 9 }
  //     // calculateSelectedPositionEnts(engine)
  //     // engine.selectedTool = SelectedToolId.File
  //     engine.selectedTool = SelectedToolId.Inspect
  //     // engine.selectedTool = SelectedToolId.Paint
  //     // engine.selectedTool = SelectedToolId.Erase
  //     engine.viewCtx.zoom = 200
  //     engine.viewCtx.tile_size = calculateTileSize(engine.viewCtx)
  //     calculateMapWidthHeightPxTileSize(engine)
  //     centerPan(engine)
  //   }))
  // })

  let canvasRef: HTMLCanvasElement | undefined

  onMount(() => {
    if (!canvasRef) {
      console.error('!canvasRef')
      return
    }
    // Set up the scene, camera, and renderer
    const scene = new THREE.Scene()
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000)
    // camera.position.set(5, 0, 0) // Start the camera at an offset
    camera.position.set(30, 30, 100) // Start the camera at an offset
    const renderer = new THREE.WebGLRenderer({ canvas: canvasRef })
    renderer.setSize(window.innerWidth, window.innerHeight)
    renderer.toneMapping = THREE.ACESFilmicToneMapping
    renderer.toneMappingExposure = 0.5

    const updateHoverTile = createHoverTileMesh(engine, scene)

    // Water
    const waterMesh = createWaterPlaneMesh(engine, scene)

    // Skybox
    createSkyPlaneMesh(engine, scene, waterMesh, renderer)

    const grassPlaneMesh = createGrassPlaneMesh(engine, scene)

    // Animation loop variables
    // let angle = 0 // Angle in radians
    // const radius = 50 // Radius of the orbit

    // camera.translateY(1)

    createMapTrees(engine, scene)
    createMapControlEntities(engine, scene)
    createUnitEntities(engine, scene)

    // mouse events

    const raycaster = new THREE.Raycaster()

    const pointerRef = new THREE.Vector2()
    registerPointerMoveEvent(engine, canvasRef, pointerRef, camera)

    // camera.lookAt(0, 0, 0) // Ensure the camera looks at the center
    camera.rotation.x = -Math.atan(1 / Math.sqrt(2))
    clampCameraToState(camera, engine.state)

    // Animation loop
    const render = () => {
      const time = performance.now() * 0.001
      // angle += 0.0025 // Increment the angle
      // camera.position.x = radius * Math.cos(angle) // Update camera position (x)
      // camera.position.z = radius * Math.sin(angle) // Update camera position (z)

      // camera.position.y = (Math.sin(time / 1000) * 5) + 1 // Bounce camera up and down

      // camera.lookAt(0, 0, 0) // Ensure the camera looks at the center

      waterMesh.material.uniforms['time'].value += 0.25 / 60.0
      const maxWaterDip = 0.05
      waterMesh.position.y = ((Math.sin(time / 100) * maxWaterDip)) - maxWaterDip // Bounce water up and down

      // update the picking ray with the camera and pointer position
      raycaster.setFromCamera(pointerRef, camera)
      const intersects = raycaster.intersectObject(grassPlaneMesh)

      updateHoverTile(firstIfOnlyOne(intersects))
      // applyMousePanVelocityToCamera()

      renderer.render(scene, camera)
      animationId = requestAnimationFrame(render)
    }

    let animationId = requestAnimationFrame(render)

    // Cleanup on component unmount
    onCleanup(() => {
      cancelAnimationFrame(animationId)
      renderer.dispose()
      // geometry2.dispose()
    })
  })

  return <EngineContext.Provider value={engine}>
    <div id="gpc">
      <div id="gvp" ref={(ref: HTMLElement) => {
        // console.log('ref', ref)
        if (ref) {
          // registerTouchEventsV2(engine, ref)
          // registerMouseMoveEvent(engine, ref)
        }
      }}>
        <canvas ref={canvasRef} />
      </div>
    </div>
    {/* <MapEditorToolbar /> */}
  </EngineContext.Provider>
}

export default MapEditThreeJsCanvas
