import { deepClone } from "../../../deep_clone"
import type { Engine } from "../../engine/Engine.type"
import getPlayerEntities from "../../entity/getPlayerEntities"
import isEntityAtFullMobility from "../../entity/isEntityAtFullMobility"
import isEntityUnit from "../../entity/isEntityUnit"
import notInTaxi from "../../entity/notInTaxi"
import type { Bot } from "../Bot.type"

/**
 * all units will seek out annexables,
 * infantry for the purpose of annexing,
 * other units for the purpose of making sure
 * the infantry successfully annexes
 * 
 * @param engine 
 * @param bot 
 */
export default async function botReportUnmovedUnits(
  engine: Engine,
  bot: Bot
) {
  console.log('%c[botReportUnmovedUnits]', 'color: grey; font-weight: lighter; font-size: 10px;')
  const { player } = bot
  const playerId = player.id
  const allPlayerEnts = getPlayerEntities(engine.state.ents, playerId)
  const playerUnitsAtFullMobility = allPlayerEnts.filter((ent) => {
    return (
      isEntityUnit(ent) &&
      notInTaxi(ent) &&
      isEntityAtFullMobility(ent)
    )
  })
  if (playerUnitsAtFullMobility.length === 0) {
    return
  }
  console.warn('playerUnitsAtFullMobility', deepClone(playerUnitsAtFullMobility))
}