import { MathUtils, PMREMGenerator, Scene, Vector3, type ShaderMaterial, type WebGLRenderer, type WebGLRenderTarget } from "three"
import type { Engine } from "../../core/engine/Engine.type"
import { Sky } from "../objects/mesh/Sky"
import type { Water } from "../objects/mesh/Water"
import setFromSphericalCoords from "./setFromSphericalCoords"

// this did not work lol
export default function createSkyPlaneMesh(
  engine: Engine,
  scene: Scene,
  waterMesh: Water,
  renderer: WebGLRenderer
) {
  const skyMesh = new Sky()
  skyMesh.scale.setScalar(10000)
  scene.add(skyMesh)

  const skyUniforms = (skyMesh.material as ShaderMaterial).uniforms

  skyUniforms['turbidity'].value = 10
  skyUniforms['rayleigh'].value = 2
  skyUniforms['mieCoefficient'].value = 0.005
  skyUniforms['mieDirectionalG'].value = 0.8

  const parameters = {
    elevation: 2,
    azimuth: 180
  }

  const pmremGenerator = new PMREMGenerator(renderer)
  const sceneEnv = new Scene()

  let renderTarget: WebGLRenderTarget

  const sun = new Vector3()

  function updateSun() {

    const phi = MathUtils.degToRad(90 - parameters.elevation)
    const theta = MathUtils.degToRad(parameters.azimuth)

    // sun.setFromSphericalCoords(1, phi, theta)
    setFromSphericalCoords(sun, 1, phi, theta)

    ; (skyMesh.material as ShaderMaterial).uniforms['sunPosition'].value.copy(sun)
    waterMesh.material.uniforms['sunDirection'].value.copy(sun).normalize()

    if (renderTarget !== undefined) renderTarget.dispose()

    sceneEnv.add(skyMesh)
    renderTarget = pmremGenerator.fromScene(sceneEnv)
    scene.add(skyMesh)

    scene.environment = renderTarget.texture

  }

  updateSun()
  // return skyMesh
}