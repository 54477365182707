import { GameRowValue } from '@sg/backend/src/lib/db/getGameOr404.ts'
import { Component, onMount } from 'solid-js'
import { createMutable, modifyMutable, produce } from 'solid-js/store'
import toast from 'solid-toast'
import bindViewCtxToWindow from '../lib/bindViewCtxToWindow'
import bindEngineToComponent from '../lib/canvas/bind_engine_to_component'
import addDrawMovementGridCanvas from '../lib/canvas/layer/addDrawMovementGridCanvas'
import addBgGradientCanvas from '../lib/canvas/layer/bg_gradient'
import addDrawEntsByKindCanvas from '../lib/canvas/layer/ents_by_type'
import addTerrainCursorCanvasOld from '../lib/canvas/layer/terrain_cursor/add_canvas'
import addCursorCanvas from '../lib/canvas/layer/terrain_cursor/addCursorCanvas'
import calculateMapWidthHeightPxTileSize from '../lib/core/calculateMapWidthHeightPx'
import calculateTileSize from '../lib/core/calculateTileSize'
import createCenterPanOnMount from '../lib/core/createCenterPanOnMount'
import createEngineForGamePlay from '../lib/core/engine/createEngineForGamePlay'
import { Engine } from '../lib/core/engine/Engine.type'
import syncEngineWithPropsRow from '../lib/core/engine/syncEngineWithPropsRow'
import { EntityLayerId } from '../lib/core/entity/entity_layer_id.enum'
import { SelectedToolId } from '../lib/core/map_editor/SelectedToolId.enum'
import { PlayerKind } from '../lib/core/player/PlayerKind.enum'
import { StateType } from '../lib/core/state/state_type.enum'
import { loadGameFromLocalStorage } from '../lib/core/Storage/LocalStorage/localStorageEngineLoader'
import { centerPan } from '../lib/core/view_ctx/centerPan'
import { deepClone } from '../lib/deep_clone'
import registerContextMenuEvent from '../lib/dom/event/registerContextMenuEvent'
import registerGamePlayKeyboardEvents from '../lib/dom/event/registerGamePlayKeyboardEvents'
import registerKeyDownEvent from '../lib/dom/event/registerKeyDownEvent'
import registerKeyUpEvent from '../lib/dom/event/registerKeyUpEvent'
import registerMouseClickEvent from '../lib/dom/event/registerMouseClickEvent'
import registerMouseDownEvent from '../lib/dom/event/registerMouseDownEvent'
import registerMouseMoveEvent from '../lib/dom/event/registerMouseMoveEvent'
import registerMouseUpEvent from '../lib/dom/event/registerMouseUpEvent'
import registerTouchEventsV2 from '../lib/dom/event/registerTouchEventsV2'
import registerWheelEvent from '../lib/dom/event/wheel'
import createRedirectIfWrongStateTypeEffect from '../rx/effect/createRedirectIfWrongStateTypeEffect'
import createSetEngineAuthPlayerIdFromAuthProfileSignalEffect from '../rx/effect/createSetEngineAuthPlayerIdFromAuthProfileSignalEffect'
import createSetEngineAuthPlayerIdFromLocalEffect from '../rx/effect/createSetEngineAuthPlayerIdFromLocalEffect'
import useCornerWidgetEngine from '../rx/shared/CornerWidget/useCornerWidgetEngine'
import createAuthProfileSignal from '../rx/shared/profile/createAuthProfileSignal'
import registerServerSentEvents from '../rx/sse/registerServerSentEvents'
import useLocalBotPlayer from '../rx/use/useLocalBotPlayer'
import EngineContext from './EngineContext'
import GameEndedModal from './GameEndedModal'

interface Props {
  // session: BackendSessionPayload,
  row?: GameRowValue,
  local?: boolean,
}

const componentStateType = StateType.Game

const GamePlayCanvas: Component<Props> = (props) => {
  const engine: Engine = createMutable<Engine>(createEngineForGamePlay())

  // eslint-disable-next-line solid/reactivity
  if (props.local) {
    loadGameFromLocalStorage(engine)

    // eslint-disable-next-line solid/reactivity
    engine.state.players.forEach((player) => {
      player.kind = PlayerKind.Bot
      player.is_local = true
    })
    // engine.state.ents.push(createMediumRocketsEntity(nextEntityIdFromEngine(engine.state), 1, 0, engine.state.players[1].id))
    // engine.state.ents.push(createLightAntiAirEntity(nextEntityIdFromEngine(engine.state), 8, 0, engine.state.players[0].id))

    createSetEngineAuthPlayerIdFromLocalEffect(engine)

    // eslint-disable-next-line solid/reactivity
    console.log('engine.actionLogs', deepClone(engine.actionLogs))

    // engine.actionLogs = engine.actionLogs.filter(l => l.action.type > 2000 || l.action.type === 509)
    // console.log('engine.actionLogs', deepClone(engine.actionLogs))
    // saveGameToLocalStorage(engine)

    useLocalBotPlayer(engine)
  } else {
    onMount(() => {
      syncEngineWithPropsRow(engine, props.row)
    })
    const [authProfile] = createAuthProfileSignal()
    createSetEngineAuthPlayerIdFromAuthProfileSignalEffect(engine, authProfile)
  }

  bindViewCtxToWindow(engine)
  createCenterPanOnMount(engine)

  addBgGradientCanvas(engine)
  // addTerrainGridCanvas(engine)
  addDrawEntsByKindCanvas(engine, [EntityLayerId.TerrainL1])
  addDrawEntsByKindCanvas(engine, [EntityLayerId.TerrainL2])
  addDrawMovementGridCanvas(engine)
  addDrawEntsByKindCanvas(engine, [EntityLayerId.Unit2])

  // addDrawOffMapCloudsCanvas(engine)
  addTerrainCursorCanvasOld(engine)
  addCursorCanvas(engine)

  registerMouseDownEvent(engine)
  registerMouseUpEvent(engine)
  registerMouseClickEvent(engine)
  registerContextMenuEvent(engine)
  registerWheelEvent(engine)
  registerKeyUpEvent(engine)
  registerKeyDownEvent(engine)
  registerGamePlayKeyboardEvents(engine, true)
  // registerTouchEventsV2(engine)

  // modifyMutable(engine, produce((engine) => {
  //   engine.canvasList.forEach(makeCanvasFullViewport)
  // }))
  // modifyMutable(engine, produce((engine) => {
  //   engine.canvasList.forEach(function (elem: HTMLCanvasElement) {
  //     // elem.style.position = 'relative'
  //     // elem.style.top = '0px'
  //     // elem.style.left = '0px'
  //     // elem.width = '100%'
  //     // elem.height = '100%'
  //   })
  // }))

  // const isStateDirty = createIsStateDirtyMemo(engine)
  // useConfirmLeaveWithUnsavedChanges(isStateDirty)

  bindEngineToComponent(engine)

  useCornerWidgetEngine(engine)

  registerServerSentEvents(engine)
  createRedirectIfWrongStateTypeEffect(componentStateType, () => engine.state.type, () => engine.state.online?.id)

  // setUp()
  onMount(() => {
    modifyMutable(engine, produce((engine) => {
      engine.toast = toast
      // engine.selectedPosition = { x: 13, y: 9 }
      // calculateSelectedPositionEnts(engine)
      engine.selectedTool = SelectedToolId.Inspect
      engine.viewCtx.zoom = 200
      engine.viewCtx.zoom = 150
      engine.viewCtx.tile_size = calculateTileSize(engine.viewCtx)
      calculateMapWidthHeightPxTileSize(engine)
      centerPan(engine)
    }))
  })

  // setTimeout(() => {
  //   modifyMutable(engine, produce((engine) => {
  //     engine.selectedPosition = coord(1, 1)
  //     calculateSelectedPositionEnts(engine)
  //     engine.hoveredPosition = coord(2, 3)
  //   }))
  // }, 500)
  // setTimeout(() => {
  //   modifyMutable(engine, produce((engine) => {
  //     engine.hoveredPosition = null
  //     engine.draftMove!.destPosition = coord(2, 3)
  //   }))
  // }, 1000)

  // let prevSelectedTool : SelectedToolId | null = null
  // createEffect(() => {
  //   // console.log('Effect', 'engine.selectedTool', engine.selectedTool)
  //   if (prevSelectedTool === SelectedToolId.MoveUnit && engine.selectedTool === SelectedToolId.Inspect) {
  //     throw new Error('Move -> Inspect')
  //   }
  //   prevSelectedTool = engine.selectedTool
  // })
  // createEffect(() => {
  //   // eslint-disable-next-line solid/reactivity
  //   const { draftMove } = engine
  //   if (draftMove) {
  //     trackDeep(draftMove)
  //   }
  //   console.log('Effect', 'engine.draftMove', unwrap(draftMove))
  // })
  // createEffect(() => {
  //   const { pan_x, pan_y } = engine.viewCtx
  //   console.log('Effect.viewCtx', { pan_x, pan_y })
  // })

  // const [tempHidden, setTempHidden] = createSignal(0)
  // createMountedInverval(() => {
  //   const value = Math.floor(Math.abs(10 * Math.sin(0.001 * Date.now())))
  //   // console.log('value', value)
  //   setTempHidden(value)
  // <div style={{ height: `${tempHidden()}px` }} />
  // }, 10)

  return <EngineContext.Provider value={engine}>
    <div id="gpc">
      <div id="gvp" ref={(ref: HTMLElement) => {
        // console.log('ref', ref)
        if (ref) {
          registerTouchEventsV2(engine, ref)
          registerMouseMoveEvent(engine, ref)
        }
      }}>
        {engine.canvasList}
      </div>
      {/* <div id="cmdp">
        <div class="d-flex justify-content-between">
          <button class="btn btn-custom">Button 1</button>
          <button class="btn btn-custom">Button 2</button>
          <button class="btn btn-custom">Button 3</button>
        </div>
      </div> */}
    </div>
    <GameEndedModal />
  </EngineContext.Provider>
}

export default GamePlayCanvas
