import { useSearchParams } from '@solidjs/router'
import { createEffect, createMemo, Show, Suspense } from 'solid-js'
import AlertBag from '../../components/AlertBag'
import Loader from '../../components/Loader'
import MapEditThreeJsCanvas from '../../components/MapEditThreeJsCanvas'
import { StateType } from '../../lib/core/state/state_type.enum'
import { parseIntOrNull } from '../../lib/core/util/math'
import createRedirectIfWrongStateTypeEffect from '../../rx/effect/createRedirectIfWrongStateTypeEffect'
import createMapRowResource from '../../rx/resource/createMapRowResource'
import { createLoadingSignal, registerLoadingResource } from '../../rx/signal/create_loading_signal'
import { createAlertBagStore, registerAlertBagResource, setAlertBagWarning } from '../../rx/store/create_alert_bag_store'
import type { Nullable } from '../../typescript'

type MapEditPageSearchParams = {
  map_id: string
}

const componentStateType = StateType.Map

const MapEditPageV2 = () => {
  const [searchParams] = useSearchParams<MapEditPageSearchParams>()

  const loading = createLoadingSignal()
  const ab = createAlertBagStore()

  const stateId = createMemo<Nullable<number>>((): Nullable<number> => parseIntOrNull(searchParams.map_id))

  // eslint-disable-next-line solid/reactivity
  const stateItemResource = createMapRowResource(stateId)
  const [stateRow] = stateItemResource
  createRedirectIfWrongStateTypeEffect(componentStateType, () => stateRow()?.data?.type, () => stateRow()?.data?.online?.id)

  registerLoadingResource(loading, stateItemResource)
  registerAlertBagResource(ab, stateItemResource)
  // const [stateRow] = mapItemResource

  createEffect(() => {
    const deleted_at = stateRow()?.deleted_at
    if (deleted_at) {
      setAlertBagWarning(ab, 'This map has been marked for deletion and will soon be removed by an automated garbage collection process.')
    } else {
      setAlertBagWarning(ab, null)
    }
  })

  // TODO only MapEditCanvas can be seen
  return (
    <div>
      <Loader signal={loading} />
      <AlertBag store={ab} />
      <Suspense fallback={<div class="text-muted">Loading...</div>}>
        <Show when={stateRow()}>
          {(sr) => <MapEditThreeJsCanvas row={sr()} />}
        </Show>
      </Suspense>
    </div>
  )
}

export default MapEditPageV2