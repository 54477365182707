import type { Engine } from "../../../engine/Engine.type"
import { PlayerGameStatus } from "../../../player/PlayerGameStatus"
import type { ActionLog } from "../ActionLog.type"

export default function checkIfGameShouldEnd(
  engine: Engine,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  actionLog: ActionLog
) {
  const { state } = engine
  const { players } = state
  const remainingPlayers = players.filter(player => {
    return player.game_status === PlayerGameStatus.Active
  })
  if (remainingPlayers.length === 1) {
    const player = remainingPlayers[0]
    player.game_status = PlayerGameStatus.Won

    // a special GameEnded action will be created after
    // engine.state.type = StateType.Replay
    state.ended = true
  }
}