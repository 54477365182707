import type { Nullable } from '../../../typescript'
import type { Engine } from '../engine/Engine.type'
import { ArmorTypeId } from '../entity/Armor/ArmorTypeId.enum'
import entityTypeMetaList from '../entity/entityTypeMetaList.generated'
import getUnitDefenseAtPosition from '../entity/getUnitDefenseAtPosition'
import hasAtLeastOneWeapon from '../entity/hasAtLeastOneWeapon'
import type { HasTaxiID } from '../entity/HasTaxiID'
import type { Entity } from '../entity/index'
import getUnitTypeWeaponTypeTargetTypePair from '../entity/Weapon/getUnitTypeWeaponTypeTargetTypePair'
import getWeaponTypeArmorTypePair from '../entity/Weapon/getWeaponTypeArmorTypePair'
import { WeaponInvalidId } from '../entity/Weapon/WeaponInvalidId.enum'
import findByIdOrThrow from '../findByIdOrThrow'
import type { HasHP } from '../has_hp'
import type { HasArmor } from '../HasArmor'
import getManhattanDistance from '../tile_position_xy/getManhattanDistance'
import { samePosition } from '../tile_position_xy/samePosition'
import type { TilePositionXY } from '../tile_position_xy/TilePositionXY.type'
import toCoord from '../tile_position_xy/toCoord'
import { byValueDesc } from '../util/HasValue.interface'
import applyDraftMoveAttackEstimateCommonInvalids from './applyDraftMoveAttackEstimateCommonInvalids'
import calcWeaponDmg from './calcWeaponDmg'
import type { DraftMove_AttackEstimate } from './DraftMove_AttackEstimate.type'
import isValidWeaponEstimate from './isValidWeaponEstimate'
import type { WeaponEstimate } from './WeaponEstimate.type'

export default function createDraftMoveAttackEstimate(
  engine: Engine,
  unit: Entity,
  destPosition: Nullable<TilePositionXY>,
  target: Entity
): DraftMove_AttackEstimate {
  // console.log('createDraftMoveAttackEstimate', deepClone({
  //   unit,
  //   destPosition,
  //   target,
  // }))
  const { state } = engine
  const { ents } = state
  const targetPositionEnts : Array<Entity> = []
  const destPositionEnts : Array<Entity> = []
  for (let index = 0; index < ents.length; index++) {
    const ent = ents[index]
    if ((ent as HasTaxiID).taxi_id) {
      // cant attack units inside transport
    } else if (samePosition(ent, target)) {
      targetPositionEnts.push(ent)
    } else if (samePosition(ent, destPosition)) {
      destPositionEnts.push(ent)
    }
  }
  if (!destPositionEnts.includes(unit)) {
    destPositionEnts.push(unit)
  }
  const targetDefense = getUnitDefenseAtPosition(target, targetPositionEnts)
  const unitDefense = getUnitDefenseAtPosition(unit, destPositionEnts)
  const targetEntityTypeMeta = findByIdOrThrow(entityTypeMetaList, target.etype_id)
  const unitEntityTypeMeta = findByIdOrThrow(entityTypeMetaList, unit.etype_id)
  const targetArmorTypeId = targetEntityTypeMeta.armor_type || ArmorTypeId.None
  const unitArmorTypeId = unitEntityTypeMeta.armor_type || ArmorTypeId.None
  const targetPrice = targetEntityTypeMeta.price || 0
  const unitPrice = unitEntityTypeMeta.price || 0

  const { hp: unitHp, armor: unitArmor } = unit as Entity & HasHP & HasArmor
  const { hp: targetHp, armor: targetArmor } = target as Entity & HasHP & HasArmor
  // console.table([{ unitArmor, targetArmor }])

  const distance = getManhattanDistance(destPosition || unit, target)

  // unit attacks target
  const weaponEstimates1: Array<WeaponEstimate> = []

  if (hasAtLeastOneWeapon(unit)) {
    unit.weapons.forEach((weapon) => {
      const uwtPair = getUnitTypeWeaponTypeTargetTypePair(
        unitEntityTypeMeta.id,
        weapon.wt_id,
        targetEntityTypeMeta.id
      )
      const waPair = getWeaponTypeArmorTypePair(weapon.wt_id, targetArmorTypeId)
      const value = calcWeaponDmg(
        waPair.v,
        targetDefense,
        targetArmor,
        targetArmorTypeId,
        weapon.str || 0,
        unitHp,
        uwtPair.v
      )
      const estimate : WeaponEstimate = { weapon, value, distance, errors: [] }
      applyDraftMoveAttackEstimateCommonInvalids(target, estimate)
      weaponEstimates1.push(estimate)
    })
  }

  weaponEstimates1.sort(byValueDesc)
  const unitWeaponEstimate = weaponEstimates1.find(isValidWeaponEstimate) || null
  const targetDmg = unitWeaponEstimate?.value || 0
  const targetHpAfterDmg = targetHp - targetDmg

  // retaliation from target
  const weaponEstimates2: Array<WeaponEstimate> = []
  if (hasAtLeastOneWeapon(target)) {
    target.weapons.forEach((weapon) => {
      const uwtPair = getUnitTypeWeaponTypeTargetTypePair(
        targetEntityTypeMeta.id,
        weapon.wt_id,
        unitEntityTypeMeta.id
      )
      const waPair = getWeaponTypeArmorTypePair(weapon.wt_id, unitArmorTypeId)
      const value = calcWeaponDmg(
        waPair.v,
        unitDefense,
        unitArmor,
        unitArmorTypeId,
        weapon.str || 0,
        targetHpAfterDmg,
        uwtPair.v
      )
      const estimate : WeaponEstimate = { weapon, value, distance, errors: [] }
      applyDraftMoveAttackEstimateCommonInvalids(unit, estimate)
      if (!weapon.ca) {
        estimate.errors.push(WeaponInvalidId.CantCounterAttack)
      }
      weaponEstimates2.push(estimate)
    })
  }
  weaponEstimates2.sort(byValueDesc)
  const targetWeaponEstimate = weaponEstimates2.find(isValidWeaponEstimate) || null
  const unitDmg = targetWeaponEstimate?.value || 0

  // damage can exceed hp, but overkill has no value
  const unitCba = (unitDmg / 10) * unitPrice
  const targetCba = (targetDmg / 10) * targetPrice

  // TODO is overkill already checked? if not:
  // const unitCba = (min(unitHp, unitDmg) / 10) * unitPrice
  // const targetCba = (min(targetHp, targetDmg) / 10) * targetPrice

  return {
    unit,
    target,
    unitPosition: destPosition || toCoord(unit),
    unitWeapons: weaponEstimates1,
    targetWeapons: weaponEstimates2,
    unitWeaponEstimate,
    targetWeaponEstimate,
    unitDmg,
    targetDmg,
    unitCba,
    targetCba,
    unitDefense,
    targetDefense,
  }
}


