import { captureException } from "@sentry/browser"
import createDefaultWait from "../../../createDefaultWait"
import BotInvalidMove from "../../../Exception/BotInvalidMove.class"
import createDraftMove from "../../draft_move/createDraftMove"
import type { Engine } from "../../engine/Engine.type"
import type { AnnexableEntityUnion } from "../../entity/AnnexableEntityUnion.type"
import { isEntityInfantry } from "../../entity/entityTypeMetaList.generated"
import { byEntityResaleValueDesc } from "../../entity/getEntityResaleValue"
import { byEntityMapControlPriorityDesc } from "../../entity/getMapControlPriority"
import getPlayerEntities from "../../entity/getPlayerEntities"
import isBeingAnnexed from "../../entity/isBeingAnnexed"
import isEntityAnnexable from "../../entity/isEntityAnnexable"
import isEntityHasMovesLeft from "../../entity/isEntityHasMovesLeft"
import notInTaxi from "../../entity/notInTaxi"
import { createMoveUnitAction } from "../../state/flux/action/Game/MoveUnitAction"
import dispatchClient from "../../state/flux/dispatchClient"
import entityListToGridXY from "../../tile_position_xy/entityListToGridXY"
import findMostEfficientPathToTileV2 from "../../tile_position_xy/findMostEfficientPathToTileV2/findMostEfficientPathToTileV2"
import type { PathTurnStepCollection } from "../../tile_position_xy/findMostEfficientPathToTileV2/PathTurnStepCollection.type"
import getManhattanDistance from "../../tile_position_xy/getManhattanDistance"
import { byDistanceAsc } from "../../tile_position_xy/sortByDistance"
import toCoord from "../../tile_position_xy/toCoord"
import { byTotalCostAsc } from "../../util/sortByTotalCost"
import type { Bot } from "../Bot.type"
import throwOnBotError from "../throwOnBotError"
import botShouldContinue from "./botShouldContinue"

type AnnexableEntityAndDistance = {
  ent: AnnexableEntityUnion
  distance: number
}

/**
 * all units will seek out annexables,
 * infantry for the purpose of annexing,
 * other units for the purpose of making sure
 * the infantry successfully annexes
 * 
 * @param engine 
 * @param bot 
 */
export default async function botAnnexesFind(
  engine: Engine,
  bot: Bot
) {
  console.log('%c[botAnnexesFind]', 'color: grey; font-weight: lighter; font-size: 10px;')
  const { player, stepDelay } = bot
  const playerId = player.id
  const allPlayerEnts = getPlayerEntities(engine.state.ents, playerId)
  // console.log('allPlayerEnts', deepClone(allPlayerEnts))
  const playerUnits = allPlayerEnts.filter((ent) => {
    return (
      // isEntityUnit(ent) &&
      isEntityInfantry(ent) &&
      isEntityHasMovesLeft(ent) &&
      notInTaxi(ent)
    )
  }).sort(byEntityResaleValueDesc)
  // console.log('playerInfantryUnits', deepClone(playerUnits))

  const { width, height } = engine.state

  async function innerFn(unit: typeof playerUnits[0]) {

    const entityGridXY = entityListToGridXY(engine.state.ents, width, height)

    const allAnnexableEnts : Array<AnnexableEntityUnion> = engine.state.ents.filter((ent): ent is AnnexableEntityUnion => {
      return (ent.player_id !== playerId
          && isEntityAnnexable(ent)
          && !isBeingAnnexed(ent)
      ) as boolean
    }).sort(byEntityMapControlPriorityDesc)
    // console.log('allAnnexableEnts', deepClone(allAnnexableEnts))

    const bestPaths = allAnnexableEnts.reduce((carry : Array<AnnexableEntityAndDistance>, annexableEnt : AnnexableEntityUnion) => {
      carry.push({
        ent: annexableEnt,
        distance: getManhattanDistance(unit, annexableEnt),
      })
      return carry
    }, []).sort(byDistanceAsc).reduce((carry: Array<PathTurnStepCollection>, md) => {
      if (carry.length < 8) {
        const { ent: annexableEnt } = md
        const eptt = findMostEfficientPathToTileV2(entityGridXY, unit, width, height, annexableEnt.x, annexableEnt.y)
        if (eptt) {
          carry.push(eptt)
        }
      }
      return carry
    }, []).sort(byTotalCostAsc)

    // hope the first lastStep is not occupied
    let index = 1
    do {
      for (const eptt of bestPaths) {
        if (await attemptEptt(unit, eptt, index)) {
          return
        }
      }
    } while (index++ <= unit.mobility)
  }

  async function attemptEptt(unit: typeof playerUnits[0], eptt: PathTurnStepCollection, index: number): Promise<void | true> {
    const firstTurn = eptt.turns[0]
    if (firstTurn) {
      const lastStep = firstTurn.steps.at(index * -1)
      if (lastStep && !lastStep?.impass && !lastStep?.occupant && !lastStep?.enemies?.[0]) {
        try {
          const draftMove = createDraftMove(unit)
          draftMove.destPosition = toCoord(lastStep)
          await dispatchClient(engine, createMoveUnitAction(draftMove))

          // stop attempting to find a place to go
          return true
        } catch (err1) {
          const err2 = new BotInvalidMove('Stroll', err1)
          console.error(err2)
          captureException(err2)
          throwOnBotError(err2)
        }
      }
    }
  }

  do {
    const unit = playerUnits.pop()
    if (!unit) {
      console.log('%cran out of units', 'color: grey; font-weight: lighter; font-size: 10px;')
      break
    }

    await innerFn(unit)

    await createDefaultWait(stepDelay)
  } while (botShouldContinue(engine, bot))
}