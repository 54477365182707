import { modifyMutable, produce } from "solid-js/store"
import { isServer } from "solid-js/web"
import { parse } from "valibot"
import type { Engine } from "../../engine/Engine.type"
import { SavedGamePayloadSchema, type SavedGamePayload } from "./SavedGamePayload.type"
import { hasLocalStorage } from "./HasLocalStorage"

export const LocalStorageGameName = 'st1_g0'

export function localStorageGameExists() {
  return hasLocalStorage && !!localStorage.getItem(LocalStorageGameName)
}

export async function saveGameToLocalStorage(engine : Engine): Promise<void> {
  const payload : SavedGamePayload = {
    state: engine.state,
    actionLogs: engine.actionLogs,
  } 
  const validatedPayload = parse(SavedGamePayloadSchema, payload)
  const jsonString = JSON.stringify(validatedPayload)
  localStorage.setItem(LocalStorageGameName, jsonString)
}
export async function loadGameFromLocalStorage(engine : Engine): Promise<void> {
  if (isServer) {
    return
  }
  const jsonString = localStorage.getItem(LocalStorageGameName)
  const jsonValue = jsonString ? JSON.parse(jsonString) : null
  const parseResult = parse(SavedGamePayloadSchema, jsonValue)
  modifyMutable(engine, produce((engine : Engine) => {
    engine.state = parseResult.state
    engine.actionLogs = parseResult.actionLogs
  }))
}