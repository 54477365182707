import { literal, object, type InferOutput } from 'valibot'
import { Engine } from '../../../../engine/Engine.type'
import type { ActionLog } from '../../ActionLog.type'
import { ActionType } from '../ActionType'

// type BotId = Nullable<AuthProfile['id']>

export const RestoreLobbyActionSchema = object({
  type: literal(ActionType.Lobby.RestoreLobby),
})

export type RestoreLobbyAction = InferOutput<typeof RestoreLobbyActionSchema>

export function createRestoreLobbyAction(): RestoreLobbyAction {
  return {
    type: ActionType.Lobby.RestoreLobby,
  }
}

export async function handleRestoreLobbyAction(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  engine: Engine,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: RestoreLobbyAction,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  actionLog: ActionLog
): Promise<void> {
  // so basically the server does a special thing when it receives this action
}
