import { captureException } from "@sentry/browser"
import type { Nullable } from "vite-node"
import createDefaultWait from "../../../createDefaultWait"
import { deepClone } from "../../../deep_clone"
import BotInvalidMove from "../../../Exception/BotInvalidMove.class"
import calculateDraftMoveRangedAttackEstimates from "../../draft_move/calculateDraftMoveRangedAttackEstimates"
import createDraftMove from "../../draft_move/createDraftMove"
import type { DraftMove_AttackEstimate } from "../../draft_move/DraftMove_AttackEstimate.type"
import type { DraftMove_RangedEstimate } from "../../draft_move/DraftMove_RangedEstimate.type"
import type { Engine } from "../../engine/Engine.type"
import entityTypeMetaList from "../../entity/entityTypeMetaList.generated"
import { byEntityResaleValueDesc } from "../../entity/getEntityResaleValue"
import getPlayerEntities from "../../entity/getPlayerEntities"
import isEntityHasMovesLeft from "../../entity/isEntityHasMovesLeft"
import notInTaxi from "../../entity/notInTaxi"
import unitHasUsableRangedAttackWeapon from "../../entity/unitHasUsableRangedAttackWeapon"
import findByIdOrThrow from "../../findByIdOrThrow"
import type { HasFuel } from "../../has_fuel"
import type { HasMobility } from "../../has_mobility"
import type { HasPrice } from "../../HasPrice"
import { createMoveUnitAction } from "../../state/flux/action/Game/MoveUnitAction"
import dispatchClient from "../../state/flux/dispatchClient"
import toCoord from "../../tile_position_xy/toCoord"
import type { Bot } from "../Bot.type"
import throwOnBotError from "../throwOnBotError"
import botShouldContinue from "./botShouldContinue"

export default async function botRangedAttacks(
  engine: Engine,
  bot: Bot
) {
  console.log('%c[botRangedAttacks]', 'color: grey; font-weight: lighter; font-size: 10px;')
  const { player, stepDelay } = bot
  const playerId = player.id
  const allPlayerEnts = getPlayerEntities(engine.state.ents, playerId)
  // console.log('allPlayerEnts', deepClone(allPlayerEnts))
  const rangedUnits = allPlayerEnts.filter(isEntityHasMovesLeft)
    .filter(notInTaxi)
    .filter(unitHasUsableRangedAttackWeapon)
    .sort(byEntityResaleValueDesc)
  // console.log('rangedUnits', deepClone(rangedUnits))

  let index = 0
  do {
    const unit = rangedUnits[index++]
    if (!unit) {
      console.log('%cran out of units', 'color: grey; font-weight: lighter; font-size: 10px;')
      break
    }

    const unitEntityTypeMeta = findByIdOrThrow(entityTypeMetaList, unit.etype_id)
    const {
      mobility: unitDefaultMobility,
    } = unitEntityTypeMeta.entDefaults as HasMobility & HasPrice & HasFuel
    const unitHasFullMobilityPoints = unitDefaultMobility && unitDefaultMobility === unit.mobility
    if (!unitHasFullMobilityPoints) {
      continue;
    }

    console.log('unit', deepClone(unit))

    const draftMove = createDraftMove(unit)
    calculateDraftMoveRangedAttackEstimates(engine, draftMove)
    const rangedEstimates : Nullable<Array<DraftMove_RangedEstimate>> = draftMove.rangedEstimates?.filter((re : DraftMove_RangedEstimate) => {
      const estimate: Nullable<DraftMove_AttackEstimate> = re.estimate
      if (estimate) {
        const { unitWeaponEstimate } = estimate
        if (!unitWeaponEstimate) {
          return false
        }
        return true
      }
      return false
    })

    if (rangedEstimates) {
      for (const estimate of rangedEstimates) {
        const target = estimate.estimate?.target
        if (target) {
          try {
            await dispatchClient(engine, createMoveUnitAction({
              ...draftMove,
              attackPosition: toCoord(estimate),
              target,
            }))

            // stop attempting ranged attacks
            break;
          } catch (err1) {
            const err2 = new BotInvalidMove('Ranged Attack', err1)
            console.error(err2)
            captureException(err2)
            throwOnBotError(err2)
          }
        }
      }
    } else {
      // unit will do something else
    }

    await createDefaultWait(stepDelay)
  } while (botShouldContinue(engine, bot))
}