import { addBreadcrumb, captureException } from '@sentry/browser'
import { now } from '@sg/shared/src/lib/Date'
import { flatten, isValiError, parse } from 'valibot'
import { setAlertBagError } from '../../../../rx/store/create_alert_bag_store'
import { Engine } from '../../engine/Engine.type'
import { StateSchema } from '../State.type'
import Action from './action/Action.type'
import type ActionHandler from './ActionHandler.type'
import actionHandlersList, { type ActionSchemaUnion } from './actionHandlersList.generated'
import type { ActionLog } from './ActionLog.type'

// let lastClientDispatch: Promise<unknown> | null = null
export default async function dispatchReplay(engine: Engine, action: Action, cref: number): Promise<void> {
  // console.log('dispatchReplay', {
  //   authPlayerId: engine.authPlayerId,
  //   players: engine.state.players.map((p) => ({
  //     player_id: p.id,
  //     profile_id: p.profile_id,
  //   }))
  // })

  addBreadcrumb({
    category: 'action',
    level: 'info',
    data: {
      action,
    },
  })
  // if (isServer) {
  //   throw new Error('dispatchReplay is only available on the client')
  // }
  try {
    // const handler = actionHandlersList.get(action.type)
    const tuple = actionHandlersList.find((t) => action.type === t[0])
    if (tuple) {
      // const actionType = tuple[0]
      const actionSchema: ActionSchemaUnion = tuple[1]
      const actionHandler: ActionHandler = tuple[2]
      const validatedAction = parse(actionSchema, action)
      // await actionHandler(engine, validatedAction)

      const { state : { round }, authPlayerId } = engine

      const actionLog : ActionLog = {
        cref,
        round,
        player_id: authPlayerId,
        at: now(),
        action: validatedAction,
      }

      await actionHandler(engine, validatedAction, actionLog)

      engine.state = parse(StateSchema, engine.state)

      engine.actionLogs.push(actionLog)

      // engine.prevState = deepClone(engine.state)
    } else {
      throw new Error(`Action ${action.type} is not supported`)
    }
  } catch (err) {
    const { toast, ab } = engine
    const msg: string = (err as { message?: string }).message || 'unknown'
    const context: Record<string, unknown> = {
      action,
    }
    if (isValiError(err)) {
      context.errors = flatten(err.issues)
    }
    const err2 = new Error(`Action Failed: ${msg}`, { cause: err })
    console.error(err, context)
    captureException(err, context)
    if (toast) {
      toast.error(msg)
    }
    if (ab) {
      setAlertBagError(ab, err2)
    }
  }
}
