import type { BuildingEntity } from "./EntityType/Building"
import type { FactoryEntity } from "./EntityType/Factory"
import type { HQEntity } from "./EntityType/HQ"
import { isEntityBuilding, isEntityFactory, isEntityHQ } from "./entityTypeMetaList.generated"
import type { Entity } from "./index"

export type MapControlTargetEntity = HQEntity | BuildingEntity | FactoryEntity

export default function isMapControlTarget (entity: Entity) {
  return isEntityHQ(entity)
    || isEntityBuilding(entity)
    || isEntityFactory(entity)
}