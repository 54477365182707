import { InferOutput, enum_, object } from 'valibot'

/**
 * usually an entity cannot share a position
 * with another entity if they are both the same type.
 * example: helicopter flies over tank
 * counter-example: jump-jet troops land into enemy squad to squash+melee
 */

export enum EntityLayerId {
  /**
   * @example: Plain, Desert, Ocean
   */
  TerrainL1 = 1,
  /**
   * @example: River, Reef, Mountain, Barren, Road, Forest, HQ, Factory
   */
  TerrainL2 = 2,
  /**
   * @example: Fog, Wind
   */
  TerrainL3 = 3,

  /**
   * @example: submarine, if submerged
   */
  Unit1 = 11,
  /**
   * @example: infrantry, tank
   */
  Unit2 = 12,
  /**
   * @example: helicopter
   */
  Unit3 = 13,
  /**
   * @example: fighters, b52s
   */
  Unit4 = 14,
  /**
   * @example: satellites
   */
  Unit5 = 15,
}

export const EntityLayerIdSchema = enum_(EntityLayerId, 'Invalid Entity Layer ID')

export const HasLayerIdSchema = object({
  layer_id: EntityLayerIdSchema,
})

export type HasLayerId = InferOutput<typeof HasLayerIdSchema>
