import { captureException } from '@sentry/browser'
import { StoreMapBodySchema } from '@sg/backend/src/api/st1/map/handleStoreMap'
import { BioSchema } from '@sg/shared/src/valibot/schema/Bio.type'
import { DisplayNameSchema } from '@sg/shared/src/valibot/schema/DisplayName.type'
import valibotToErrorBag from '@sg/shared/src/valibot/valibotToErrorBag.ts'
import { A, useNavigate } from '@solidjs/router'
import {
  Description,
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "solid-a11y"
import { FormGroup, FormLabel } from 'solid-bootstrap'
import { FaRegularFloppyDisk } from 'solid-icons/fa'
import { For, Show, createEffect, createMemo, createSignal } from 'solid-js'
import { flatten, object, safeParse, type InferOutput } from 'valibot'
import { backendAxios } from '../../axios'
import AlertBag from '../../components/AlertBag'
import AlertWarning from '../../components/AlertWarning'
import Breadcrumbs from '../../components/Breadcrumb/Breadcrumbs'
import FormSection from '../../components/Form/FormSection'
import InvalidFeedback from '../../components/Form/InvalidFeedback'
import Loader from '../../components/Loader'
import MapShowCanvas from '../../components/MapShowCanvas'
import toastError from '../../lib/AlertBag/toastError'
import { MapScript_FormData } from '../../lib/core/map/map_scripts/FormData/FormData.type'
import runFormData from '../../lib/core/map/map_scripts/FormData/run/runFormData'
import MapScriptForm from '../../lib/core/map/map_scripts/MapScriptForm'
import { MapScriptOption } from '../../lib/core/map/map_scripts/MapScriptOption.type'
import mapScriptOptionsList from '../../lib/core/map/map_scripts/mapScriptOptionsList'
import { SymmetryScriptOption } from '../../lib/core/map/symmetry_scripts/SymmetryScriptOption.type'
import symmetryScriptOptionsList from '../../lib/core/map/symmetry_scripts/symmetryScriptOptionsList'
import { randomMapSeed } from '../../lib/core/rng/map_seed'
import { State } from '../../lib/core/state/State.type'
import { StateHeightSchema } from '../../lib/core/state/StateHeight.type'
import { MAP_DEFAULT_HEIGHT, MAP_DEFAULT_WIDTH, MAP_MAX_SIZE, MAP_MIN_SIZE } from '../../lib/core/state/StateMap.type'
import { StateWidthSchema } from '../../lib/core/state/StateWidth.type'
import preventDefault from '../../lib/dom/event/preventDefault'
import createIsDotKeyInvalidDerivedSignal from '../../rx/derived_signal/createIsDotKeyInvalidDerivedSignal'
import canSessionCreateGame from '../../rx/shared/session/canSessionCreateGame'
import { createSessionSignal } from '../../rx/shared/session/createSessionSignal'
import { createLoadingSignal } from '../../rx/signal/create_loading_signal'
import { createAlertBagStore, setAlertBagError } from '../../rx/store/create_alert_bag_store'

const MapFormDataSchema = object({
  dname: DisplayNameSchema,
  bio: BioSchema,
  width: StateWidthSchema,
  height: StateHeightSchema,
})

type MapFormData = InferOutput<typeof MapFormDataSchema>

function createMapRowFormData(): MapFormData {
  return {
    dname: '',
    bio: '',
    width: MAP_DEFAULT_WIDTH,
    height: MAP_DEFAULT_HEIGHT,
    // width: 6,
    // height: 4,
  }
}
const MapCreatePage = () => {
  const navigate = useNavigate()

  const loading = createLoadingSignal()
  const ab = createAlertBagStore()
  const [formData, setFormData] = createSignal<MapFormData>(createMapRowFormData())
  const formDataValidation = createMemo(() => safeParse(MapFormDataSchema, formData()))
  // eslint-disable-next-line solid/reactivity
  const isDotPathInvalid = createIsDotKeyInvalidDerivedSignal(formDataValidation)
  const hasErrors = () => !formDataValidation().success
  const disableForm = createMemo<boolean>(() => {
    return Boolean(hasErrors() || loading())
  })
  // createEffect(() => {
  //   console.log('MapCreatePage.effect.formData', formData())
  // })

  const [selectedMapScriptOption, setSelectedMapScriptOption] = createSignal<MapScriptOption>(mapScriptOptionsList[0])
  setSelectedMapScriptOption(mapScriptOptionsList[1])

  const [selectedSymmetryScriptOption, setSelectedSymmetryScriptOption] = createSignal<SymmetryScriptOption>(symmetryScriptOptionsList[0])
  setTimeout(() => {
    setSelectedSymmetryScriptOption(symmetryScriptOptionsList[3])
  }, 1000)
  // createMountedInverval(() => {
  //   if (selectedSymmetryScriptOption().dname === symmetryScriptOptionsList[3].dname) {
  //     setSelectedSymmetryScriptOption(symmetryScriptOptionsList[3])
  //   } else {
  //     setSelectedSymmetryScriptOption(symmetryScriptOptionsList[0])
  //   }
  // }, 1000)

  const session = createSessionSignal()[0]

  const needsToSignInBeforeCanCreateGame = createMemo(() => {
    // return disk() === StateMapDisk.Cloud && !canSessionCreateGame(session())
    return !canSessionCreateGame(session())
  })

  const [mapScriptFormData, setMapScriptFormData] = createSignal<MapScript_FormData>({})
  createEffect(() => {
    const smso = selectedMapScriptOption()
    if (smso) {
      setMapScriptFormData({
        ...smso.makeFormData(),
        seed: randomMapSeed(),
        // seed: 28241,
      })
    }
  })
  const mapScriptOptionMapPreviewState = createMemo<State>(() => {
    const state = formData() as Partial<State>
    const msfd = mapScriptFormData()
    // console.log('createMemo.mapScriptOptionMapPreviewState.mso', mso)
    // const newValue = mso.run(formData() as Partial<State>, mapScriptFormData())
    let newValue = runFormData(state, msfd)
    // console.log('createMemo.mapScriptOptionMapPreviewState', newValue)
    const symmetryScriptOption = selectedSymmetryScriptOption()
    if (symmetryScriptOption?.run) {
      newValue = symmetryScriptOption.run(newValue)
    }

    return newValue
  })

  const handleChange = (event: InputEvent) => {
    const target = (event.target as HTMLInputElement)
    const { name, value } = target
    setFormData((prevMap: MapFormData): MapFormData => ({ ...prevMap, [name]: value }))
  }
  const handleIntegerChange = (event: InputEvent) => {
    const target = (event.target as HTMLInputElement)
    const { name, value } = target
    setFormData((prevMap: MapFormData): MapFormData => ({
      ...prevMap, [name]: parseInt(value, 10),
    }))
  }

  // let debugChangeStuff : ReturnType<typeof setInterval>
  // onMount(() => {
  //   debugChangeStuff = setInterval(() => {
  //     setSelectedMapScriptOption(mapScriptOptionsList.filter(v => v !== selectedMapScriptOption())[0])
  //   }, 2000)
  // })
  // onCleanup(() => {
  //   clearInterval(debugChangeStuff)
  // })

  const handleSubmit = async (event: Event) => {
    preventDefault(event)
    const sentryContext: Record<string, unknown> = {}
    try {
      loading.start()
      setAlertBagError(ab, null)
      if (hasErrors()) {
        const errorBag = valibotToErrorBag(formDataValidation())
        console.error('validation errors:', errorBag)
        setAlertBagError(ab, JSON.stringify(errorBag, null, 2))
        return
      }
      // const user_id = session()?.user?.id
      const fd = formData()
      const payload = {
        // ...formData(),
        // creator_id: user_id,
        // owner_id: user_id,
        state: mapScriptOptionMapPreviewState(),
      }
      payload.state.map.dname = fd.dname

      const result = safeParse(StoreMapBodySchema, payload)

      if (!result.success) {
        sentryContext.errors = flatten(result.issues)
        throw new Error(`Script Validation Failed`, { cause: result })
      }
      const response = await backendAxios.post('/st1/map', payload)
      const { data } = response

      const newStateId = data?.id
      // console.log('Map saved:', formData(), id || data)
      if (newStateId) {
        navigate(`/map?map_id=${newStateId}`) // Redirect to the maps listing page after saving
      } else {
        const msg = 'Could not parse ID from: '
        console.error(msg, data)
        setAlertBagError(ab, msg + JSON.stringify(data, null, 2))
      }
      // }
    } catch (err) {
      console.error(err, sentryContext)
      captureException(err, sentryContext)
      setAlertBagError(ab, err as Error)
      toastError(err)
    } finally {
      loading.end()
    }
  }

  return (
    <div class="pagec w-24-rem" style={{ "max-height": "80vh" }}>
      <Breadcrumbs />
      <h2>Create Map</h2>
      <Show when={needsToSignInBeforeCanCreateGame()}>
        <AlertWarning>
          <A href="/auth/sign-in">Sign In</A> to save maps
        </AlertWarning>
      </Show>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <FormLabel>Name</FormLabel>
          <input
            class="form-control"
            classList={{
              'is-invalid': (isDotPathInvalid('dname')) as unknown as boolean,
            }}
            type="text"
            name="dname"
            value={formData().dname || ''}
            onInput={handleChange}
            required
          // minLength={DNAME_MINIMUM} maxLength={DNAME_MAXIMUM}
          />
          <InvalidFeedback name="dname" v={formDataValidation()} />
        </FormGroup>
        <FormGroup>
          <FormLabel>Description</FormLabel>
          <textarea
            class="form-control"
            classList={{
              'is-invalid': (isDotPathInvalid('bio')) as unknown as boolean,
            }}
            name="bio"
            value={formData().bio || ''}
            onInput={handleChange}
            rows="2"
          // minLength={DESCRIPTION_MINIMUM} maxLength={DESCRIPTION_MAXIMUM}
          />
          <InvalidFeedback name="bio" v={formDataValidation()} />
        </FormGroup>
        <FormGroup>
          <FormLabel class="w-50">Width</FormLabel>
          <input class="form-control w-50 d-inline text-end" classList={{
            'is-invalid': (isDotPathInvalid('width')) as unknown as boolean,
          }}
            name="width" type="number" step={2}
            value={formData().width || ''}
            onInput={handleIntegerChange}
            placeholder={`From ${MAP_MIN_SIZE} and ${MAP_MAX_SIZE}`} min={MAP_MIN_SIZE} max={MAP_MAX_SIZE}
            required
          />
          <InvalidFeedback name="width" v={formDataValidation()} />
        </FormGroup>
        <FormGroup>
          <FormLabel class="w-50">Height</FormLabel>
          <input class="form-control w-50 d-inline text-end" classList={{
            'is-invalid': (isDotPathInvalid('height')) as unknown as boolean,
          }}
            name="height" type="number" step={2}
            value={formData().height || ''}
            onInput={handleIntegerChange}
            placeholder={`From ${MAP_MIN_SIZE} and ${MAP_MAX_SIZE}`} min={MAP_MIN_SIZE} max={MAP_MAX_SIZE}
            required
          />
          <InvalidFeedback name="height" v={formDataValidation()} />
        </FormGroup>
        <FormSection show>
          <FormGroup>
            <Listbox<MapScriptOption>
              onChange={setSelectedMapScriptOption}
              value={selectedMapScriptOption()}
            >
              <Label classList={{ 'form-label': true }}>Map Script</Label>
              <Description classList={{ 'form-text': true }}>Choosing a color will affect your preferences for t-shirt color.</Description>
              <ListboxButton classList={{ 'btn btn-secondary w-100 text-start': true }}>
                {selectedMapScriptOption()?.dname}
              </ListboxButton>
              <ListboxOptions classList={{ 'list-group': true }}>
                {() => (
                  <For each={mapScriptOptionsList}>
                    {(mso: MapScriptOption) => {
                      return <ListboxOption<MapScriptOption>
                        classList={{ 'list-group-item': true }}
                        value={mso}
                      >{mso.dname}</ListboxOption>
                    }}
                  </For>
                )}
              </ListboxOptions>
            </Listbox>
          </FormGroup>
          <MapScriptForm
            value={mapScriptFormData()}
            onChange={setMapScriptFormData}
          />
          <FormGroup>
            <Listbox<SymmetryScriptOption>
              onChange={setSelectedSymmetryScriptOption}
              value={selectedSymmetryScriptOption()}
            >
              <Label classList={{ 'form-label': true }}>Symmetry Script</Label>
              <Description classList={{ 'form-text': true }}>Multiplayer maps benefit from symmetry, but campaign and scenario maps are usually better without.</Description>
              <ListboxButton classList={{ 'btn btn-secondary w-100 text-start': true }}>
                {selectedSymmetryScriptOption()?.dname}
              </ListboxButton>
              <ListboxOptions classList={{ 'list-group': true }}>
                {() => (
                  <For each={symmetryScriptOptionsList}>
                    {(symmetryScriptOption: SymmetryScriptOption) => {
                      return <ListboxOption<SymmetryScriptOption>
                        classList={{ 'list-group-item': true }}
                        value={symmetryScriptOption}
                      >{symmetryScriptOption.dname}</ListboxOption>
                    }}
                  </For>
                )}
              </ListboxOptions>
            </Listbox>
          </FormGroup>
          <br />
          <MapShowCanvas state={mapScriptOptionMapPreviewState()} minWidth={361} width={361} height={400} />
        </FormSection>
        <AlertBag store={ab} />
        <div>
          <button class="btn btn-primary" type="submit" disabled={disableForm()}>
            <FaRegularFloppyDisk /> Save
          </button>
        </div>
      </form>
      <Loader signal={loading} />
    </div>
  )
}

export default MapCreatePage