import { GameRowValue } from '@sg/backend/src/lib/db/getGameOr404.ts'
import { A, useNavigate, useSearchParams } from '@solidjs/router'
import { FaSolidPencil } from 'solid-icons/fa'
import { createMemo, onMount, Show, Suspense } from 'solid-js'
import { produce } from 'solid-js/store'
import AlertBag from '../../components/AlertBag'
import Breadcrumbs from '../../components/Breadcrumb/Breadcrumbs'
import DeleteButton from '../../components/Btn/DeleteButton'
import ContinueOrSpectateGameButton from '../../components/ContinueOrSpectateGameButton'
import GameShowCanvas from '../../components/GameShowCanvas'
import Loader from '../../components/Loader'
import gameDisplayName from '../../lib/core/state/gameDisplayName'
import { StateType } from '../../lib/core/state/state_type.enum'
import { StateTypeGame } from '../../lib/core/state/stateTypeMetaList.type'
import { parseIntOrNull } from '../../lib/core/util/math'
import createRedirectIfWrongStateTypeEffect from '../../rx/effect/createRedirectIfWrongStateTypeEffect'
import createGameRowResource from '../../rx/resource/createGameRowResource'
import { createLoadingSignal, registerLoadingResource } from '../../rx/signal/create_loading_signal'
import { createAlertBagStore, registerAlertBagResource } from '../../rx/store/create_alert_bag_store'
import type { Nullable } from '../../typescript'

type GameShowPageSearchParams = {
  game_id: string
}

const componentStateType = StateType.Game

const GameShowPage = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams<GameShowPageSearchParams>()
  onMount(() => {
    if (!searchParams.game_id) {
      navigate('/')
    }
  })

  const loading = createLoadingSignal()
  const ab = createAlertBagStore()

  const stateId = createMemo<Nullable<number>>((): Nullable<number> => parseIntOrNull(searchParams.game_id))
  // eslint-disable-next-line solid/reactivity
  const gameRowResource = createGameRowResource(stateId)
  const [stateRow, { mutate }] = gameRowResource

  createRedirectIfWrongStateTypeEffect(componentStateType, () => stateRow()?.data?.type, () => stateRow()?.data?.online?.id)

  registerLoadingResource(loading, gameRowResource)
  registerAlertBagResource(ab, gameRowResource)

  return (
    <div class="pagec">
      <Breadcrumbs />
      <Suspense fallback={<div class="text-muted">Loading...</div>}>
        <Show when={stateRow()?.data}>
          {(state) => <>
            <h2>
              {gameDisplayName(state())}
              {' '}
              <A class="btn btn-primary btn-sm"
                href={`/game/edit?game_id=${searchParams.game_id}`}
                aria-label="edit game">
                <FaSolidPencil />
              </A>
              {' '}
              <DeleteButton
                st={StateTypeGame}
                disabled={loading()}
                ab={ab}
                item={stateRow() as GameRowValue}
                onChange={(newValue) => {
                  mutate(produce((stateRow) => {
                    (stateRow as GameRowValue).deleted_at = newValue
                  }))
                }}
              />
              <ContinueOrSpectateGameButton row={stateRow() as GameRowValue} />
            </h2>
            <Loader signal={loading} />
            <AlertBag store={ab} />
            <GameShowCanvas row={stateRow() as GameRowValue} width={512} height={512} />
          </>}
        </Show>
      </Suspense>
    </div>
  )
}

export default GameShowPage