import type { Engine } from "../../engine/Engine.type"
import { PlayerTurnStatus, playerTurnStatusTextMap } from "../../player/PlayerTurnStatus"
import type { Bot } from "../Bot.type"

export default function botShouldContinue(
  engine: Engine,
  bot: Bot
) {
  if (engine.state.ended) {
    console.log('%c[botShouldContinue]%c game ended', 'color: #F33;', '', {playerId: bot.player.id})
    return false
  }
  if (bot.player.id !== engine.state.turnPlayerId) {
    console.log('%c[botShouldContinue]%c not bot turn', 'color: #F33;', '', {playerId: bot.player.id})
    return false
  }
  if (bot.player.turn_status !== PlayerTurnStatus.Playing) {
    console.log('%c[botShouldContinue]%c turn_status not playing', 'color: #F33;', '', {playerId: bot.player.id, turnStatus: playerTurnStatusTextMap[bot.player.turn_status]})
    throw new Error('turn_status not playing')
    return false
  }
  if (bot.maxIterations-- <= 0) {
    console.log('%c[botShouldContinue]%c maxIterations', 'color: #F33;', '', {playerId: bot.player.id})
    return false
  }
  return true
}