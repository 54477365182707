import createDefaultWait from "../../../createDefaultWait"
import BotInvalidMove from "../../../Exception/BotInvalidMove.class"
import hasLengthOne from "../../../ldsh/hasLengthOne"
import createDraftMove from "../../draft_move/createDraftMove"
import createDraftMoveAttackEstimate from "../../draft_move/createDraftMoveAttackEstimate"
import type { Engine } from "../../engine/Engine.type"
import canUnitDirectAttackInGeneral from "../../entity/canUnitDirectAttackInGeneral"
import { byEntityResaleValueDesc } from "../../entity/getEntityResaleValue"
import getPlayerEntities from "../../entity/getPlayerEntities"
import isEntityHasMovesLeft from "../../entity/isEntityHasMovesLeft"
import isEntityUnit from "../../entity/isEntityUnit"
import notInTaxi from "../../entity/notInTaxi"
import type { UnitEntityUnion } from "../../entity/UnitEntityUnion.type"
import { createMoveUnitAction } from "../../state/flux/action/Game/MoveUnitAction"
import dispatchClient from "../../state/flux/dispatchClient"
import entityListToGridXY from "../../tile_position_xy/entityListToGridXY"
import findMostEfficientPathToTileV2 from "../../tile_position_xy/findMostEfficientPathToTileV2/findMostEfficientPathToTileV2"
import getManhattanDistance from "../../tile_position_xy/getManhattanDistance"
import toCoord from "../../tile_position_xy/toCoord"
import { byCbaDesc } from "../../util/sortByCba"
import type { Bot } from "../Bot.type"
import throwOnBotError from "../throwOnBotError"
import botShouldContinue from "./botShouldContinue"

export default async function botDirectAttacks(
  engine: Engine,
  bot: Bot
) {
  console.log('%c[botDirectAttacks]', 'color: grey; font-weight: lighter; font-size: 10px;')
  const { player, stepDelay } = bot
  const playerId = player.id
  const allPlayerEnts = getPlayerEntities(engine.state.ents, playerId)
  // console.log('allPlayerEnts', deepClone(allPlayerEnts))
  const playerUnits = allPlayerEnts.filter((ent) => {
    return (
      isEntityUnit(ent) &&
      notInTaxi(ent) &&
      isEntityHasMovesLeft(ent) &&
      canUnitDirectAttackInGeneral(ent)
    )
  }).sort(byEntityResaleValueDesc)

  // console.log('playerUnits', deepClone(playerUnits))

  // const allEnemyUnitsEnts = engine.state.ents.filter((ent): ent is UnitEntityUnion => {
  //   return (ent.player_id && ent.player_id !== playerId && isEntityUnit(ent)) as boolean
  // }).sort(byEntityResaleValueDesc)
  // console.log('allEnemyUnitsEnts', deepClone(allEnemyUnitsEnts))

  // const hqEnt = allPlayerEnts.find(isEntityHQ)
  const { width, height } = engine.state

  async function innerFn(unit: UnitEntityUnion) {

    const entityGridXY = entityListToGridXY(engine.state.ents, width, height)

    const allEnemyUnitEnts = engine.state.ents.filter((ent): ent is UnitEntityUnion => {
      return (ent.player_id && ent.player_id !== playerId
              && isEntityUnit(ent) && notInTaxi(ent)) as boolean
    }).sort(byEntityResaleValueDesc)
    // console.log('allEnemyUnitEnts', deepClone(allEnemyUnitEnts))

    const proposedAttacksList = []
    for (const target of allEnemyUnitEnts) {
      if (getManhattanDistance(unit, target) <= (unit.mobility ?? 0) + 1) {
        const draftMove = createDraftMove(unit)
        const eptt = findMostEfficientPathToTileV2(entityGridXY, unit, width, height, target.x, target.y)
        if (eptt && hasLengthOne(eptt.turns)) {
          const { lastStep, targetStep } = eptt
          if (!lastStep?.impass && !lastStep?.occupant && targetStep?.enemies?.[0]) {
            const destPosition = lastStep ? toCoord(lastStep) : null
            const estimate = createDraftMoveAttackEstimate(engine, unit, destPosition || unit, target)
            if (estimate) {
              const { unitCba, targetCba } = estimate
              if ((unitCba ?? 0) + 100 > (targetCba ?? 0)) {

                draftMove.destPosition = destPosition
                draftMove.attackPosition = toCoord(target)
                draftMove.target = target

                proposedAttacksList.push({
                  draftMove,
                  cba: (unitCba ?? 0) - (targetCba ?? 0),
                })
              }
            }
          }
        }
      }
    }

    proposedAttacksList.sort(byCbaDesc)

    for (const { draftMove } of proposedAttacksList) {
      try {
        await dispatchClient(engine, createMoveUnitAction(draftMove))

        // stop attempting direct attacks
        return
      } catch (err1) {
        const err2 = new BotInvalidMove('Direct Attack', err1)
        console.error(err2)
        throwOnBotError(err2)
      }
    }
  }

  do {
    const unit = playerUnits.pop()
    if (!unit) {
      console.log('%cran out of units', 'color: grey; font-weight: lighter; font-size: 10px;')
      break
    }

    await innerFn(unit)

    await createDefaultWait(stepDelay)
  } while (botShouldContinue(engine, bot))
}