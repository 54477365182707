import { addBreadcrumb, captureException } from '@sentry/browser'
import { now } from '@sg/shared/src/lib/Date'
import { AxiosError } from 'axios'
import { isServer } from 'solid-js/web'
import { flatten, isValiError, parse } from 'valibot'
import { setAlertBagError } from '../../../../rx/store/create_alert_bag_store'
import { deepClone } from '../../../deep_clone'
import { Engine } from '../../engine/Engine.type'
import { EntityTypeId } from '../../entity/entity_type_id.enum'
import findEntityById from '../../entity/findEntityById'
import getEntitiesAtPosition from '../../entity/getEntitiesAtPosition'
import type { TilePositionXY } from '../../tile_position_xy/TilePositionXY.type'
import toCoord from '../../tile_position_xy/toCoord'
import { byLayerIdDesc } from '../../util/id'
import { StateSchema } from '../State.type'
import Action from './action/Action.type'
import { ActionType } from './action/ActionType'
import type { MoveUnitAction } from './action/Game/MoveUnitAction'
import getActionTypeText from './action/getActionTypeText'
import type ActionHandler from './ActionHandler.type'
import actionHandlersList, { type ActionSchemaUnion } from './actionHandlersList.generated'
import type { ActionLog } from './ActionLog.type'
import nextClientReferenceId from './nextClientReferenceId'
import postRemoteAction from './postRemoteAction'

// let lastClientDispatch: Promise<unknown> | null = null
export default async function dispatchClient(engine: Engine, action: Action): Promise<void> {
  console.log('%c[dispatchClient]', 'color: #BB6; font-weight: lighter; font-size: 10px;', getActionTypeText(action.type), {
    authPlayerId: engine.authPlayerId,
    // players: engine.state.players.map((p) => ({
    //   player_id: p.id,
    //   profile_id: p.profile_id,
    // })),
    action: deepClone(action),
  })
  if (action.type === ActionType.Game.MoveUnit) {
    const moveUnitAction = action as MoveUnitAction
    const unit = findEntityById(engine.state.ents, moveUnitAction.unit_id)
    if (unit) {
      console.log('%c[dispatchClient][MoveUnit]', 'color: #BB6; font-weight: lighter; font-size: 10px;',
        EntityTypeId[unit.etype_id], toCoord(unit))
    }
  }

  addBreadcrumb({
    category: 'action',
    level: 'info',
    data: {
      action,
    },
  })
  if (isServer) {
    throw new Error('dispatchClient is only available on the client')
  }
  const { state : { round, ended }, authPlayerId } = engine
  if (ended) {
    console.log('dispatchClient: game ended, ignoring action')
    return
  }
  const originalActionLogsLen = engine.actionLogs.length
  let actionLog: ActionLog | null = null
  try {
    // const handler = actionHandlersList.get(action.type)
    const tuple = actionHandlersList.find((t) => action.type === t[0])
    if (tuple) {
      // const actionType = tuple[0]
      const actionSchema: ActionSchemaUnion = tuple[1]
      const actionHandler: ActionHandler = tuple[2]
      const validatedAction = parse(actionSchema, action)
      // await actionHandler(engine, validatedAction)

      actionLog = {
        cref: nextClientReferenceId(engine.actionLogs, authPlayerId, round),
        round,
        player_id: authPlayerId,
        at: now(),
        action: validatedAction,
      }

      let remoteActionPromise: Promise<unknown> | null = null
      const onlineId = engine.state.online?.id
      if (onlineId) {
        remoteActionPromise = postRemoteAction(engine, validatedAction, onlineId, actionLog)
      }

      await actionHandler(engine, validatedAction, actionLog)

      engine.state = parse(StateSchema, engine.state)

      engine.actionLogs.push(actionLog)

      engine.prevState = deepClone(engine.state)

      await remoteActionPromise

      // engine.state.ents.forEach((ent) => {
      //   if (isEntityInfantry(ent)) {
      //     ent.hasError = true
      //   }
      // })
    } else {
      throw new Error(`Action ${action.type} is not supported`)
    }
  } catch (err) {
    const { toast, ab } = engine
    const msg: string = (((err as AxiosError).response?.data as { message?: string})?.message)
      || (err as { message?: string }).message
      || 'unknown'
    const context: Record<string, unknown> = {
      action,
    }
    if (isValiError(err)) {
      context.errors = flatten(err.issues)
    }
    const err2 = new Error(`Action Failed: ${msg}`, { cause: err })
    console.error(err, context)
    captureException(err, context)
    if (toast) {
      toast.error(msg)
    }
    if (ab) {
      setAlertBagError(ab, err2)
    }

    // restore original state
    engine.state = deepClone(engine.prevState)
    engine.actionLogs.length = originalActionLogsLen

    if (actionLog) {
      if (action.type === ActionType.Game.MoveUnit) {
        const moveUnitAction = action as MoveUnitAction
        const entIdKeys : Array<keyof MoveUnitAction> = [
          'unit_id',
          'target_id',
          'pickup_id',
          'taxi_id',
          'cargo_id',
          'merge_id',
        ] as const
        entIdKeys.forEach((key) => {
          if (moveUnitAction[key]) {
            const unit = findEntityById(engine.state.ents, moveUnitAction[key] as number)
            if (unit) {
              unit.hasError = true
            }
          }
        })
        const positionKeys : Array<keyof MoveUnitAction> = [
          'start_position',
          'dest_position',
          'attack_position',
          'unload_position',
        ] as const
        positionKeys.forEach((key) => {
          if (moveUnitAction[key]) {
            const entStack = getEntitiesAtPosition(engine.state.ents, moveUnitAction[key] as TilePositionXY)
            entStack.sort(byLayerIdDesc)
            if (entStack[0]) {
              entStack[0].hasError = true
            }
          }
        })
      }
    }
    throw err2
  }
}
