export default class BotInvalidMove extends Error {
    constructor(message?: string, cause?: unknown) {
        super(message);  // Pass message to the base Error class
        this.name = 'BotInvalidMove'  // Assign a custom error name

        // Fix the prototype chain (important for TypeScript inheritance)
        // Object.setPrototypeOf(this, Unexpected.prototype)

        // Optionally, you can also set the cause property
        if (cause) {
          this.cause = cause
        }
    }
}
