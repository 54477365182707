import { literal, object, type InferOutput } from 'valibot'
import { Engine } from '../../../../engine/Engine.type'
import type { ActionLog } from '../../ActionLog.type'
import { ActionType } from '../ActionType'


export const PublishMapActionSchema = object({
  type: literal(ActionType.Map.PublishMap),
})

export type PublishMapAction = InferOutput<typeof PublishMapActionSchema>

export function createPublishMapAction(): PublishMapAction {
  return {
    type: ActionType.Map.PublishMap,
  }
}

export async function handlePublishMapAction(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  engine: Engine,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: PublishMapAction,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  actionLog: ActionLog
): Promise<void> {
  // so basically the server does a special thing when it receives this action
}
