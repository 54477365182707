export interface HasTurns {
  turns: number
}

export function byTurnsDesc (a : HasTurns, b : HasTurns) {
  return b.turns - a.turns
}

export function byTurnsAsc (a : HasTurns, b : HasTurns) {
  return a.turns - b.turns
}