import { literal, object, pipe, rawCheck, type InferOutput } from 'valibot'
import { deepClone } from '../../../../../deep_clone'
import { Engine } from '../../../../engine/Engine.type'
import isPlayerSlotTaken from '../../../../player/isPlayerSlotTaken'
import type { Player } from '../../../../player/Player.type'
import { PlayerTurnStatus } from '../../../../player/PlayerTurnStatus'
import { StateSchema, type State } from '../../../State.type'
import { StateType } from '../../../state_type.enum'
import type { ActionLog } from '../../ActionLog.type'
import { ActionType } from '../ActionType'

export const StartNewGameActionSchema = object({
  type: literal(ActionType.Lobby.StartNewGame),
})

export type StartNewGameAction = InferOutput<typeof StartNewGameActionSchema>

export const StartNewGameStateSchema = pipe(
  StateSchema,
  rawCheck(({ dataset, addIssue }) => {
    if (dataset.typed) {
      const state: State = dataset.value
      const totalPlayers = state.players.length || 0
      const occupiedPlayerSlotsCount = state.players.filter(isPlayerSlotTaken).length || 0
      if (occupiedPlayerSlotsCount !== totalPlayers) {
        addIssue({
          message: `Waiting for all players to be ready [${occupiedPlayerSlotsCount}/${totalPlayers}]`,
        })
      }
    }
  })
)

export function createStartNewGameAction(): StartNewGameAction {
  return {
    type: ActionType.Lobby.StartNewGame,
  }
}

export async function handleStartNewGameAction(
  engine: Engine,
  action: StartNewGameAction,
  actionLog: ActionLog
): Promise<void> {
  engine.state.round = 1
  engine.state.type = StateType.Game

  engine.state.players.forEach((player: Player) => {
    player.turn_status = PlayerTurnStatus.Waiting
    player.money = engine.state.config.startIncome
  })
  const firstPlayer = engine.state.players[0]
  engine.state.turnPlayerId = firstPlayer.id
  console.log(`[StartTurnAction] firstPlayer(${firstPlayer.id}): firstPlayer.turn_status = PlayerTurnStatus.Pending`)
  firstPlayer.turn_status = PlayerTurnStatus.Pending

  actionLog.round = 0
  actionLog.state0 = deepClone(engine.state)
}
