import { literal, object, type InferOutput } from 'valibot'
import { Engine } from '../../../../engine/Engine.type'
import type { ActionLog } from '../../ActionLog.type'
import { ActionType } from '../ActionType'


export const UnpublishMapActionSchema = object({
  type: literal(ActionType.Map.UnpublishMap),
})

export type UnpublishMapAction = InferOutput<typeof UnpublishMapActionSchema>

export function createUnpublishMapAction(): UnpublishMapAction {
  return {
    type: ActionType.Map.UnpublishMap,
  }
}

export async function handleUnpublishMapAction(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  engine: Engine,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: UnpublishMapAction,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  actionLog: ActionLog
): Promise<void> {
  // so basically the server does a special thing when it receives this action
}
