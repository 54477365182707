import { trackDeep } from "@solid-primitives/deep"
import { Component, createEffect, createSignal, Match, onMount, Switch } from "solid-js"
import preventCursorSelectText from "../../lib/dom/event/preventCursorSelectText"
import viewportBreakpointMatches from "../../rx/shared/device/viewportBreakpointMatches"
import { OneOrZero } from "../../rx/signal/create_one_or_zero_signal"

const BreakpointCardWidget: Component = () => {
  let timeout: NodeJS.Timeout

  const [hidden, setHidden] = createSignal<OneOrZero>(0)

  createEffect(() => {
    // console.log('BreakpointCardWidget.createEffect.lastZoomAt', viewCtx.lastZoomAt)
    trackDeep(viewportBreakpointMatches)
    setHidden(0)
    clearTimeout(timeout)
    timeout = setTimeout(setHidden, 1000, 1)
  })

  onMount(() => {
    setTimeout(setHidden, 100, 0)
    setTimeout(setHidden, 1100, 1)
  })

  return <h4
    id="bpcw"
    ref={(elem) => {
      preventCursorSelectText(elem)
    }}
    class="widget widget-bpcw"
    classList={{
      'fade-out': hidden() as unknown as boolean,
      // 'd-none': hidden() as unknown as boolean,
    }}
  >
    <Switch fallback={<div>fallback</div>}>
      <Match when={viewportBreakpointMatches.xxl}>xxl</Match>
      <Match when={viewportBreakpointMatches.xl}>xl</Match>
      <Match when={viewportBreakpointMatches.lg}>lg</Match>
      <Match when={viewportBreakpointMatches.md}>md</Match>
      <Match when={viewportBreakpointMatches.sm}>sm</Match>
      <Match when={viewportBreakpointMatches.xs}>xs</Match>
    </Switch>
  </h4>
}

export default BreakpointCardWidget
