import { isoNow } from '@sg/shared/src/lib/Date'
import { FaSolidArrowRotateLeft, FaSolidNewspaper } from 'solid-icons/fa'
import { createMemo } from 'solid-js'
import { backendAxios } from '../../axios'
import { createPublishMapAction } from '../../lib/core/state/flux/action/Map/PublishMapAction'
import { createUnpublishMapAction } from '../../lib/core/state/flux/action/Map/UnpublishMapAction'
import { StateType } from '../../lib/core/state/state_type.enum'
import type { StateOnlineId } from '../../lib/core/state/StateOnlineId.type'
import type { StateTypeMeta } from '../../lib/core/state/StateTypeMeta.type'
import createDefaultWait from '../../lib/createDefaultWait'
import NotImplemented from '../../lib/Exception/NotImplemented.class'
import { createSessionSignal } from '../../rx/shared/session/createSessionSignal'
import { LoadingSignal, createLoadingSignal } from '../../rx/signal/create_loading_signal'
import { AlertBagStore, createAlertBagStore, setAlertBagError, setAlertBagSuccess } from '../../rx/store/create_alert_bag_store'
import { Nullable } from '../../typescript'
import LoadingIcon from '../LoadingIcon'
import { GetAlertBagSuccessMessageFunction, GetItemValueFunction, OnChangeFunction } from './onClickMutateTimestampFunctionFactory'

interface Publishable {
  id: StateOnlineId
  published_at: string | null,
}

interface Props {
  disabled: Nullable<boolean>,
  item: Publishable,
  st: StateTypeMeta,
  // ariaLabel: string,
  onChange: OnChangeFunction,
  ab: AlertBagStore,
}

const getItemValue: GetItemValueFunction = function (item: Publishable) {
  return item?.published_at
} as GetItemValueFunction

function getAction(item: Publishable, stateType: StateTypeMeta) {
  const isPublish = !getItemValue(item)
  // if (stateType.id === StateType.Lobby) {
  //   return isPublish ? createPublishLobbyAction() : createUnpublishLobbyAction()
  // }
  if (stateType.id === StateType.Map) {
    return isPublish ? createPublishMapAction() : createUnpublishMapAction()
  }
  // if (stateType.id === StateType.Game) {
  //   return isPublish ? createPublishGameAction() : createUnpublishGameAction()
  // }
  // if (stateType.id === StateType.Replay) {
  //   return isPublish ? createPublishReplayAction() : createUnpublishReplayAction()
  // }
  throw new NotImplemented()
}

const getAlertBagSuccessMessage: GetAlertBagSuccessMessageFunction = function (newValue: string | null, noun: string): Nullable<string> {
  return newValue ? `${noun} published.` : `${noun} unpublished.`
} as GetAlertBagSuccessMessageFunction

const PublishButton = (props: Props) => {
  const loading: LoadingSignal = createLoadingSignal()
  // eslint-disable-next-line solid/reactivity
  const ab = props.ab || createAlertBagStore()
  const session = createSessionSignal()[0]

  const noun = () => props.st.dname

  const ariaLabel = createMemo(() => {
    return props.item && (getItemValue(props.item) ? `Unpublish ${noun()}` : `Publish ${noun()}`)
  })

  return (<button
    class="btn btn-sm"
    classList={{
      'btn-warning': getItemValue(props.item) as unknown as boolean,
      'btn-primary': !getItemValue(props.item),
    }}
    disabled={!props.item?.id || props.disabled || loading()}
    aria-label={ariaLabel()}
    title={ariaLabel()}
    onClick={async () => {
      console.log('PublishButton.onClick')
      const wait = createDefaultWait()
      const itemId = props.item.id
      if (!itemId) {
        // throw new Error('!itemId')
        return
      }
      try {
        setAlertBagError(ab, null)
        const user_id = session()?.sub
        if (!user_id) {
          return
        }
        loading.start()

        const newTimeStampValue = getItemValue(props.item) ? null : isoNow()
        const action = getAction(props.item, props.st)

        await backendAxios.post(`/${props.st.api}/${itemId}`, {
          action,
          cref: 1,
        })

        const absm = getAlertBagSuccessMessage(newTimeStampValue, noun())
        setAlertBagSuccess(ab, absm)

        props.onChange(newTimeStampValue)
      } catch (error) {
        // console.error(error)
        setAlertBagError(ab, error as Error)
      } finally {
        await wait
        loading.end()
      }
    }}
  >
    {(loading() || !props.item)
      ? <LoadingIcon />
      : (getItemValue(props.item)
        ? <FaSolidArrowRotateLeft />
        : <FaSolidNewspaper />)}
    {(loading() || !props.item)
      ? (getItemValue(props.item)
        ? ' Unpublishing'
        : ' Publishing')
      : (getItemValue(props.item)
        ? ' Unpublish'
        : ' Publish')}
  </button>)
}

export default PublishButton