import { useNavigate } from '@solidjs/router'
import { createEffect, on, type Accessor } from 'solid-js'
import { StateType } from '../../lib/core/state/state_type.enum'
import type { StateOnlineId } from '../../lib/core/state/StateOnlineId.type'
import type { Nullable } from '../../typescript'

export default function createRedirectIfWrongStateTypeEffect(
  componentStateType: StateType,
  getStateType: Accessor<Nullable<StateType>>,
  stateId: Accessor<Nullable<StateOnlineId>>
): void {
  const navigate = useNavigate()

  createEffect(
    on(getStateType, (value: Nullable<StateType>) => {
      // console.log(
      //   'expectedStateType',
      //   expectedStateType,
      //   'actualStateType',
      //   actualStateType,
      // )
      // return
      if (!value) {
        // do nothing, probably loading
      } else if (componentStateType === value) {
        // do nothing, everything is awesome
      } else {
        const stateIdValue = stateId()
        if (!stateIdValue) {
          // do nothing, probably loading
        } else if (StateType.Map === value) {
          navigate(`/map?map_id=${stateIdValue}`)
        } else if (StateType.Lobby === value) {
          navigate(`/game/create?game_id=${stateIdValue}`)
        } else if (StateType.Game === value) {
          if (componentStateType === StateType.Replay) {
            // don't navigate, the game is probably replaying
          } else {
            navigate(`/game/play?game_id=${stateIdValue}`)
          }
        } else if (StateType.Replay === value) {
          if (componentStateType === StateType.Game) {
            // do nothing, the game probably just ended
          } else {
            navigate(`/replay?game_id=${stateIdValue}`)
          }
        }
      }
    })
  )
}
