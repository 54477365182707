import type { MyProfilePayload } from '@sg/backend/src/api/profile/handleGetMe'
import { PatchMyProfileBodySchema } from '@sg/backend/src/api/profile/handlePatchMe'
import { A } from '@solidjs/router'
import { FormGroup, FormLabel } from 'solid-bootstrap'
import { FaSolidFloppyDisk } from 'solid-icons/fa'
import { Component, createEffect, createMemo, createSignal, Show, type Accessor } from 'solid-js'
import { parse } from 'valibot'
import { backendAxios } from '../axios'
import Breadcrumbs from '../components/Breadcrumb/Breadcrumbs'
import EditAvatar from '../components/EditAvatar'
import Loader from '../components/Loader'
import SignInButton from '../components/SignInButton'
import toastError from '../lib/AlertBag/toastError'
import preventDefault from '../lib/dom/event/preventDefault'
import hasNonUndefinedProperty from '../lib/hasNonUndefinedProperty'
import onlyDirty from '../lib/only_dirty'
import { updateAuthProfileValue } from '../rx/shared/profile/authProfileValue'
import createAuthProfileSignal from '../rx/shared/profile/createAuthProfileSignal'
import { createLoadingSignal } from '../rx/signal/create_loading_signal'

const ProfilePage: Component = () => {
  const loading = createLoadingSignal()
  const [handle, setHandle] = createSignal<MyProfilePayload['handle']>(null)
  const [dname, setDname] = createSignal<MyProfilePayload['dname']>(null)
  const [email, setEmail] = createSignal<MyProfilePayload['email']>(null)

  const [authProfile] = createAuthProfileSignal()

  createEffect(() => {
    const p = authProfile()
    if (p) {
      setHandle(p.handle)
      setDname(p.dname)
      setEmail(p.email)
    }
  })

  const allFormData = createMemo(() => {
    return {
      handle: handle(),
      dname: dname(),
      email: email(),
    }
  })

  const dirtyData = createMemo(() => {
    const p = authProfile()
    return onlyDirty(allFormData(), p)
  })
  const isDirty = createMemo(() => {
    return hasNonUndefinedProperty(dirtyData())
  })

  // createEffect(() => {
  //   console.log('dirtyData', dirtyData())
  // })

  const updateProfile = async (e: Event) => {
    preventDefault(e)

    try {
      loading.start()
      const user = authProfile()
      if (user) {
        const payload = parse(PatchMyProfileBodySchema, {
          handle: handle(),
          dname: dname(),
          email: email(),
        })

        await backendAxios.post(`/profile/me`, payload)

        updateAuthProfileValue({
          ...authProfile(),
          ...payload as MyProfilePayload,
        })
      }
    } catch (error) {
      console.error(error)
      toastError(error)
    } finally {
      loading.end()
    }
  }

  return (
    <div class="container w-24-rem" style={{ "max-height": "80vh" }}>
      <Breadcrumbs>
        <A href="/auth/profile">Profile</A>
      </Breadcrumbs>
      <Show when={authProfile()}
        fallback={<FormGroup><SignInButton /> to edit your profile.</FormGroup>}
        children={(authProfile: Accessor<MyProfilePayload>) => {
          return <form onSubmit={updateProfile}>
            <FormGroup>
              <EditAvatar
                size={150}
                onUpload={(e: Event) => {
                  console.log('EditAvatar.onUpload', e)
                }}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Email</FormLabel>
              <input
                type="email"
                disabled
                class="form-control"
                value={authProfile()?.email || ''}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Handle</FormLabel>
              <input
                type="text"
                class="form-control"
                value={handle() || ''}
                onChange={(e) => setHandle(e.currentTarget.value)}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Display Name</FormLabel>
              <input
                type="text"
                class="form-control"
                value={dname() || ''}
                onInput={(e) => setDname(e.currentTarget.value)}
              />
            </FormGroup>
            <br />
            <FormGroup>
              <button type="submit" class="btn btn-primary" disabled={loading() || !isDirty()}>
                <FaSolidFloppyDisk />{' '}
                {loading() ? 'Saving ...' : 'Update Profile'}
              </button>
              <Loader signal={loading} />
            </FormGroup>
          </form>
        }}
      />
    </div>
  )
}

export default ProfilePage
