import { captureException } from "@sentry/browser"
import createDefaultWait from "../../../createDefaultWait"
import { deepClone } from "../../../deep_clone"
import BotInvalidMove from "../../../Exception/BotInvalidMove.class"
import createDraftMove from "../../draft_move/createDraftMove"
import createDraftMoveAttackEstimate from "../../draft_move/createDraftMoveAttackEstimate"
import type { DraftMove_AttackEstimate } from "../../draft_move/DraftMove_AttackEstimate.type"
import isValidWeaponEstimate from "../../draft_move/isValidWeaponEstimate"
import type { Engine } from "../../engine/Engine.type"
import canUnitRangedAttackInGeneral from "../../entity/canUnitRangedAttackInGeneral"
import { byEntityResaleValueDesc } from "../../entity/getEntityResaleValue"
import { byEntityMapControlPriorityDesc } from "../../entity/getMapControlPriority"
import getPlayerEntities from "../../entity/getPlayerEntities"
import isEntityHasMovesLeft from "../../entity/isEntityHasMovesLeft"
import isEntityUnit from "../../entity/isEntityUnit"
import notInTaxi from "../../entity/notInTaxi"
import type { UnitEntityUnion } from "../../entity/UnitEntityUnion.type"
import { createMoveUnitAction } from "../../state/flux/action/Game/MoveUnitAction"
import dispatchClient from "../../state/flux/dispatchClient"
import coord from "../../tile_position_xy/coord"
import createLazyEfficientPathGrid from "../../tile_position_xy/createLazyEfficientPathGrid"
import entityListToGridXY from "../../tile_position_xy/entityListToGridXY"
import canEndMoveOnStep from "../../tile_position_xy/findMostEfficientPathToTileV2/canEndMoveOnStep"
import getManhattanDistance from "../../tile_position_xy/getManhattanDistance"
import isPositionInBounds from "../../tile_position_xy/isPositionInBounds"
import { byTurnsAsc } from "../../tile_position_xy/sortByTurns"
import type { TilePositionXY } from "../../tile_position_xy/TilePositionXY.type"
import toCoord from "../../tile_position_xy/toCoord"
import { max, min } from "../../util/math"
import { byCbaDesc } from "../../util/sortByCba"
import type { Bot } from "../Bot.type"
import throwOnBotError from "../throwOnBotError"
import botShouldContinue from "./botShouldContinue"

/**
 * all unit that can attacks shall seek out enemies,
 * when no better options are available,
 * TODO camp outside factory to snipe new units
 * TODO avoid strolling into enemy attack range
 * TODO flee from approaching enemies
 * 
 * @param engine 
 * @param bot 
 */
export default async function botRangedAttackFind(
  engine: Engine,
  bot: Bot
) {
  console.log('%c[botRangedAttackFind]', 'color: grey; font-weight: lighter; font-size: 10px;')
  const { player, stepDelay } = bot
  const playerId = player.id
  const allPlayerEnts = getPlayerEntities(engine.state.ents, playerId)
  // console.log('allPlayerEnts', deepClone(allPlayerEnts))
  const playerUnits = allPlayerEnts.filter((ent) => {
    return (
      isEntityUnit(ent) &&
      isEntityHasMovesLeft(ent) &&
      notInTaxi(ent) &&
      canUnitRangedAttackInGeneral(ent)
    )
  }).sort(byEntityResaleValueDesc)
  console.log('playerUnits', deepClone(playerUnits))

  const { width, height } = engine.state

  async function innerFn(unit: typeof playerUnits[0]) {

    const entityGridXY = entityListToGridXY(engine.state.ents, width, height)

    const allApproachableEnts : Array<UnitEntityUnion> = engine.state.ents.filter((ent): ent is UnitEntityUnion => {
      if (ent.player_id === playerId) return false

      if (isEntityUnit(ent)) {
        return true
      }
      return false
    }).sort(byEntityMapControlPriorityDesc)
      .sort(byEntityResaleValueDesc)
    // console.log('allApproachableEnts', deepClone(allApproachableEnts))

    const maxRange: number = max(...unit.weapons.map(weapon => weapon.maxRange as number))
    const getEfficientPathToTile = createLazyEfficientPathGrid(width, height, entityGridXY, unit)

    type CampSite = TilePositionXY & {
      cba: number
      turns: number
      ent: UnitEntityUnion
      distance: number
    }

    const campsites = allApproachableEnts.reduce((carry : Array<CampSite>, targetEnt : UnitEntityUnion) => {
      const { x: x0, y: y0 } = targetEnt

      // console.log('targetEnt', toCoord(targetEnt), entityDisplayName(targetEnt))

      for (let x = max(0, x0 - maxRange); x <= min(width - 1, x0 + maxRange); x++) {
        for (let y = max(0, y0 - maxRange); y <= min(height - 1, y0 + maxRange); y++) {
          const destPosition = coord(x, y)
          if (!isPositionInBounds(destPosition, engine)) continue
          if (getManhattanDistance(destPosition, targetEnt) !== maxRange) continue
          const ae : DraftMove_AttackEstimate = createDraftMoveAttackEstimate(engine, unit, destPosition, targetEnt)
          const { targetCba, unitWeaponEstimate } = ae
          if (targetCba && unitWeaponEstimate && isValidWeaponEstimate(unitWeaponEstimate)) {
            const eptt = getEfficientPathToTile(destPosition.x, destPosition.y)
            if (eptt) {
              const firstTurn = eptt.turns[0]
              if (firstTurn) {
                const lastStep = firstTurn.steps.findLast(canEndMoveOnStep)
                if (lastStep) {
                  carry.push({
                    x: lastStep.x,
                    y: lastStep.y,
                    ent: targetEnt,
                    cba: targetCba,
                    turns: eptt.turns.length,
                    distance: getManhattanDistance(unit, targetEnt),
                  })
                }
              }
            }
          }
        }
      }
      return carry
    }, []).sort(byCbaDesc).sort(byTurnsAsc)
    // console.log('campsites', deepClone(campsites))

    // hope the first lastStep is not occupied
    let index = 1
    do {
      for (const campsite of campsites) {
        try {
          const draftMove = createDraftMove(unit)
          draftMove.destPosition = toCoord(campsite)
          await dispatchClient(engine, createMoveUnitAction(draftMove))

          // stop attempting to find a place to go
          return
        } catch (err1) {
          const err2 = new BotInvalidMove('Stroll', err1)
          console.error(err2)
          captureException(err2)
          throwOnBotError(err2)
        }
      }
    } while (index++ <= unit.mobility)
  }

  do {
    const unit = playerUnits.pop()
    if (!unit) {
      console.log('%cran out of units', 'color: grey; font-weight: lighter; font-size: 10px;')
      break
    }

    await innerFn(unit)

    await createDefaultWait(stepDelay)
  } while (botShouldContinue(engine, bot))
}