import PNG from '@sg/shared/src/three/texture/par.synt.grass.pack/ground_grass_gen_05.png'
import * as THREE from "three"

export default function getGrassTerrainNormalsTexture1() {
  // Load the terrain texture
  const textureLoader = new THREE.TextureLoader();
  const terrainTexture = textureLoader.load(PNG);
  terrainTexture.wrapS = THREE.RepeatWrapping; // Repeat the texture horizontally
  terrainTexture.wrapT = THREE.RepeatWrapping; // Repeat the texture vertically
  terrainTexture.repeat.set(10, 10); // Adjust this for more or less tiling
  return terrainTexture
}