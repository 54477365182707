// import AerialRocks04RoughJPG from '@sg/shared/src/three/texture/aerial_rocks_04_rough_512.jpg?url'
import { createNoise2D } from 'simplex-noise'
import * as THREE from "three"
import type { MountainEntity } from "../../core/entity/EntityType/Mountain"
import { abs, max, PI, sqrt, tanh } from '../../core/util/math'

const size = 10; // Grid tile size
const resolution = 32; // Number of subdivisions for detail

const noise2D = createNoise2D()

export default function createMountainPlane(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ent: MountainEntity
): THREE.Mesh {

  const geometry = new THREE.PlaneGeometry(size, size, resolution, resolution);

  // const simplex = new createNoise2D();
  // const maxHeight = 0.5; // Maximum mountain height
  const maxHeight = 10; // Maximum mountain height
  const centerX = 0;
  // const centerY = 0;
  const centerZ = 0;
  // console.log('geometry.attributes.position.array', geometry.attributes.position.array)

  const colors : Array<number> = [];

  geometry.attributes.position.array.forEach((_, index, array) => {
    if (index % 3 === 0) {
      const x = array[index];
      const y = array[index + 1];
      // const z = array[index + 2];

      // console.log('abs(x)', abs(x), 'abs(y)', abs(y))
      if (abs(x) > 4|| abs(y) > 4) {
        colors.push(0.2, 0.5, 0.2);
        return
      }

      // Calculate distance from the center for radial displacement
      const distanceFromCenter = 1.2 * sqrt((x - centerX) ** 2 + (y - centerZ) ** 2) / (size / 2);
      // console.log('x - centerX', x - centerX)
      // console.log('distanceFromCenter', distanceFromCenter)

      // Displace height based on distance and noise
      const noiseValueA = noise2D(x, y); // Adjust frequency
      // console.log('noiseValueA', noiseValueA)
      // const noiseValueB = getPerlinNoise2D(x, y, tmpRng)
      // console.log('noiseValueB', noiseValueB)
      // const noiseValue = 10
      // const noiseValue = distanceFromCenter
      // const height = max(0, maxHeight * (1 - distanceFromCenter) + noiseValue * 0.2); // Combine radial and noise
      // const height = max(0, maxHeight * (1 - distanceFromCenter) + noiseValue * 1); // Combine radial and noise
      // console.log(height)
      const height = max(0, (10 * (0.9 - tanh(distanceFromCenter ** 2))) + noiseValueA)

      // console.log('x, z', x, z)
      // console.log(x, z, array[index + 2], height, distanceFromCenter)
      array[index + 2] = height; // Set the y-coordinate (height)

      // Assign colors based on height
      if (height > (0.7 * maxHeight)) {
        // Snow at the top
        colors.push(1, 1, 1); // White
      } else if (height > (0.35 * maxHeight)) {
        // Rocky mid-section
        colors.push(0.5, 0.3, 0.2); // Brown
      } else {
        // Grass at the base
        colors.push(0.2, 0.5, 0.2); // Green
      }
    }
  });

  geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));
  geometry.attributes.position.needsUpdate = true;
  geometry.computeVertexNormals(); // Recalculate normals for proper lighting
  
  const material = new THREE.MeshStandardMaterial({
    vertexColors: true,
    // color: 0xffffff, // Pink for debugging
    // color: 0x964B00, // Brown for dirt
    flatShading: false,
    // wireframe: true,
    // roughness: 0.1,
    // metalness: 0.1,
    side: THREE.FrontSide,
    // roughness: 0.1,
    // roughnessMap: new THREE.TextureLoader().load(AerialRocks04RoughJPG),
    // displacementMap: new THREE.TextureLoader().load(AerialRocks04RoughJPG),
    // map: new THREE.TextureLoader().load(AerialRocks04RoughJPG),
    // displacementMap: getGrassTerrainNormalsTexture1(),
    // displacementMap
  });
  const mountain = new THREE.Mesh(geometry, material)
  mountain.rotateX(-PI / 2) // Rotate to align with the grid
  // geometry.scale(0.25, 0.25, 0.25)
  // mountain.scale.z = 1
  mountain.scale.z = 0.75
  // mountain.position.z = 100
  return mountain
}
