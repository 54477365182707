import type { Entity } from '../entity/index'
import type { EntityGridXY } from './EntityGridXY.type'
import createLazyReadOnlyGridXY from './createLazyReadOnlyGridXY'

type OccupantValueOrNull = Entity | null

// an occupant is a unit that occupies a position {x, y}
// at the same layer as the current unit
export default function createLazyOccupantValueGrid(
  // full state width
  gridWidth: number,
  // full state height
  gridHeight: number,
  // full state ents as GridXY
  grid: EntityGridXY,
  // unit attempting to move
  unit: Entity
) {
  const lazyGrid = createLazyReadOnlyGridXY<OccupantValueOrNull>(
    gridWidth,
    gridHeight,
    function occupantValueGridGetter(x: number, y: number): OccupantValueOrNull {
      const positionStack = grid?.[x]?.[y]
      if (positionStack) {
        for (let index = 0; index < positionStack.length; index++) {
          const ent = positionStack[index]
          if (ent.taxi_id) {
            continue
          } else if (ent.layer_id === unit.layer_id) {
            return ent
          }
        }
      }
      return null
    }
  )
  return lazyGrid
}
