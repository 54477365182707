import removeItem from "../../../array/remove_item"
import createDefaultWait from "../../../createDefaultWait"
import BotInvalidMove from "../../../Exception/BotInvalidMove.class"
import hasLengthOne from "../../../ldsh/hasLengthOne"
import createDraftMove from "../../draft_move/createDraftMove"
import type { DraftMove } from "../../draft_move/DraftMove.type"
import type { Engine } from "../../engine/Engine.type"
import { byEntityResaleValueAsc } from "../../entity/getEntityResaleValue"
import getPlayerEntities from "../../entity/getPlayerEntities"
import getUnitDefenseAtPosition from "../../entity/getUnitDefenseAtPosition"
import type { Entity } from "../../entity/index"
import isEntityAtFullHp from "../../entity/isEntityAtFullHp"
import isEntityHasMovesLeft from "../../entity/isEntityHasMovesLeft"
import isEntityUnit from "../../entity/isEntityUnit"
import notInTaxi from "../../entity/notInTaxi"
import { createMoveUnitAction } from "../../state/flux/action/Game/MoveUnitAction"
import dispatchClient from "../../state/flux/dispatchClient"
import entityListToGridXY from "../../tile_position_xy/entityListToGridXY"
import findMostEfficientPathToTileV2 from "../../tile_position_xy/findMostEfficientPathToTileV2/findMostEfficientPathToTileV2"
import getEntityGridXYStack from "../../tile_position_xy/getEntityGridXYStack"
import getManhattanDistance from "../../tile_position_xy/getManhattanDistance"
import toCoord from "../../tile_position_xy/toCoord"
import { abs } from "../../util/math"
import { byCbaDesc } from "../../util/sortByCba"
import type { Bot } from "../Bot.type"
import throwOnBotError from "../throwOnBotError"
import botShouldContinue from "./botShouldContinue"

type DraftMoveWithCba = { draftMove: DraftMove, cba: number}

export default async function botMergeUnits(
  engine: Engine,
  bot: Bot
) {
  console.log('%c[botMergeUnits]', 'color: grey; font-weight: lighter; font-size: 10px;')
  const { player, stepDelay } = bot
  const playerId = player.id
  const allPlayerEnts = getPlayerEntities(engine.state.ents, playerId)
  // console.log('allPlayerEnts', deepClone(allPlayerEnts))
  const playerUnits = allPlayerEnts.filter((ent : Entity) => {
    return isEntityUnit(ent) && notInTaxi(ent) && !isEntityAtFullHp(ent)
  }).sort(byEntityResaleValueAsc)
  // console.log('playerUnits', deepClone(playerUnits))

  // const allEnemyUnitsEnts = engine.state.ents.filter((ent): ent is UnitEntityUnion => {
  //   return (ent.player_id && ent.player_id !== playerId && isEntityUnit(ent)) as boolean
  // }).sort(byEntityResaleValueDesc)
  // console.log('allEnemyUnitsEnts', deepClone(allEnemyUnitsEnts))

  // const hqEnt = allPlayerEnts.find(isEntityHQ)
  const { width, height } = engine.state

  do {
    const unit = playerUnits.pop()
    if (!unit) {
      return
    }

    if (isEntityHasMovesLeft(unit)) {
      const grid = entityListToGridXY(engine.state.ents, width, height)
      const unitDefense = getUnitDefenseAtPosition(unit, getEntityGridXYStack(grid, unit.x, unit.y))
      const mergablesList : Array<DraftMoveWithCba> = playerUnits.reduce((carry, entity: Entity) => {
        if (unit.id === entity.id) {
          // cant merge with self
        } else if (unit.etype_id === entity.etype_id && getManhattanDistance(unit, entity) <= unit.mobility) {
          const sumHp = unit.hp + entity.hp
          if (sumHp <= 12) {
            const eptt = findMostEfficientPathToTileV2(
              grid, unit, width, height, entity.x, entity.y)
            if (eptt && hasLengthOne(eptt.turns)) {
              const { lastStep } = eptt
              if (!lastStep) {
                // no step
              } else if (lastStep.impass || lastStep.occupant || lastStep.enemies[0]) {
                // blocked
              } else if (lastStep.merge?.id === entity.id) {
                const draftMove = createDraftMove(unit)
                draftMove.merge = entity
                draftMove.destPosition = toCoord(entity)

                // if we can merge with this entity, and it has moves left,
                // and it has a better defense than us, merge with it
                if (isEntityHasMovesLeft(entity)) {
                  const entDefense = getUnitDefenseAtPosition(unit, getEntityGridXYStack(grid, entity.x, entity.y))
                  if (unitDefense < entDefense) {
                    draftMove.unit = entity
                    draftMove.merge = unit
                    draftMove.destPosition = toCoord(unit)
                  }
                }

                // the closest we can get to 10 without overflow is best
                const cba = abs(10 - sumHp) - (sumHp > 10 ? (sumHp - 10) : 0)

                carry.push({ draftMove, cba })
              }
            }
          }
        }
        return carry
      }, [] as Array<DraftMoveWithCba>)

      mergablesList.sort(byCbaDesc)

      for (const { draftMove } of mergablesList) {
        try {
          await dispatchClient(engine, createMoveUnitAction(draftMove))

          removeItem(playerUnits, draftMove.unit)

          await createDefaultWait(stepDelay)

          // stop attempting merges with this unit
          break
        } catch (err1) {
          const err2 = new BotInvalidMove('Merge', err1)
          console.error(err2)
          throwOnBotError(err2)
        }
      }
    }
  } while (botShouldContinue(engine, bot))
}