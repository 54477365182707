import { InferOutput, check, fallback, nullable, object, optional, pipe } from 'valibot'
import isSubsetOf from '../../ldsh/Set.isSubsetOf'
import { OptionalLiteralTrueSchema } from '../LiteralTrue'
import { HasPlayerId } from '../player/has_player_id'
import { PlayerId, PlayerIdSchema } from '../player/PlayerId'
import { RNGConfigSchema } from '../rng/RNGConfig.type'
import { EntityListSchema } from './EntityList.type'
import { PlayerListSchema } from './PlayerList.type'
import { RoundNumberSchema } from './RoundNumber'
import { StateConfigSchema } from './state_config'
import { StateOnlineSchema } from './state_online.type'
import { StateTypeSchema } from './state_type.enum'
import { StateHeightSchema } from './StateHeight.type'
import { StateMapSchema } from './StateMap.type'
import { StateWidthSchema } from './StateWidth.type'

export const StateSchema = pipe(
  object({
    round: fallback(RoundNumberSchema, 1),
    online: optional(nullable(StateOnlineSchema)), // online is optional and can be null
    config: StateConfigSchema,
    map: StateMapSchema,
    type: StateTypeSchema,
    width: StateWidthSchema,
    height: StateHeightSchema,
    ents: EntityListSchema,
    players: PlayerListSchema,
    turnPlayerId: optional(nullable(PlayerIdSchema)), // Assuming PlayerId is a string
    rng: RNGConfigSchema,
    ended: OptionalLiteralTrueSchema,
  }),
  check((state): boolean => {
    const playerIdSet: Set<PlayerId> = state.players.reduce(
      (acc, player) => (acc.add(player.id), acc),
      new Set<PlayerId>()
    )
    const playerIdFromEntitySet: Set<PlayerId> = state.ents.reduce((acc, ent) => {
      const playerId = (ent as HasPlayerId)?.player_id as number
      if (playerId > 0) {
        acc.add(playerId)
      }
      return acc
    }, new Set<PlayerId>())
    if (!isSubsetOf(playerIdFromEntitySet, playerIdSet)) {
      return false
    }
    return true
  }, 'Entities must have valid player ids.')
)

export type State = InferOutput<typeof StateSchema>

export const NullableStateSchema = nullable(StateSchema)

export type NullableState = InferOutput<typeof NullableStateSchema>

export const OptionalNullableStateSchema = optional(StateSchema)

export type OptionalNullableState = InferOutput<typeof OptionalNullableStateSchema>