import { literal, object, type InferOutput } from 'valibot'
import Unexpected from '../../../../../Exception/Unexpected.class'
import { Engine } from '../../../../engine/Engine.type'
import { StateType } from '../../../state_type.enum'
import type { ActionLog } from '../../ActionLog.type'
import { ActionType } from '../ActionType'

export const EndGameActionSchema = object({
  type: literal(ActionType.Game.EndGame),
})

export type EndGameAction = InferOutput<typeof EndGameActionSchema>

export function createEndGameAction(): EndGameAction {
  return {
    type: ActionType.Game.EndGame,
  }
}

export async function handleEndGameAction(
  engine: Engine,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: EndGameAction,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  actionLog: ActionLog
): Promise<void> {
  const { state } = engine
  if (!state.ended) {
    throw new Unexpected('game not ended')
  }

  state.type = StateType.Replay
}
