import { modifyMutable, produce } from 'solid-js/store'
import calculateMapWidthHeightPxTileSize from '../calculateMapWidthHeightPx'
import calculateTileSize from '../calculateTileSize'
import { Engine } from '../engine/Engine.type'
import { floor, min } from '../util/math'
import { BASE_TILE_SIZE } from '../view_ctx'
import { centerPan } from './centerPan'

/**
 * The map shall be centered,
 * the zoom level shall be adjusted to fit the entire map on the screen.
 *
 * @param {Engine} engine - The game engine object containing the `viewCtx` and state properties.
 */
export default function recenterPanZoomViewport(engine: Engine): void {
  modifyMutable(
    engine,
    produce((engine: Engine) => {

      const { viewCtx } = engine
      viewCtx.zoom = 100
      // viewCtx.tile_size = BASE_TILE_SIZE
      viewCtx.tile_size = calculateTileSize(viewCtx)
      calculateMapWidthHeightPxTileSize(engine)

      {
        const { viewCtx } = engine

        const { renderWidthPx, renderHeightPx, tile_size } = viewCtx
 
        const zoomByWidth  = (renderWidthPx / tile_size)  / BASE_TILE_SIZE
        const zoomByHeight = (renderHeightPx / tile_size) / BASE_TILE_SIZE

        viewCtx.zoom = floor(145 * min(zoomByWidth, zoomByHeight))
        // viewCtx.zoom = 195
      }

      engine.viewCtx.tile_size = calculateTileSize(engine.viewCtx)
      calculateMapWidthHeightPxTileSize(engine)

      centerPan(engine)
    })
  )
}
