import { literal, object, optional, type InferOutput } from 'valibot'
import { Engine } from '../../../../engine/Engine.type'
import { assign } from '../../../../util/Object'
import { StateMapSchema } from '../../../StateMap.type'
import type { ActionLog } from '../../ActionLog.type'
import { ActionType } from '../ActionType'

export const PatchMapActionDataSchema = object({
  dname: optional(StateMapSchema.entries.dname),
  bio: optional(StateMapSchema.entries.bio),
})

export type PatchMapActionData = InferOutput<typeof PatchMapActionDataSchema>

export const PatchMapActionSchema = object({
  type: literal(ActionType.Map.PatchMap),
  data: PatchMapActionDataSchema,
})

export type PatchMapAction = InferOutput<typeof PatchMapActionSchema>

export function createPatchMapAction(data: PatchMapActionData): PatchMapAction {
  return {
    type: ActionType.Map.PatchMap,
    data,
  }
}

export async function handlePatchMapAction(
  engine: Engine,
  action: PatchMapAction,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  actionLog: ActionLog
): Promise<void> {
  assign(engine.state.map, action.data)
}
