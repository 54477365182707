import { HasLayerId } from '../entity/entity_layer_id.enum'
import { HasEntityTypeId } from '../entity/entity_type_id.enum'
import { randomAZaz09 } from './rand'

export function randomUid(): string {
  return randomAZaz09(32)
}

export interface HasId {
  id: number
}

export function byIdAsc(a: HasId, b: HasId) {
  return a.id - b.id
}
export function byIdDesc(a: HasId, b: HasId) {
  return b.id - a.id
}

export function byEntityTypeIdAsc(a: HasEntityTypeId, b: HasEntityTypeId) {
  return a.etype_id - b.etype_id
}
export function byEntityTypeIdDesc(a: HasEntityTypeId, b: HasEntityTypeId) {
  return b.etype_id - a.etype_id
}

export function byLayerIdAsc(a: HasLayerId, b: HasLayerId) {
  return a.layer_id - b.layer_id
}
export function byLayerIdDesc(a: HasLayerId, b: HasLayerId) {
  return b.layer_id - a.layer_id
}
