import { captureException } from "@sentry/browser"
import createDefaultWait from "../../../createDefaultWait"
import BotInvalidMove from "../../../Exception/BotInvalidMove.class"
import hasLengthOne from "../../../ldsh/hasLengthOne"
import createDraftMove from "../../draft_move/createDraftMove"
import type { Engine } from "../../engine/Engine.type"
import type { AnnexableEntityUnion } from "../../entity/AnnexableEntityUnion.type"
import type { InfantryEntity } from "../../entity/EntityType/Infantry"
import { isEntityInfantry } from "../../entity/entityTypeMetaList.generated"
import { byEntityResaleValueDesc } from "../../entity/getEntityResaleValue"
import { byEntityMapControlPriorityDesc } from "../../entity/getMapControlPriority"
import getPlayerEntities from "../../entity/getPlayerEntities"
import isBeingAnnexed from "../../entity/isBeingAnnexed"
import isEntityAnnexable from "../../entity/isEntityAnnexable"
import isEntityHasMovesLeft from "../../entity/isEntityHasMovesLeft"
import notInTaxi from "../../entity/notInTaxi"
import { createMoveUnitAction } from "../../state/flux/action/Game/MoveUnitAction"
import dispatchClient from "../../state/flux/dispatchClient"
import entityListToGridXY from "../../tile_position_xy/entityListToGridXY"
import findMostEfficientPathToTileV2 from "../../tile_position_xy/findMostEfficientPathToTileV2/findMostEfficientPathToTileV2"
import getManhattanDistance from "../../tile_position_xy/getManhattanDistance"
import toCoord from "../../tile_position_xy/toCoord"
import type { Bot } from "../Bot.type"
import throwOnBotError from "../throwOnBotError"
import botShouldContinue from "./botShouldContinue"

export default async function botAnnexesNew(
  engine: Engine,
  bot: Bot
) {
  console.log('%c[botAnnexesNew]', 'color: grey; font-weight: lighter; font-size: 10px;')
  const { player, stepDelay } = bot
  const playerId = player.id
  const allPlayerEnts = getPlayerEntities(engine.state.ents, playerId)
  // console.log('allPlayerEnts', deepClone(allPlayerEnts))
  const playerInfantryUnits = allPlayerEnts.filter((ent): ent is InfantryEntity => {
    return (
      isEntityInfantry(ent) &&
      isEntityHasMovesLeft(ent) &&
      notInTaxi(ent)
    )
  }).sort(byEntityResaleValueDesc)
  // console.log('playerInfantryUnits', deepClone(playerInfantryUnits))

  const { width, height } = engine.state

  async function innerFn(unit: InfantryEntity) {

    const entityGridXY = entityListToGridXY(engine.state.ents, width, height)

    if (isEntityInfantry(unit)) {
      const allAnnexableEnts = engine.state.ents.filter((ent): ent is AnnexableEntityUnion => {
        return (ent.player_id !== playerId
            && isEntityAnnexable(ent)
            && !isBeingAnnexed(ent)
        ) as boolean
      }).sort(byEntityMapControlPriorityDesc)
      // console.log('allAnnexableEnts', deepClone(allAnnexableEnts))

      for (const annexableEnt of allAnnexableEnts) {
        if (getManhattanDistance(unit, annexableEnt) <= unit.mobility) {
          const eptt = findMostEfficientPathToTileV2(entityGridXY, unit, width, height, annexableEnt.x, annexableEnt.y)
          if (eptt && hasLengthOne(eptt.turns)) {
            const { lastStep } = eptt
            if (!lastStep) {
              // no step
            } else if (lastStep.impass || lastStep.occupant || lastStep.enemies[0]) {
              // blocked
            } else if (lastStep.annex?.[0]) {
              try {
                const draftMove = createDraftMove(unit)
                draftMove.destPosition = toCoord(annexableEnt)
                draftMove.annex = true
                await dispatchClient(engine, createMoveUnitAction(draftMove))

                // stop attempting annexations
                return
              } catch (err1) {
                const err2 = new BotInvalidMove('AnnexNew', err1)
                console.error(err2)
                captureException(err2)
                throwOnBotError(err2)
              }
            }
          }
        }
      }
    }
  }

  let index = 0
  do {
    const unit = playerInfantryUnits[index++]
    if (!unit) {
      console.log('%cran out of units', 'color: grey; font-weight: lighter; font-size: 10px;')
      break
    }

    await innerFn(unit)

    await createDefaultWait(stepDelay)
  } while (botShouldContinue(engine, bot))
}