import { FormGroup, FormLabel } from 'solid-bootstrap'
import { FaSolidDice } from 'solid-icons/fa'
import { Component } from 'solid-js'
import preventDefault from '../../../dom/event/preventDefault'
import { MAP_SEED_MAX_SIZE, MAP_SEED_MIN_SIZE, isInvalidSeedValue, randomMapSeed } from '../../rng/map_seed'
import { MapScript_FormData } from './FormData/FormData.type'

type Props = {
  value: MapScript_FormData
  onChange: (formData: MapScript_FormData) => void
}

const MapScriptForm: Component<Props> = (props) => {

  function update<T extends keyof MapScript_FormData>(key: T, value: MapScript_FormData[typeof key]) {
    props.onChange({
      ...props.value,
      [key]: value,
    })
  }

  return (<div class="map-script-form">
    {('seed' in props.value) && <FormGroup>
      <FormLabel class="w-25">Seed</FormLabel>
      <div class="input-group d-inline-flex w-75">
        <input class="form-control text-end" classList={{
          'is-invalid': isInvalidSeedValue(props.value.seed),
        }}
          name="seed" type="number" step={1}
          value={props.value.seed}
          onInput={(event: InputEvent) => {
            const target = (event.target as HTMLInputElement)
            const { value } = target
            update('seed', parseInt(value, 10))
            // update('seed', 'dongkey')
          }}
          placeholder={`From ${MAP_SEED_MIN_SIZE} to ${MAP_SEED_MAX_SIZE}`} min={MAP_SEED_MIN_SIZE} max={MAP_SEED_MAX_SIZE}
        />
        <span class="input-group-text"
          aria-label="Reroll seed"
          onClick={(event: MouseEvent) => {
            preventDefault(event)
            // update('seed', 0)
            update('seed', randomMapSeed())
          }}
        >
          <FaSolidDice />
        </span>
      </div>
    </FormGroup>}

  </div>)
}

export default MapScriptForm
