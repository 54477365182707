import { A } from '@solidjs/router'
import { Modal } from 'solid-bootstrap'
import { Component, createEffect, createSignal, For, Match, Show, Switch, useContext, type Accessor } from 'solid-js'
import type { Engine } from '../lib/core/engine/Engine.type'
import { createInfantryEntity } from '../lib/core/entity/EntityType/Infantry'
import playerDisplayName from '../lib/core/player/playerDisplayName'
import { PlayerGameStatus } from '../lib/core/player/PlayerGameStatus'
import type { StateMap } from '../lib/core/state/StateMap.type'
import { createAuthPlayerMemo } from '../rx/signal/createAuthPlayerSignal'
import PlayerGameStatusBadge from './Badge/PlayerGameStatusBadge'
import EngineContext from './EngineContext'
import SingleSpriteIcon from './MapEditor/SingleSpriteIcon'

const GameEndedModal: Component = () => {
  const engine: Engine = useContext(EngineContext)

  const [isModalOpen, setIsModalOpen] = createSignal<boolean>(false)

  const closeMenu = () => setIsModalOpen(false)

  createEffect(() => {
    if (engine.state.ended) {
      setIsModalOpen(true)
    }
  })

  const authPlayer = createAuthPlayerMemo(engine)

  return (
    <Modal show={isModalOpen()} onHide={closeMenu} centered>
      <Modal.Body>
        {(console.log('rendered'), null)}
        <div class="float-end text-muted" onClick={closeMenu}>x</div>
        <h3 class="text-center w-100">Game Ended</h3>
        <Switch>
          <Match when={authPlayer()?.game_status === PlayerGameStatus.Won}>
            <h1 class="text-center">You Won!</h1>
          </Match>
          <Match when={authPlayer()?.game_status === PlayerGameStatus.Forfeited}>
            <h2 class="text-center">You Forfeited!</h2>
          </Match>
          <Match when={authPlayer()?.game_status === PlayerGameStatus.Lost}>
            <h2 class="text-center">You Lost!</h2>
          </Match>
          <Match when={authPlayer()?.game_status === PlayerGameStatus.Resigned}>
            <h2 class="text-center">You Resigned!</h2>
          </Match>
        </Switch>
        <Show when={authPlayer()} children={<hr />} />
        <table class="table table-sm">
          <tbody>
            <For each={engine.state.players} children={(player) => {
              return <tr>
                <td>
                  <SingleSpriteIcon
                    entity={createInfantryEntity(1, 0, 0, player.id)}
                    noBg
                  />
                </td>
                <td>
                  {playerDisplayName(player)}
                </td>
                <td>
                  <PlayerGameStatusBadge player={player} />
                </td>
              </tr>
            }} />
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Show when={!engine.state.online?.id}
          children={<>TODO local game replay?</>}
        />
        <Show when={!engine.replay && engine.state.online?.id}
          children={(onlineId: Accessor<number>) => (
            <A href={`/game/replay?game_id=${onlineId()}`} class="btn btn-primary">Watch Replay</A>
          )}
        />
        <Show when={engine.state.map.id ? engine.state.map : null}
          children={(map: Accessor<StateMap>) => (
            <A href={`/map?map_id=${map().id}`} class="btn btn-primary">Visit Map</A>
          )}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default GameEndedModal
