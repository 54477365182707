import { Component, Show, type JSXElement } from 'solid-js'
import type { AnnexableEntityUnion } from '../lib/core/entity/AnnexableEntityUnion.type'
import type { BaseEntity } from '../lib/core/entity/BaseEntity'
import type { Entity } from '../lib/core/entity/index'
import type { HasHP } from '../lib/core/has_hp'
import { ActionType } from '../lib/core/state/flux/action/ActionType'
import type { MoveUnitAction } from '../lib/core/state/flux/action/Game/MoveUnitAction'
import type { ActionLog } from '../lib/core/state/flux/ActionLog.type'
import isOrthogonallyAdjacent from '../lib/core/tile_position_xy/isOrthogonallyAdjacent'
import toFloorMin0 from '../lib/core/util/toFloorMin0'
import type { Nullable } from '../typescript'
import SingleSpriteIcon from './MapEditor/SingleSpriteIcon'

interface Props {
  value: ActionLog
}

/* eslint-disable solid/reactivity */
/* eslint-disable solid/components-return-once */

const ActionLogDescription: Component<Props> = (props) => {
  // const engine : Engine = useContext(EngineContext)
  const { value: log } = props
  const { action } = log
  const { type: actionType } = action

  if (actionType === ActionType.Game.MoveUnit) {
    const {
      // round,
      // cref,
      // at,
      // player_id,
      action,
      unit0,
      unit1,
      target0,
      target1,
      // destPositionSteps,
      annexed0,
      annexed1,
      cargo0,
      // cargo1,
      taxi0,
      // taxi1,
      merge0,
      merge1,
    } = log

    const {
      merge_id,
      cargo_id,
      taxi_id,
      pickup_id,
      unload_position,
      dest_position: destPosition,
    } = (action as MoveUnitAction)
    if (annexed1?.annexed) {
      return <>
        {entSprite(unit0, 'Unit')}
        {' Annexed '}
        {entSprite(annexed0, 'Annexable')}
        →
        {entSprite(annexed1, 'Annexable')}
      </>
    } else if (annexed0) {
      return <>
        {entSprite(unit0, 'Unit')}
        {` Annexing `}
        {entSprite(annexed0, 'Annexable')}
        {diffOrNull((annexed0 as AnnexableEntityUnion).ap, (annexed1 as AnnexableEntityUnion).ap)}
      </>
    } else if (merge_id) {
      return <>
        {entSprite(unit0, 'Unit')}
        {' Merged Into '}
        {entSprite(merge0, 'Unit')}
        {diffOrNull((merge0 as HasHP).hp, (merge1 as HasHP).hp)}
      </>
    } else if (unload_position && cargo_id) {
      return <>
        {entSprite(unit0, 'Unit')}
        {' Unloaded '}
        {entSprite(cargo0, 'Cargo')}
      </>
    } else if (taxi_id) {
      return <>
        {entSprite(unit0, 'Unit')}
        {' Loaded Into '}
        {entSprite(taxi0, 'Taxi')}
      </>
    } else if (pickup_id) {
      return <>
        {entSprite(unit0, 'Unit')}
        {' Picked Up '}
        {entSprite(cargo0, 'Cargo')}
      </>
    } else if (target1?.destroyed) {
      return <>
        {entSprite(unit0, 'Unit')}
        {diffOrNull((unit0 as HasHP).hp, (unit1 as HasHP).hp)}
        {' Destroyed '}
        {entSprite(target0, 'Unit')}
      </>
    } else if (target0 && unit0) {
      const isBombard = isOrthogonallyAdjacent(destPosition || unit0, target0)
      if (isBombard) {
        console.log('target0.hp', target0.hp, 'target1.hp', target1?.hp)
      }
      return <>
        {entSprite(unit0, 'Unit')}
        {diffOrNull((unit0 as HasHP).hp, (unit1 as HasHP).hp)}
        {unit1?.destroyed && '💀'}
        {isBombard ? ' Attacked ' : ' Bombarded '}
        {entSprite(target0, 'Unit')}
        {diffOrNull((target0 as HasHP).hp, (target1 as HasHP).hp)}
        {target1?.destroyed && '💀'}
      </>
    } else {
      return <>
        {'Move '}{entSprite(unit0, 'Unit')}
      </>
    }
  }
  if (actionType === ActionType.Game.PlaceFactoryOrder) {
    return <>
      {entSprite(log.factory0, 'Factory')}
      {' ordered to produce '}
      {entSprite(log.unit0, 'Unit')}
    </>
  }
  if (actionType === ActionType.Game.CancelFactoryOrder) {
    return <>
      {entSprite(log.factory0, 'Factory')}
      {' canceled order '}
      {entSprite(log.unit0, 'Unit')}
    </>
  }
  if (actionType === ActionType.Game.StartTurn) {
    return 'Start Turn'
  }
  if (actionType === ActionType.Game.EndTurn) {
    return 'End Turn'
  }
  if (actionType === ActionType.Game.StartRound) {
    return 'Start Round'
  }
  if (actionType === ActionType.Game.EndRound) {
    return 'End Round'
  }
  if (actionType === ActionType.Lobby.StartNewGame) {
    return 'Game Started'
  }
  return 'Other'
}

export default ActionLogDescription

function entSprite(unit: Nullable<Entity>, fallback: JSXElement) {
  return <Show when={unit}
    children={(unit) => {
      return <SingleSpriteIcon entity={withoutBuiltThisTurn(unit())} noBg />
    }}
    fallback={fallback}
  />
}

function withoutBuiltThisTurn(unit: Entity) {
  if ((unit as BaseEntity).builtThisTurn) {
    unit = {
      ...unit,
    }
    delete (unit as BaseEntity).builtThisTurn
  }
  return unit
}
function diffOrNull(n1: number | null, n2: number | null, debug = false) {
  if (n1 === null || n2 === null) {
    return null
  }
  if (debug) {
    console.log('diffOrNull', n1, n2, toFloorMin0(n1), toFloorMin0(n2))
  }
  n1 = toFloorMin0(n1)
  n2 = toFloorMin0(n2)
  if (n1 === n2) {
    return null
  }
  // return `(${n1}→${n2})`
  const diff = (n1 - n2)
  const plusOrMinus = diff > 0 ? '-' : '+'
  return `(${plusOrMinus}${diff})`
}
