import { Entity } from '.'
import type { HasAttackedThisTurn } from '../AttackedThisTurn'
import type { HasWasBuiltThisTurn } from '../WasBuiltThisTurn'
import isEntityOutOfMoves from './isEntityOutOfMoves'
import isEntityUnit from './isEntityUnit'
import type { UnitEntityUnion } from './UnitEntityUnion.type'

export default function isEntityHasMovesLeft(entity: Entity): entity is UnitEntityUnion {
  if ((entity as HasAttackedThisTurn).attackedThisTurn) {
    return false
  }
  if ((entity as HasWasBuiltThisTurn).builtThisTurn) {
    return false
  }
  if (isEntityOutOfMoves(entity)) {
    return false
  }
  return isEntityUnit(entity)
}
