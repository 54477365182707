import { object, type InferOutput } from "valibot"
import { ActionLogListSchema } from "../../state/flux/ActionLogList.type"
import { StateSchema } from "../../state/State.type"

export const SavedGamePayloadSchema = object({
  state: StateSchema,
  actionLogs: ActionLogListSchema,
})

export type SavedGamePayload = InferOutput<typeof SavedGamePayloadSchema>
