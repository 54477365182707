import { now } from "@sg/shared/src/lib/Date"
import type { Engine } from "../../engine/Engine.type"
import type { ActionLog } from "./ActionLog.type"

export default function alreadyHasActionLog(engine: Engine, actionLog: ActionLog): boolean {
  const list = engine.actionLogs
  // console.log('alreadyHasActionLog.list', deepClone(list), 'actionLog', deepClone(actionLog))

  const len = list.length
  const fiveMinutes = 60 * 1000
  const fiveMinutesAgo = now() - fiveMinutes

  if (fiveMinutesAgo > actionLog.at) {
    return false
  }

  // we use cref instead of at
  // because the client/server clocks will be different values
  // cref is set by the client and must be unique per round+player_id
  const { action: { type }, player_id, round, cref } = actionLog

  for (let i = len - 1; i >= 0; i--) {
    const log = list[i]

    // Check for a match
    if (
      log.cref === cref &&
      log.round === round &&
      log.player_id === player_id &&
      log.action.type === type
    ) {
      return true
    }

    // Early exit if the log is more than 5 minutes old
    if (fiveMinutesAgo > log.at) {
      return false
    }
  }

  // No match found
  return false
}
