import { VITE_ALLOWS_GUEST_ACCESS_TOKEN } from "./auth_session_key"
import fetchGuestAccessToken from "./fetchGuestAccessToken"
import fetchRefreshedAccessToken from "./fetchRefreshedAccessToken"
import isAccessTokenExpired from "./isAccessTokenExpired"
import { getSessionValue } from "./sessionValue"

export default async function getAccessToken() {
  // console.log('<let sessionValue = await getSessionValue()>')
  let sessionValue = await getSessionValue()
  // console.log('</let sessionValue = await getSessionValue()>')
  // console.log('getAccessToken.sessionValue', sessionValue)
  // console.log('VITE_ALLOWS_GUEST_ACCESS_TOKEN', VITE_ALLOWS_GUEST_ACCESS_TOKEN)
  if (!sessionValue?.at && VITE_ALLOWS_GUEST_ACCESS_TOKEN) {
    // console.log('<await fetchGuestAccessToken()>')
    await fetchGuestAccessToken()
    // console.log('</await fetchGuestAccessToken()>')
    // console.log('<sessionValue = await getSessionValue()>')
    sessionValue = await getSessionValue()
    // console.log('</sessionValue = await getSessionValue()>')
  }
  // console.log('isAccessTokenExpired()', isAccessTokenExpired())
  if (isAccessTokenExpired()) {
    // console.log('<await fetchRefreshedAccessToken()>')
    await fetchRefreshedAccessToken()
    // console.log('</await fetchRefreshedAccessToken()>')
    // console.log('<sessionValue = await getSessionValue()>')
    sessionValue = await getSessionValue()
    // console.log('</sessionValue = await getSessionValue()>')
  }
  return sessionValue?.at
}