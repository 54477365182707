import { type Camera, MathUtils } from "three"
import { VERTICES_PER_TILE } from "../../three/consts"
import type { State } from "../state/State.type"

export default function clampCameraToState(camera: Camera, state: State): void {
  const xBound = state.width * (1 * VERTICES_PER_TILE)
  const zBound = state.height * (1 * VERTICES_PER_TILE)
  camera.position.x = MathUtils.clamp(camera.position.x, -xBound, xBound)
  camera.position.z = MathUtils.clamp(camera.position.z, -zBound, zBound)
}

