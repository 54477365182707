export interface HasDistance {
  distance: number
}

export function byDistanceDesc (a : HasDistance, b : HasDistance) {
  return b.distance - a.distance
}

export function byDistanceAsc (a : HasDistance, b : HasDistance) {
  return a.distance - b.distance
}